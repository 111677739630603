import platformIconFirst from "../../assets/png/PlatformIcon1Black.png"
import platformIconSecond from "../../assets/png/PlatformIcon2Black.png"
import activePlatformIconFirst from "../../assets/png/PlatformIcon1.png"
import activePlatformIconSecond from "../../assets/png/PlatformIcon2.png"
import BuyBrokIconFirst from "../../assets/svg/BuyBrokIconFirst"
import BuyBrokIconSecond from "../../assets/svg/BuyBrokIconSecond"
import BuyBrokIconThird from "../../assets/png/BrokIconThird.png"
import BuyBrokIconForthActive from "../../assets/png/BrokIconForth.png"
import BuyBrokIconForth from "../../assets/png/BrokIconForthBlack.png"
import BuyBrokIconFifth from "../../assets/svg/BuyBrokIconFifth"
import BuyBrokIconLastActive from "../../assets/png/BrokIconLast.png"
import BuyBrokIconLast from "../../assets/png/BrokIconLastBlack.png"
import BuyCheckRule from "../../assets/svg/BuyCheckRule"
import '../../assets/styles/Buy.min.css'
import React, { useEffect, useState } from "react";
import axios from "axios";
import { ClipLoader, ScaleLoader } from "react-spinners";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import i18n from "../../i18n";

const CheckoutSecond = (props) => {
    const { t } = useTranslation();
    const [platform, setPlatform] = useState('metatrader5')
    const [broker, setBroker] = useState('ic_markets')
    const [account, setaccount] = useState('ecn_or_raw')
    const [priceLoading, setPriceLoading] = useState(true)
    const [discountLoading, setDiscountLoading] = useState(false)
    const [CheckoutProcessLoading, setCheckoutProcessLoading] = useState(false);
    const [Price, setPrice] = useState('');
    const [Rules, setIsRules] = useState(false);
    const [BrokerAccept, setBrokerAccept] = useState(false);
    const [CheckOutInput, setCheckOut] = useState({
        discount_code: '',
        error_list: [],
    });

    const handleRulesChange = event => {
        setIsRules(current => !current);
    };

    const handleBrokerAcceptChange = event => {
        setBrokerAccept(current => !current);
    };

    useEffect(() => {
        fetchPrice()
    }, [])

    console.log("props.package: ", props.package)

    const fetchPrice = async () => {
        // axios.post('/api/check-product', { product_id: props.package }).then(res => {


        const prices = {
            "1": "$ 66",
            "2": "$ 88",
            "3": "$ 157",
            "4": "$ 314",
            "5": "$ 502",
            "6": "$ 858"
        }

        setPrice({
            "ShowPrice": prices[`${props.package}`],
            "price": `$ ${prices[`${props.package}`]}`
        })
        setPriceLoading(false)
        // })
    }

    const handlePlatformInput = (e) => {
        e.persist();
        setPlatform(e.target.value);
    }
    const handleBrokerInput = (e) => {
        e.persist();
        setBroker(e.target.value);
    }
    const handleAccountInput = (e) => {
        e.persist();
        setaccount(e.target.value);
    }
    const handleInput = (e) => {
        e.persist();
        setCheckOut({ ...CheckOutInput, [e.target.name]: e.target.value });
    }

    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-start',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const CheckDiscount = () => {

    }

    const CheckOutSubmit = (e) => {
        e.preventDefault()
    }

    return (
        <div className="px-[25px] sm:px-[72px]">
            {
                CheckoutProcessLoading ?
                    <div className='text-center py-[80px]'>
                        <ClipLoader color={'#0098ff'} loading={CheckoutProcessLoading} size={60} aria-label="Loading Spinner" data-testid="loader" />
                    </div>
                    :
                    <form onSubmit={CheckOutSubmit}>
                        <div className="grid grid-flow-row lg:grid-cols-3 gap-6 mt-[20px]">
                            <div className="lg:col-span-2 h-max bg-white rounded-[20px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] px-[30px] pb-[40px] mb-[20px] lg:mb-[70px]">
                                <p className="my-[20px] sm:my-[30px] font-extrabold text-[16px] sm:text-[18px] leading-[31.09px] text-[#797979]">{t('Buy.جزئیات صورتحساب', 'جزئیات صورتحساب')}</p>
                                <div className="border-b-[1px] border-[#ECECEC] mb-[30px]"></div>
                                <div className="mb-[30px]">
                                    <p className="font-semibold text-[14px] text-[#515151] leading-[24.18px] mb-[10px]">{t('Buy.پلتفرم موردنظر را انتخاب کنید', 'پلتفرم موردنظر را انتخاب کنید')}</p>
                                    <div className="grid md:grid-cols-2 gap-[10px]">
                                        <label htmlFor='metatrader5' className={`${platform === "metatrader5" ? "bg-[#1B262C]" : "bg-white border-[1px] border-[#C7C7C7]"} h-[63px] rounded-[10px] text-center py-[10px] cursor-pointer`}>
                                            <input selected className='hidden' onChange={handlePlatformInput} id='metatrader5' type='radio' name='platform' value='metatrader5' />
                                            <img className='inline-block' src={platform === "metatrader5" ? activePlatformIconFirst : platformIconFirst}></img>
                                        </label>
                                        <label htmlFor='metatrader4' className={`${platform === "metatrader4" ? "bg-[#1B262C]" : "bg-white border-[1px] border-[#C7C7C7]"} h-[63px] rounded-[10px] text-center py-[10px] cursor-pointer`}>
                                            <input className='hidden' onChange={handlePlatformInput} id='metatrader4' type='radio' name='platform' value='metatrader4' />
                                            <img className='inline-block' src={platform === "metatrader4" ? activePlatformIconSecond : platformIconSecond}></img>
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <p className="font-semibold text-[14px] text-[#515151] leading-[24.18px] mb-[10px]">{t('Buy.بروکر موردنظر را انتخاب کنید', 'بروکر موردنظر را انتخاب کنید')}</p>
                                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[10px] mb-[30px]">
                                        <label htmlFor='fusion_markets' className={`${broker === "fusion_markets" ? "bg-[#1B262C]" : "bg-white border-[1px] border-[#C7C7C7]"} h-[63px] rounded-[10px] text-center py-[15.5px] cursor-pointer`}>
                                            <input className='hidden' onChange={handleBrokerInput} id='fusion_markets' type='radio' name='broker' value='fusion_markets' />
                                            <span className='inline-block'><BuyBrokIconSecond color={broker === "fusion_markets" ? "#fff" : "#6A67CF"} /></span>
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <p className="font-semibold text-[14px] text-[#515151] leading-[24.18px] mb-[10px]">{t('Buy.نوع حساب موردنظر را انتخاب کنید', 'نوع حساب موردنظر را انتخاب کنید')}</p>
                                    <div className="grid md:grid-cols-2 gap-[10px] mb-[30px]">
                                        <label htmlFor='ecn_or_raw' className={`${account === "ecn_or_raw" ? "bg-[#1B262C] text-white" : "bg-white text-[#1B262C] border-[1px] border-[#C7C7C7]"} h-[63px] flex flex-col items-center rounded-[10px] cursor-pointer`}>
                                            <input className='hidden' onChange={handleAccountInput} id='ecn_or_raw' type='radio' name='account' value='ecn_or_raw' />
                                            <p className="w-max mx-auto my-auto font-bold text-[14px] leading-[40px]">ECN or Raw</p>
                                        </label>
                                        <label htmlFor='standard' className={`${account === "standard" ? "bg-[#1B262C] text-white" : "bg-white text-[#1B262C] border-[1px] border-[#C7C7C7]"} h-[63px] flex flex-col items-center rounded-[10px] cursor-pointer`}>
                                            <input className='hidden' onChange={handleAccountInput} id='standard' type='radio' name='account' value='standard' />
                                            <p className="w-max mx-auto my-auto font-bold text-[14px] leading-[40px]">Standard</p>
                                        </label>
                                    </div>
                                </div>
                                <div className="flex flex-col items-start">
                                    <div>
                                        <input onChange={handleBrokerAcceptChange} className='hidden' id='accept_broker' type='checkbox' name='accept_broker' value={BrokerAccept} />
                                        <label htmlFor='accept_broker' className="flex auto-cols-[minmax(0,_2fr)] items-center gap-[12px] mb-[30px]">
                                            <div className='checkbox duration-[300ms] bg-white w-[20px] h-[20px] border-[1.5px] border-[#E4E4E4] rounded-[6px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] cursor-pointer'>
                                                <div className="w-[20px] mx-auto relative left-[-3px] my-[5px]">
                                                    <BuyCheckRule />
                                                </div>
                                            </div>
                                            <div>
                                                <p id='brokerRequired' className="font-semibold text-[14px] text-[#515151] leading-[24.18px] cursor-pointer">{t('Buy.BrokerRules', 'بروکر موردنظر توسط تریدر تست شده و سرمایه گذار برتر هیچ مسئولیتی در قبال آن ندارد. *')}</p>
                                            </div>
                                        </label>
                                    </div>
                                    <div>
                                        <input onChange={handleRulesChange} className='hidden' id='rules' type='checkbox' name='rules' value={Rules} />
                                        <label htmlFor='rules' className="flex gap-[12px]">
                                            <div className='checkbox duration-[300ms] bg-white w-[20px] h-[20px] border-[1.5px] border-[#E4E4E4] rounded-[6px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] cursor-pointer'>
                                                <div className="w-[20px] mx-auto relative left-[-3px] my-[5px]">
                                                    <BuyCheckRule />
                                                </div>
                                            </div>
                                            <div>
                                                <p id='rulesRequired' className="font-semibold text-[14px] text-[#515151] leading-[24.18px] cursor-pointer">{t('Buy.BuyRules', 'قوانین را مطالعه کرده و شرایط پلن ها را میپذیرم. * ')}</p>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col ">
                                <div className="h-max px-[40px] py-[42px] bg-white rounded-[20px] mb-[24px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)]">
                                    {
                                        priceLoading ?
                                            <div className='text-center'>
                                                <ScaleLoader className='!inline-block' color={'#0098ff'} loading={true} size={60} aria-label="Loading Spinner" data-testid="loader" />
                                            </div>
                                            :
                                            <>
                                                {
                                                    Price.show_discount_price ?
                                                        <>
                                                            <div className="mb-[17px] flex text-[#0098FF]">
                                                                <p className="font-bold text-[14px]  leading-[24.18px] text-start w-1/2">{t('Buy.جمع کل', 'جمع کل')}:</p>
                                                                <p className={`w-1/2 text-[10px] ${i18next.language === 'fa' ? 'text-end' : 'text-start'}`} dir='rtl'>
                                                                    <span className="text-[16px] font-semibold" dir='rtl'>{Price.ShowCalculatePrice}</span>
                                                                </p>
                                                            </div>
                                                            <div className="border-b-[1px] border-[#ECECEC] mb-[17px] "></div>
                                                            <div className="mb-[17px] pb-[17px] flex text-[#0F4C75] border-b-[1px] border-[#ECECEC]">
                                                                <p className="text-[14px] leading-[24.18px] text-start w-1/2">{t('Buy.مبلغ پکیج', 'مبلغ پکیج')}:</p>
                                                                <p className={`w-1/2 text-[10px] ${i18next.language === 'fa' ? 'text-end' : 'text-start'}`} dir='rtl'>
                                                                    <span className="text-[16px]">{Price.ShowPrice}</span>
                                                                </p>
                                                            </div>
                                                            <div className="mb-[25px] flex text-[#0F4C75]">
                                                                <p className="text-[14px] leading-[24.18px] text-start w-1/2">{t('Buy.مبلغ تخفیف', 'مبلغ تخفیف')}:</p>
                                                                <p className={`w-1/2 text-[10px] ${i18next.language === 'fa' ? 'text-end' : 'text-start'}`} dir='rtl'>
                                                                    <span className="text-[16px]">{Price.show_discount_price}</span> </p>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="mb-[17px] flex text-[#0098FF]">
                                                                <p className="font-bold text-[14px] leading-[24.18px] text-start w-1/2">{t('Buy.جمع کل', 'جمع کل')}:</p>
                                                                <p className={`w-1/2 text-[10px] ${i18next.language === 'fa' ? 'text-end' : 'text-start'}`} dir='rtl'>
                                                                    <span className="text-[16px] font-semibold">{Price.ShowPrice}</span> </p>
                                                            </div>
                                                            <div className="border-b-[1px] border-[#ECECEC] mb-[17px] "></div>
                                                            <div className="mb-[25px] flex text-[#0F4C75]">
                                                                <p className="text-[14px] leading-[24.18px] text-start w-1/2">{t('Buy.مبلغ پکیج', 'مبلغ پکیج')}:</p>
                                                                <p className={`w-1/2 text-[10px] ${i18next.language === 'fa' ? 'text-end' : 'text-start'}`} dir='rtl'>
                                                                    <span className="text-[16px]">{Price.ShowPrice}</span> </p>
                                                            </div>
                                                        </>
                                                }
                                                <button type='submit' className="flex bg-[#ffe422] hover:bg-[#ffcb22] duration-[300ms] w-full mt-[40px] h-[63px] rounded-[10px] cursor-pointer px-[23.14px]">
                                                    <p className="w-max mx-auto my-auto font-bold text-[14px] leading-[40px] text-[#]">{t('Buy.پرداخت با زرین پال', 'پرداخت با زرین پال')}</p>
                                                </button>
                                            </>
                                    }
                                </div>

                                <div className={"border-[1px] border-white duration-[300ms] h-max mb-[38px] px-[17.5px] py-[20px] bg-white rounded-[10px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] flex auto-cols-[minmax(0,_2fr)]" + CheckOutInput.error_list.discount_error}>
                                    {
                                        discountLoading ?
                                            <div className='w-full text-center transform scale-[.5] my-[-15px] '>
                                                <ScaleLoader className='!inline-block' color={'#0098ff'} loading={true} size={20} />
                                            </div>
                                            :
                                            <>
                                                <input type='hidden' value={CheckOutInput.discount} />
                                                <input id='discount_code' name={'discount_code'} placeholder={t('Buy.کد تخفیف خود را وارد کنید', 'کد تخفیف خود را وارد کنید')} className={`outline-none text-[16px] w-full ${i18next.language === 'fa' ? 'pl-10' : 'pr-10'}`} onChange={handleInput} value={CheckOutInput.discount_code} />
                                                <div onClick={CheckDiscount} className="outline-none font-bold text-[16px] text-[#0098FF] cursor-pointer">{t('Buy.ثبت', 'ثبت')}</div>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
            }
        </div>
    )
}

export default CheckoutSecond