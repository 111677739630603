import Avatar from "../../../assets/svg/Avatar";

const AuthInfo = () => {
    return (
        <div className='flex gap-3'>
            <div className=''>
                <Avatar />
            </div>
            <div>
                <div className='font-semibold text-[16px]'>امیرحسین نژادجعفری</div>
                <div className='text-[#B1B1B1] text-[15px]'>09901932636</div>
            </div>
        </div>
    )
}

export default AuthInfo