export default ({ color = "white" }) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_141_5231)">
      <path
        d="M24.2459 12.27C24.2459 11.48 24.1759 10.73 24.0559 10H12.7559V14.51H19.2259C18.9359 15.99 18.0859 17.24 16.8259 18.09V21.09H20.6859C22.9459 19 24.2459 15.92 24.2459 12.27Z"
        fill="#4285F4"
      />
      <path
        d="M12.7549 24C15.9949 24 18.7049 22.92 20.6849 21.09L16.8249 18.09C15.7449 18.81 14.3749 19.25 12.7549 19.25C9.62492 19.25 6.97492 17.14 6.02492 14.29H2.04492V17.38C4.01492 21.3 8.06492 24 12.7549 24Z"
        fill="#34A853"
      />
      <path
        d="M6.02586 14.29C5.77586 13.57 5.64586 12.8 5.64586 12C5.64586 11.2 5.78586 10.43 6.02586 9.71V6.62H2.04586C1.22586 8.24 0.755859 10.06 0.755859 12C0.755859 13.94 1.22586 15.76 2.04586 17.38L6.02586 14.29Z"
        fill="#FBBC05"
      />
      <path
        d="M12.7549 4.75C14.5249 4.75 16.1049 5.36 17.3549 6.55L20.7749 3.13C18.7049 1.19 15.9949 0 12.7549 0C8.06492 0 4.01492 2.7 2.04492 6.62L6.02492 9.71C6.97492 6.86 9.62492 4.75 12.7549 4.75Z"
        fill="#EA4335"
      />
    </g>
    <defs>
      <clipPath id="clip0_141_5231">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
