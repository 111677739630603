import React, {useEffect, useState} from "react";
import FastLogin from "../components/FastLogin";
import TitleInfo from "../components/careers/TitleInfo";
import {useParams} from "react-router-dom";
import axios from "axios";
import ContentCareer from "../components/careers/ContentCareer";
import SendResume from "../components/careers/SendResume";
import {ClipLoader} from "react-spinners";

const SingleCareer = () => {
    let {slug} = useParams()
    const [loading, setLoading] = useState(false)
    const [careerData, setCareerData] = useState('')

    useEffect(() => {
        fatchCareer()
        setLoading(true)
    }, [slug])

    const fatchCareer = async () => {
        axios.get(`/api/careers/` + slug)
            .then(({data}) => {
                    setCareerData(data)
                    setLoading(false)
                }
            )
    }

    if (loading) {
        return (
            <div className='text-center py-[80px] bg-primary-bg-color'>
                <ClipLoader color={'#0098ff'} loading={loading} size={60} aria-label="Loading Spinner" data-testid="loader"/>
            </div>
        )
    }

    if (careerData) {
        return (
            <div className='overflow-hidden'>
                <TitleInfo title={careerData.title} categoryTitle={careerData.cat_tbl ? careerData.cat_tbl.title : 'دسته بندی نشده'} location={careerData.country + '، ' + careerData.city} cooperationType={careerData.cooperation_type} gender={careerData.gender}/>
                <ContentCareer jobDesc={careerData.desc} jobContent={careerData.content_text} jobRequirement={careerData.requirement}/>
                <SendResume career_id={careerData.id}/>
                <FastLogin dataContent={careerData.quick_register_section}/>
            </div>
        )
    }
}

export default SingleCareer;