import React from "react";
import InputSearchIcon from "../../assets/svg/InputSearchIcon";
import {Trans, withTranslation} from "react-i18next";
import i18next from "i18next";

const RulesSearchHeader = (props) => {
    return (
        <>
            <div className="w-full h-[169px] relative bg-secondary-bg-color mb-[68px] px-[25px] sm:px-[72px]">
                <div className="w-full h-[1px] xl:bg-[#495156]"/>
                <div className="text-center mt-[10px] xl:mt-[40px]">
                    <h3 className="text-white text-[22px] md:text-[32px] section-heading font-light" dangerouslySetInnerHTML={{__html: i18next.language == 'en' ? props.dataContent.title_en : props.dataContent.title_fa}}/>
                    <p className="text-[rgba(255,255,255,0.7)] text-[12px] md:text-sm font-medium leading-[30px] md:leading-[40px]" dangerouslySetInnerHTML={{__html: i18next.language == 'en' ? props.dataContent.desc_en : props.dataContent.desc_fa}}/>
                </div>
                <div className="absolute w-full md:w-min px-[25px] md:px-0 left-1/2 -translate-x-1/2 top-[140px]">
                    <div className="relative">
                        <input className={`outline-none w-full md:w-[636px] h-[58px] rounded-[10px] px-[25px] ${i18next.language === 'fa' ? 'pl-[65px]' : ' pr-[65px]'} py-[4px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)]`} placeholder={i18next.t('RulesPage.SearchPlaceholder', 'جستجو کلمه کلیدی در میان قوانین و مقررات...')} type="text"/>
                        <div className={`absolute top-[-0.5%] ${i18next.language === 'fa' ? 'left-[-1.5%]' : 'right-[-1.5%]'}`}>
                            <InputSearchIcon/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lg:w-[1030px] px-[25px] text-center mx-auto text-[#767D80] text-sm font-bold leading-[35px] mb-[50px]" dangerouslySetInnerHTML={{__html: i18next.language == 'en' ? props.dataContent.under_search_en : props.dataContent.under_search_fa}}/>
        </>
    );
};

export default withTranslation()(RulesSearchHeader);
