import React, {useEffect, useState} from "react";
import Header from "../components/home/Header";
import SarmayeGozarFeatures from "../components/home/SarmayeGozarFeatures";
import AboutCompony from "../components/home/AboutCompony";
import SarmayegozarPlan from "../components/home/SarmayegozarPlan";
import CustomerStatistics from "../components/home/CustomerStatistics";
import PopularPlatforms from "../components/home/PopularPlatforms";
import TradersPanelPoster from "../components/home/TradersPanelPoster";
import PopularQuestions from "../components/home/PopularQuestions";
import StepsToWorkSite from "../components/home/StepsToWorkSite";
import FastLogin from "../components/FastLogin";
import BlogContent from "../components/home/BlogContent";
import {withTranslation} from "react-i18next";
import useTitle from "../hooks/useTitle";
import axios from "axios";
import {ClipLoader} from "react-spinners";
import i18next from "i18next";

const Home = ({t}) => {
    useTitle(t('PageTitle.صفحه نخست', 'صفحه نخست'))
    const [pageData, setPageData] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchArticle()
        setLoading(true)
    }, [])

    const fetchArticle = async () => {
        axios.get(`/api/page/صفحه-نخست`).then(({data}) => {
                setPageData(data)
                setLoading(false)
            }
        )
    }

    if (loading) {
        return (
            <div className='text-center py-[80px] bg-primary-bg-color'>
                <ClipLoader color={'#0098ff'} loading={loading} size={60} aria-label="Loading Spinner" data-testid="loader"/>
            </div>
        )
    }

    if (pageData) {
        return (
            <section className="w-full bg-primary-bg-color overflow-hidden">
                <Header dataContent={pageData.section_1}/>
                <SarmayeGozarFeatures dataContent={pageData.section_2}/>
                <AboutCompony dataContent={pageData.section_3}/>
                <SarmayegozarPlan dataContent={pageData.section_4}/>
                <CustomerStatistics dataContent={pageData.statistics_section}/>
                <div className="w-full mt-[76px] mb-[64px] px-[25px] sm:px-[72px] text-center">
                    <img className="hidden md:block w-full" src={i18next.language === 'fa' ? require("../assets/img/Benefit-Banner.png") : require("../assets/img/en/Benefit-Banner.png")} alt="Benefit Banner"/> <img className="inline-block md:hidden" src={i18next.language === 'fa' ? require("../assets/img/Benefit-Banner-Responsive.png") : require("../assets/img/en/Benefit-Banner-Responsive.png")} srcSet={`${i18next.language === 'fa' ? require("../assets/img/Benefit-Banner-Responsive.png") : require("../assets/img/en/Benefit-Banner-Responsive.png")} 1x, ${i18next.language === 'fa' ? require("../assets/img/Benefit-Banner-Responsive@2.png") : require("../assets/img/en/Benefit-Banner-Responsive@2.png")} 2x`} alt="Benefit Banner"/>
                </div>
                <PopularPlatforms dataContent={pageData.section_6}/>
                <TradersPanelPoster dataContent={pageData.section_7}/>
                <PopularQuestions dataContent={pageData.section_8}/>
                <StepsToWorkSite dataContent={pageData.section_9}/>
                <FastLogin dataContent={pageData.quick_register_section}/>
                <BlogContent/>
            </section>
        );
    }
};

export default withTranslation()(Home);
