import React from "react";
import {useNavigate} from "react-router-dom";
import HeaderShadow from "../../assets/svg/HeaderShadow";
import WordLittle from "../../assets/svg/WordLittle";
import ButtonOfScrollTo from "../../assets/svg/ButtonOfScrollTo";
import {Trans, withTranslation} from 'react-i18next';
import IntroSectionImage from "./IntroSectionImage";
import i18next from "i18next";

const Header = (props) => {
    const navigate = useNavigate();
    // console.log(props.dataContent.title_fa)
    return (
        <>
            <div className="w-full items-center lg:items-stretch flex flex-col lg:flex-row justify-between bg-secondary-bg-color text-white p-[23px_20px_83px] sm:p-[23px_40px_83px] lg:p-[113px_72px] relative">
                <div className="flex flex-col justify-center w-full">
                    <div className='text-center lg:text-start'>
                        <div className="lg:flex items-center gap-[28px]">
                            <div className="hidden lg:inline w-[1px] h-[121px] bg-white"/>
                            <h2 className="section-heading font-light text-[22px] sm:text-5xl leading-[40px] sm:leading-[80px] xl:text-5xl lg:text-4xl xl:leading-[70px] lg:leading-[60px]">
                                <div dangerouslySetInnerHTML={{__html: i18next.language == 'en' ? props.dataContent.title_en : props.dataContent.title_fa}}/>
                            </h2>
                        </div>
                        <p className="w-full xl:w-[636px] lg:w-[600px] text-[12px] xl:text-lg sm:text-md !leading-[40px] mt-[20px] sm:mt-[40px] lg:mt-[14px]" dangerouslySetInnerHTML={{__html: i18next.language == 'en' ? props.dataContent.desc_en : props.dataContent.desc_fa}}/>
                        <div className="justify-center lg:justify-start flex items-center mt-[50px] lg:mt-[30px] sm:mb-[53px] gap-[24px] text-[12px] sm:text-[16px] text-[12px] sm:text-[16px] ">
                            <button onClick={() => navigate("/plans")} className="w-[237px] h-[62px] bg-primary-btn-color hover:bg-secondary-btn-color transition-all rounded-[10px] py-[11px]">
                                {i18next.t('HomePage.مشاهده پلن ها', 'مشاهده پلن ها')}
                            </button>
                            <button onClick={() => navigate("/aboutus")} className="w-[250px] h-[62px] border border-white rounded-[10px] hover:bg-primary-bg-color hover:text-secondary-bg-color transition-all py-[11px]">
                                {i18next.t('HomePage.درباره سرمایه گذار برتر', 'درباره سرمایه گذار برتر')}
                            </button>
                        </div>
                        <p className="hidden lg:block xl:text-[27px] lg:text-[24px] font-light" dangerouslySetInnerHTML={{__html: i18next.language == 'en' ? props.dataContent.under_cta_en : props.dataContent.under_cta_fa}}/>
                    </div>
                </div>

                <IntroSectionImage/>

                <p className="block lg:hidden text-[17px] sm:text-[23px] my-[20px] :my-[30px] font-light">
                    <Trans i18nKey='HomePage.underButtons'> <span className="font-extrabold">کد تخفیف : oneyearSGB</span> تا October 25 </Trans>
                </p>

                <div className="absolute lg:hidden left-1/2 -translate-x-1/2 bottom-[-50px] cursor-pointer" onClick={() =>
                    window.scrollTo({
                        top: 1270,
                        behavior: "smooth",
                    })
                }>
                    <ButtonOfScrollTo/>
                </div>
            </div>


            <div className="relative hidden lg:block">
                <HeaderShadow/> {/* <div className="absolute -top-full left-0 z-[-1]">
          <WordBig />
        </div> */}
                <div className="absolute -top-full right-0 z-[-1]">
                    <WordLittle/>
                </div>
            </div>
        </>
    );
};

export default withTranslation()(Header);
