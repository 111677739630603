import React, {useState} from "react";
import ButtonOfScrollTo from "../../assets/svg/ButtonOfScrollTo";
import DashedBorderPlans from "../../assets/svg/PlansDashedBorder";
import PlansIconFill from "../../assets/svg/PlansIcons";
import {withTranslation} from "react-i18next";
import useTitle from "../../hooks/useTitle";
import i18next from "i18next";

const InfoHeader = (props) => {
    const [chooseTab, setChooseTab] = useState({
        tabOne: false,
        tabTwo: true,
        tabThree: false,
        tabFour: false,
        tabFive: false,
        tabSix: false,
    });

    return (
        <>
            <div className="w-full h-[201px] relative bg-secondary-bg-color px-[25px] sm:px-[72px] lg:mb-[718px]">
                <div className="w-full h-[1px] xl:bg-[#495156]" />
                <div className="text-center mt-[20px] xl:mt-[40px]">
                    <div className="text-white text-[24px] sm:text-[32px] mb-4 sm:mb-0" dangerouslySetInnerHTML={{__html: i18next.language == 'en' ? props.dataContent.section_1.title_en : props.dataContent.section_1.title_fa}} />
                </div>
            </div>
            <div className="relative lg:absolute lg:left-1/2 lg:-translate-x-1/2 top-[-30px] lg:top-[290px] w-full lg:w-auto">
                <div className="relative text-center">
                    <div className="mx-0 md:mx-[100px] lg:mx-auto lg:w-[1076px] lg:h-[640px] text-center bg-white rounded-[20px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] p-[50px_40px_130px] sm:p-[50px_100px_130px] lg:p-[40px]">
                        <p className="text-black text-[16px] sm:text-2xl leading-[50px] mb-[50px] sm:mb-[85px] font-light" dangerouslySetInnerHTML={{__html: i18next.language == 'en' ? props.dataContent.section_2.title_en : props.dataContent.section_2.title_fa}}/>
                        <div className="w-full relative lg:static lg:w-[788px] lg:h-[326px] m-auto text-center border border-dashed lg:border-0 p-[60px_40px] sm:p-[80px] rounded-[30px] lg:p-0">
                            <span className='hidden lg:block'><DashedBorderPlans/></span>
                            <div onClick={() =>
                                setChooseTab({
                                    ...chooseTab,
                                    tabOne: true,
                                    tabTwo: false,
                                    tabThree: false,
                                    tabFour: false,
                                    tabFive: false,
                                    tabSix: false,
                                })
                            } className="transform scale-[.7] sm:scale-100 absolute top-[75px] sm:top-[-45px] right-[-45px] lg:right-auto lg:top-[133px] lg:left-[711px] cursor-pointer">
                                {chooseTab.tabOne ?
                                    <PlansIconFill color="black" bgColor="white"/>
                                    :
                                    <PlansIconFill/>
                                }
                            </div>
                            <div onClick={() =>
                                setChooseTab({
                                    ...chooseTab,
                                    tabOne: false,
                                    tabTwo: true,
                                    tabThree: false,
                                    tabFour: false,
                                    tabFive: false,
                                    tabSix: false,
                                })
                            } className="transform scale-[.7] sm:scale-100 absolute top-[-45px] left-1/2 ml-[-45px] lg:ml-0 lg:top-[133px] lg:left-[496px] cursor-pointer">
                                {chooseTab.tabTwo ?
                                    <PlansIconFill color="black" bgColor="white"/>
                                    :
                                    <PlansIconFill/>
                                }
                            </div>
                            <div onClick={() =>
                                setChooseTab({
                                    ...chooseTab,
                                    tabOne: false,
                                    tabTwo: false,
                                    tabThree: true,
                                    tabFour: false,
                                    tabFive: false,
                                    tabSix: false,
                                })
                            } className="transform scale-[.7] sm:scale-100 absolute bottom-[75px] sm:bottom-[-45px] left-[-45px] lg:top-[458.48px] lg:left-[281px] cursor-pointer">
                                {chooseTab.tabThree ?
                                    <PlansIconFill color="black" bgColor="white"/>
                                    :
                                    <PlansIconFill/>
                                }
                            </div>
                            <div onClick={() =>
                                setChooseTab({
                                    ...chooseTab,
                                    tabOne: false,
                                    tabTwo: false,
                                    tabThree: false,
                                    tabFour: true,
                                    tabFive: false,
                                    tabSix: false,
                                })
                            } className="transform scale-[.7] sm:scale-100 absolute bottom-[75px] sm:bottom-[-45px] right-[-45px] lg:right-auto lg:top-[458.48px] lg:left-[711px] cursor-pointer">
                                {chooseTab.tabFour ?
                                    <PlansIconFill color="black" bgColor="white"/>
                                    :
                                    <PlansIconFill/>
                                }
                            </div>
                            <div onClick={() =>
                                setChooseTab({
                                    ...chooseTab,
                                    tabOne: false,
                                    tabTwo: false,
                                    tabThree: false,
                                    tabFour: false,
                                    tabFive: true,
                                    tabSix: false,
                                })
                            } className="transform scale-[.7] sm:scale-100 absolute top-[75px] sm:top-[-45px] left-[-45px] lg:top-[458.48px] lg:left-[496px] cursor-pointer">
                                {chooseTab.tabFive ?
                                    <PlansIconFill color="black" bgColor="white"/>
                                    :
                                    <PlansIconFill/>
                                }
                            </div>
                            <div onClick={() =>
                                setChooseTab({
                                    ...chooseTab,
                                    tabOne: false,
                                    tabTwo: false,
                                    tabThree: false,
                                    tabFour: false,
                                    tabFive: false,
                                    tabSix: true,
                                })
                            } className="transform scale-[.7] sm:scale-100 absolute bottom-[-45px] left-1/2 ml-[-45px] lg:ml-0 lg:top-[133px] lg:left-[281px] cursor-pointer">
                                {chooseTab.tabSix ?
                                    <PlansIconFill color="black" bgColor="white"/>
                                    :
                                    <PlansIconFill/>
                                }
                            </div>
                            <p className="w-full lg:absolute lg:w-[490px] lg:h-[140px] lg:top-[259px] lg:left-[293px] leading-[35px] text-[12px] sm:text-[14px] font-medium"  dangerouslySetInnerHTML={{__html: i18next.language == 'en' ? props.dataContent.section_2.desc_en : props.dataContent.section_2.desc_fa}}/>
                        </div>
                    </div>
                    <div className="inline-block mt-[-28px] mr-[-72px] lg:mr-0 lg:mt-0 lg:absolute lg:left-1/2 -translate-x-1/2 lg:top-[615px] cursor-pointer" onClick={() =>
                        window.scrollTo({
                            top: 970,
                            behavior: "smooth",
                        })
                    }>
                        <ButtonOfScrollTo/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default withTranslation()(InfoHeader);