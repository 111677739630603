import React, {useState} from "react";
import TablePlanDetails from "./TablePlanDetails";
import ArrowDown from "../../assets/svg/ArrowDown";
import ArrowTop from "../../assets/svg/ArrowTop";
import {Navigation} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import {Swiper, SwiperSlide} from "swiper/react";
import {Trans, withTranslation} from "react-i18next";
import i18next from "i18next";

const SarmayegozarPlan = (props) => {
    const [isTableDetails, setIsTableDetails] = useState({
        isInvestmentPeriod: false,
        isMinTraidingDays: false,
        isMaxDailyLoss: false,
        isMaxLoss: false,
        isBenefitTarget: false,
        isRefundableFee: false,
    });

    // console.log(isTableDetails);

    return (
        <main className="w-full bg-secondary-bg-color px-[25px] sm:px-[72px] py-[66px]">
            <div className="text-center text-white mb-[36px]">
                <h2 className="text-[22px] sm:text-5xl leading-[40px] sm:leading-[70px] mb-[14px] section-heading font-light" dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.title_en : props.dataContent.title_fa }}/>
                <p className="w-full lg:w-[650px] mx-auto text-[12px] sm:text-[19px] leading-[40px]" dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.desc_en : props.dataContent.desc_fa }} />
            </div>
            <div className="text-center text-white mb-[80px] lg:mb-[129px]">
                <p className="text-lg font-extrabold leading-[50px] mb-3">{i18next.t('HomePage.انتخاب مقدار','انتخاب مقدار')}</p>
                <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-none xl:grid-flow-col xl:auto-cols-auto justify-center items-center gap-[25px] justify-items-center">
                    <button className="w-full xl:w-[164px] h-[51px] border border-white rounded-[10px] hover:bg-primary-bg-color hover:text-secondary-bg-color transition-all px-[30px]">
                        5000 $
                    </button>
                    <button className="w-full xl:w-[164px] h-[51px] border border-white rounded-[10px] hover:bg-primary-bg-color hover:text-secondary-bg-color transition-all px-[30px]">
                        10,000 $
                    </button>
                    <button className="w-full xl:w-[164px] h-[51px] border border-white rounded-[10px] hover:bg-primary-bg-color hover:text-secondary-bg-color transition-all px-[30px]">
                        25,000 $
                    </button>
                    <button className="w-full xl:w-[164px] h-[51px] border border-white rounded-[10px] hover:bg-primary-bg-color hover:text-secondary-bg-color transition-all px-[30px]">
                        50,000 $
                    </button>
                    <button className="w-full xl:w-[164px] h-[51px] border border-white rounded-[10px] hover:bg-primary-bg-color hover:text-secondary-bg-color transition-all px-[30px]">
                        100,000 $
                    </button>
                    <button className="w-full xl:w-[164px] h-[51px] border border-white rounded-[10px] hover:bg-primary-bg-color hover:text-secondary-bg-color transition-all px-[30px]">
                        200,000 $
                    </button>
                </div>
            </div>

            <section className="hidden lg:flex justify-center mb-[56px]">
                <table className="rounded-[20px] rounded-tr-[0] text-center">
                    <thead>
                    <tr className="">
                        <th className="w-[330px] h-[101.5px] text-center bg-secondary-bg-color font-extrabold text-5xl">
                            <p className="custom-gradient pb-5">{i18next.t('HomePage.توضیحات پلن','توضیحات پلن')}</p>
                        </th>
                        <th className={`w-[330px] h-[101.5px] relative leading-[30px] bg-white ${i18next.language === 'fa' ? 'border-l rounded-tr-[20px]' : 'border-r rounded-tl-[20px]'}`}>
                            <div className="absolute flex flex-col justify-center w-[76px] h-[76px] bg-white rounded-full shadow-[5px_5px_40px_rgba(0,0,0,0.05)] -top-1/2 left-1/2 -translate-x-1/2">
                                <div className="text-primary-btn-color text-[40px] font-medium">
                                    1
                                </div>
                            </div>
                            <div className="text-sm font-medium text-secondary-bg-color">
                                {i18next.t('HomePage.مرحله اول','مرحله اول')}
                            </div>
                            <div className="text-xl font-extrabold text-primary-btn-color">
                                {i18next.t('HomePage.چالش سرمایه','چالش سرمایه')}
                            </div>
                        </th>
                        <th className={`w-[330px] h-[101.5px] relative leading-[30px] bg-white ${i18next.language === 'fa' ? 'border-l-[0.5px]' : 'border-r-[0.5px]'}`}>
                            <div className="absolute flex flex-col justify-center w-[76px] h-[76px] bg-white rounded-full shadow-[5px_5px_40px_rgba(0,0,0,0.05)] -top-1/2 left-1/2 -translate-x-1/2">
                                <div className="text-primary-btn-color text-[40px] font-medium">
                                    2
                                </div>
                            </div>
                            <div className="text-sm font-medium text-secondary-bg-color">
                                {i18next.t('HomePage.مرحله دوم','مرحله دوم')}
                            </div>
                            <div className="text-xl font-extrabold text-primary-btn-color">
                                {i18next.t('HomePage.احراز هویت','احراز هویت')}
                            </div>
                        </th>
                        <th className={`w-[330px] h-[101.5px] relative leading-[30px] bg-white ${i18next.language === 'fa' ? 'rounded-tl-[20px]' : 'rounded-tr-[20px]'}`}>
                            <div className="absolute flex flex-col justify-center w-[76px] h-[76px] bg-white rounded-full shadow-[5px_5px_40px_rgba(0,0,0,0.05)] -top-1/2 left-1/2 -translate-x-1/2">
                                <div className="text-primary-btn-color text-[40px] font-medium">
                                    3
                                </div>
                            </div>
                            <div className="text-sm font-medium text-secondary-bg-color">
                                {i18next.t('HomePage.مرحله سوم','مرحله سوم')}
                            </div>
                            <div className="text-xl font-extrabold text-primary-btn-color">
                                {i18next.t('HomePage.ترید با سرمایه','ترید با سرمایه')}
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className={`${isTableDetails.isInvestmentPeriod ? "" : ""}`}>
                        <td className={`w-[330px] h-[101.5px] flex justify-between items-center px-[40px] bg-[#F5F5F5] ${i18next.language === 'fa' ? 'border-l rounded-tr-[20px]' : 'border-r rounded-tl-[20px]'}`}>
                            <p className="text-lg font-extrabold text-[#0F4C75]">{i18next.t('HomePage.دوره سرمایه گذاری','دوره سرمایه گذاری')}</p>
                            <div className="cursor-pointer" onClick={() =>
                                setIsTableDetails({
                                    ...isTableDetails,
                                    isInvestmentPeriod: !isTableDetails.isInvestmentPeriod,
                                    isBenefitTarget: false,
                                    isMaxDailyLoss: false,
                                    isMaxLoss: false,
                                    isMinTraidingDays: false,
                                    isRefundableFee: false,
                                })
                            }>
                                {isTableDetails.isInvestmentPeriod ? (
                                    <ArrowTop/>
                                ) : (
                                    <ArrowDown/>
                                )}
                            </div>
                        </td>
                        <td className={`w-[330px] h-[101.5px] bg-[#F5F5F5] text-lg font-medium ${i18next.language === 'fa' ? 'border-l' : 'border-r'}`}>
                            30 {i18next.t('HomePage.روزه','روزه')}
                        </td>
                        <td className={`w-[330px] h-[101.5px] bg-[#F5F5F5] text-lg font-medium ${i18next.language === 'fa' ? 'border-l-[0.5px]' : 'border-r-[0.5px]'}`}>
                            30 {i18next.t('HomePage.روزه','روزه')}
                        </td>
                        <td className="w-[330px] h-[101.5px] bg-[#F5F5F5] text-lg font-medium">
                            30 {i18next.t('HomePage.روزه','روزه')}
                        </td>
                    </tr>
                    {isTableDetails.isInvestmentPeriod ? (
                        <tr className="plan-detail-anime">
                            <td colSpan={4}>
                                <TablePlanDetails/>
                            </td>
                        </tr>
                    ) : null}
                    <tr className="bg-white">
                        <td className={`w-[330px] h-[101.5px] flex justify-between items-center px-[40px] ${i18next.language === 'fa' ? 'border-l' : 'border-r'}`}>
                            <p className="text-lg font-extrabold text-[#0F4C75]">{i18next.t('HomePage.حداقل روزهای معاملاتی','حداقل روزهای معاملاتی')}</p>
                            <div className="cursor-pointer" onClick={() =>
                                setIsTableDetails({
                                    ...isTableDetails,
                                    isMinTraidingDays: !isTableDetails.isMinTraidingDays,
                                    isInvestmentPeriod: false,
                                    isBenefitTarget: false,
                                    isMaxDailyLoss: false,
                                    isMaxLoss: false,
                                    isRefundableFee: false,
                                })
                            }>
                                {isTableDetails.isMinTraidingDays ? (
                                    <ArrowTop/>
                                ) : (
                                    <ArrowDown/>
                                )}
                            </div>
                        </td>
                        <td className={`w-[330px] h-[101.5px] text-lg font-medium ${i18next.language === 'fa' ? 'border-l' : 'border-r'}`}>
                            10 {i18next.t('HomePage.روزه','روزه')}
                        </td>
                        <td className={`w-[330px] h-[101.5px] text-lg font-medium ${i18next.language === 'fa' ? 'border-l-[0.5px]' : 'border-r-[0.5px]'}`}>
                            10 {i18next.t('HomePage.روزه','روزه')}
                        </td>
                        <td className="w-[330px] h-[101.5px] text-lg font-medium">
                            10 {i18next.t('HomePage.روزه','روزه')}
                        </td>
                    </tr>
                    {isTableDetails.isMinTraidingDays ? (
                        <tr className="plan-detail-anime">
                            <td colSpan={4}>
                                <TablePlanDetails/>
                            </td>
                        </tr>
                    ) : null}
                    <tr className="bg-[#F5F5F5]">
                        <td className={`w-[330px] h-[101.5px] flex justify-between items-center px-[40px] ${i18next.language === 'fa' ? 'border-l' : 'border-r'}`}>
                            <p className="text-lg font-extrabold text-[#0F4C75]">{i18next.t('HomePage.حداکثر ضرر روزانه','حداکثر ضرر روزانه')}</p>
                            <div className="cursor-pointer" onClick={() =>
                                setIsTableDetails({
                                    ...isTableDetails,
                                    isMaxDailyLoss: !isTableDetails.isMaxDailyLoss,
                                    isMinTraidingDays: false,
                                    isInvestmentPeriod: false,
                                    isBenefitTarget: false,
                                    isMaxLoss: false,
                                    isRefundableFee: false,
                                })
                            }>
                                {isTableDetails.isMaxDailyLoss ? <ArrowTop/> : <ArrowDown/>}
                            </div>
                        </td>
                        <td className={`w-[330px] h-[101.5px] text-lg font-medium ${i18next.language === 'fa' ? 'border-l' : 'border-r'}`}>
                            30 {i18next.t('HomePage.روزه','روزه')}
                        </td>
                        <td className={`w-[330px] h-[101.5px] text-lg font-medium ${i18next.language === 'fa' ? 'border-l-[0.5px]' : 'border-r-[0.5px]'}`}>
                            30 {i18next.t('HomePage.روزه','روزه')}
                        </td>
                        <td className="w-[330px] h-[101.5px] text-lg font-medium">
                            30 {i18next.t('HomePage.روزه','روزه')}
                        </td>
                    </tr>
                    {isTableDetails.isMaxDailyLoss ? (
                        <tr className="plan-detail-anime">
                            <td colSpan={4}>
                                <TablePlanDetails/>
                            </td>
                        </tr>
                    ) : null}
                    <tr className="bg-white">
                        <td className={`w-[330px] h-[101.5px] flex justify-between items-center px-[40px] ${i18next.language === 'fa' ? 'border-l' : 'border-r'}`}>
                            <p className="text-lg font-extrabold text-[#0F4C75]">{i18next.t('HomePage.حداکثر ضرر','حداکثر ضرر')}</p>
                            <div className="cursor-pointer" onClick={() =>
                                setIsTableDetails({
                                    ...isTableDetails,
                                    isMaxLoss: !isTableDetails.isMaxLoss,
                                    isMinTraidingDays: false,
                                    isInvestmentPeriod: false,
                                    isBenefitTarget: false,
                                    isMaxDailyLoss: false,
                                    isRefundableFee: false,
                                })
                            }>
                                {isTableDetails.isMaxLoss ? <ArrowTop/> : <ArrowDown/>}
                            </div>
                        </td>
                        <td className={`w-[330px] h-[101.5px] text-lg font-medium ${i18next.language === 'fa' ? 'border-l' : 'border-r'}`}>
                            500 $
                        </td>
                        <td className={`w-[330px] h-[101.5px] text-lg font-medium ${i18next.language === 'fa' ? 'border-l-[0.5px]' : 'border-r-[0.5px]'}`}>
                            500 $
                        </td>
                        <td className="w-[330px] h-[101.5px] text-lg font-medium">
                            500 $
                        </td>
                    </tr>
                    {isTableDetails.isMaxLoss ? (
                        <tr className="plan-detail-anime">
                            <td colSpan={4}>
                                <TablePlanDetails/>
                            </td>
                        </tr>
                    ) : null}
                    <tr className="bg-[#F5F5F5]">
                        <td className={`w-[330px] h-[101.5px] flex justify-between items-center px-[40px] w-[330px] h-[101.5px] text-lg font-medium ${i18next.language === 'fa' ? 'border-l' : 'border-r'}`}>
                            <p className="text-lg font-extrabold text-[#0F4C75]">{i18next.t('HomePage.هدف سود','هدف سود')}</p>
                            <div className="cursor-pointer" onClick={() =>
                                setIsTableDetails({
                                    ...isTableDetails,
                                    isBenefitTarget: !isTableDetails.isBenefitTarget,
                                    isMinTraidingDays: false,
                                    isInvestmentPeriod: false,
                                    isMaxDailyLoss: false,
                                    isMaxLoss: false,
                                    isRefundableFee: false,
                                })
                            }>
                                {isTableDetails.isBenefitTarget ? (
                                    <ArrowTop/>
                                ) : (
                                    <ArrowDown/>
                                )}
                            </div>
                        </td>
                        <td className={`w-[330px] h-[101.5px] text-lg font-medium ${i18next.language === 'fa' ? 'border-l' : 'border-r'}`}>
                            1000 $
                        </td>
                        <td className={`w-[330px] h-[101.5px] text-lg font-medium ${i18next.language === 'fa' ? 'border-l-[0.5px]' : 'border-r-[0.5px]'}`}>
                            1000 $
                        </td>
                        <td className="w-[330px] h-[101.5px] text-lg font-medium">
                            1000 $
                        </td>
                    </tr>
                    {isTableDetails.isBenefitTarget ? (
                        <tr className="plan-detail-anime">
                            <td colSpan={4}>
                                <TablePlanDetails/>
                            </td>
                        </tr>
                    ) : null}
                    <tr className="">
                        <td className={`w-[330px] h-[101.5px] flex justify-between items-center px-[40px] bg-white ${i18next.language === 'fa' ? 'border-l rounded-br-[20px]' : 'border-r rounded-bl-[20px]'}`}>
                            <p className="text-lg font-extrabold text-[#0F4C75]">{i18next.t('HomePage.هزینه قابل استرداد','هزینه قابل استرداد')}</p>
                            <div className="cursor-pointer" onClick={() =>
                                setIsTableDetails({
                                    ...isTableDetails,
                                    isRefundableFee: !isTableDetails.isRefundableFee,
                                    isMinTraidingDays: false,
                                    isInvestmentPeriod: false,
                                    isBenefitTarget: false,
                                    isMaxDailyLoss: false,
                                    isMaxLoss: false,
                                })
                            }>
                                {isTableDetails.isRefundableFee ? (
                                    <ArrowTop/>
                                ) : (
                                    <ArrowDown/>
                                )}
                            </div>
                        </td>
                        <td className={`w-[330px] h-[101.5px] text-lg font-medium ${i18next.language === 'fa' ? 'border-l' : 'border-r'} bg-white`}>
                            30 {i18next.t('HomePage.روزه','روزه')}
                        </td>
                        <td className={`w-[330px] h-[101.5px] text-lg font-medium ${i18next.language === 'fa' ? 'border-l-[0.5px]' : 'border-r-[0.5px]'} bg-white`}>
                            30 {i18next.t('HomePage.روزه','روزه')}
                        </td>
                        <td className={`w-[330px] h-[101.5px] text-lg font-medium bg-white ${i18next.language === 'fa' ? 'rounded-bl-[20px]' : 'rounded-br-[20px]'}`}>
                            30 {i18next.t('HomePage.روزه','روزه')}
                        </td>
                    </tr>
                    {isTableDetails.isRefundableFee ? (
                        <tr className="plan-detail-anime">
                            <td colSpan={4}>
                                <TablePlanDetails/>
                            </td>
                        </tr>
                    ) : null}
                    </tbody>
                </table>
            </section>

            <section className="flex lg:hidden justify-center mb-[56px] plan-table-res">
                <Swiper dir="rtl" navigation={i18next.true} loop={i18next.true} slidesPerView={1} spaceBetween={30} modules={[Navigation]} className="w-full py-10"> <SwiperSlide className='mt-[50px] mb-[20px]'>
                    <table dir={'auto'} className="rounded-[20px] rounded-tr-[0] text-center w-full">
                        <thead>
                        <tr className="">
                            <th colSpan='2' className="relative leading-[30px] px-[25px]">
                                <div className='bg-[#FDFDFD] rounded-[25px_25px_0_0] h-full pt-[35px] mb-[-2px] pt-[50px] h-[140px]'>
                                    <div className="absolute flex flex-col justify-center w-[76px] h-[76px] bg-white rounded-full shadow-[5px_5px_40px_rgba(0,0,0,0.05)] top-[-38px] left-1/2 -translate-x-1/2">
                                        <div className="text-primary-btn-color text-[40px] font-medium">
                                            1
                                        </div>
                                    </div>
                                    <div className="text-sm font-medium text-secondary-bg-color mb-[10px]">
                                        {i18next.t('HomePage.مرحله اول','مرحله اول')}
                                    </div>
                                    <div className="text-xl font-extrabold text-primary-btn-color">
                                        {i18next.t('HomePage.چالش سرمایه','چالش سرمایه')}
                                    </div>
                                </div>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr className={`${isTableDetails.isInvestmentPeriod ? "" : ""}`}>
                            <td className={`${i18next.language == 'en' ? 'border-r rounded-[25px_0_0_0]' : 'border-l rounded-[0_25px_0_0]'} w-[50%] h-[101.5px] px-[40px] drop-shadow-[5px_5px_40px_rgba(0,0,0,0.05)] bg-[#F4F4F4]`}>
                                <div className='grid sm:grid-flow-col sm:justify-between items-center'>
                                    <p className="text-[12px] sm:text-lg mt-2 mb-2 sm:mb-0 sm:mt-0  font-extrabold">{i18next.t('HomePage.دوره سرمایه گذاری','دوره سرمایه گذاری')}</p>
                                    <div className='cursor-pointer'>
                                        <div className="inline-block" onClick={() =>
                                            setIsTableDetails({
                                                ...isTableDetails,
                                                isInvestmentPeriod: !isTableDetails.isInvestmentPeriod,
                                                isBenefitTarget: false,
                                                isMaxDailyLoss: false,
                                                isMaxLoss: false,
                                                isMinTraidingDays: false,
                                                isRefundableFee: false,
                                            })
                                        }>
                                            {isTableDetails.isInvestmentPeriod ? (
                                                <ArrowTop/>
                                            ) : (
                                                <ArrowDown/>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className={`${i18next.language == 'en' ? 'border-l rounded-[0_25px_0_0]' : 'border-r rounded-[25px_0_0_0]'} w-[50%] h-[101.5px] bg-[#F5F5F5] text-[14px] sm:text-lg font-medium`}>
                                30 {i18next.t('HomePage.روزه','روزه')}
                            </td>
                        </tr>
                        {isTableDetails.isInvestmentPeriod ? (
                            <tr className="plan-detail-anime">
                                <td colSpan={2}>
                                    <TablePlanDetails/>
                                </td>
                            </tr>
                        ) : null}
                        <tr className="bg-white">
                            <td className={`${i18next.language == 'en' ? 'border-r' : 'border-l'} w-[50%] h-[101.5px] justify-between items-center px-[40px]`}>
                                <div className='grid sm:grid-flow-col sm:justify-between items-center'>
                                    <p className="text-[12px] sm:text-lg mt-2 mb-2 sm:mb-0 sm:mt-0 font-extrabold">{i18next.t('HomePage.حداقل روزهای معاملاتی','حداقل روزهای معاملاتی')}</p>
                                    <div className='cursor-pointer'>
                                        <div className="inline-block" onClick={() =>
                                            setIsTableDetails({
                                                ...isTableDetails,
                                                isMinTraidingDays: !isTableDetails.isMinTraidingDays,
                                                isInvestmentPeriod: false,
                                                isBenefitTarget: false,
                                                isMaxDailyLoss: false,
                                                isMaxLoss: false,
                                                isRefundableFee: false,
                                            })
                                        }>
                                            {isTableDetails.isMinTraidingDays ? (
                                                <ArrowTop/>
                                            ) : (
                                                <ArrowDown/>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="w-[50%] h-[101.5px] text-[14px] sm:text-lg font-medium">
                                10 {i18next.t('HomePage.روزه','روزه')}
                            </td>
                        </tr>
                        {isTableDetails.isMinTraidingDays ? (
                            <tr className="plan-detail-anime">
                                <td colSpan={4}>
                                    <TablePlanDetails/>
                                </td>
                            </tr>
                        ) : null}
                        <tr className="bg-[#F5F5F5]">
                            <td className={`${i18next.language == 'en' ? 'border-r' : 'border-l'} w-[50%] h-[101.5px] px-[40px] drop-shadow-[5px_5px_40px_rgba(0,0,0,0.05)] bg-[#F4F4F4]`}>
                                <div className='grid sm:grid-flow-col sm:justify-between items-center'>
                                    <p className="text-[12px] sm:text-lg mt-2 mb-2 sm:mb-0 sm:mt-0 font-extrabold">{i18next.t('HomePage.حداکثر ضرر روزانه','حداکثر ضرر روزانه')}</p>
                                    <div className='cursor-pointer'>
                                        <div className="inline-block" onClick={() =>
                                            setIsTableDetails({
                                                ...isTableDetails,
                                                isMaxDailyLoss: !isTableDetails.isMaxDailyLoss,
                                                isMinTraidingDays: false,
                                                isInvestmentPeriod: false,
                                                isBenefitTarget: false,
                                                isMaxLoss: false,
                                                isRefundableFee: false,
                                            })
                                        }>
                                            {isTableDetails.isMaxDailyLoss ? <ArrowTop/> : <ArrowDown/>}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="w-[50%] h-[101.5px] text-[14px] sm:text-lg font-medium">
                                30 {i18next.t('HomePage.روزه','روزه')}
                            </td>
                        </tr>
                        {isTableDetails.isMaxDailyLoss ? (
                            <tr className="plan-detail-anime">
                                <td colSpan={4}>
                                    <TablePlanDetails/>
                                </td>
                            </tr>
                        ) : null}
                        <tr className="bg-white">
                            <td className={`${i18next.language == 'en' ? 'border-r' : 'border-l'} w-[50%] h-[101.5px] justify-between items-center px-[40px]`}>
                                <div className='grid sm:grid-flow-col sm:justify-between items-center'>
                                    <p className="text-[12px] sm:text-lg mt-2 mb-2 sm:mb-0 sm:mt-0 font-extrabold">{i18next.t('HomePage.حداکثر ضرر','حداکثر ضرر')}</p>
                                    <div className='cursor-pointer'>
                                        <div className="inline-block" onClick={() =>
                                            setIsTableDetails({
                                                ...isTableDetails,
                                                isMaxLoss: !isTableDetails.isMaxLoss,
                                                isMinTraidingDays: false,
                                                isInvestmentPeriod: false,
                                                isBenefitTarget: false,
                                                isMaxDailyLoss: false,
                                                isRefundableFee: false,
                                            })
                                        }>
                                            {isTableDetails.isMaxLoss ? <ArrowTop/> : <ArrowDown/>}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="w-[50%] h-[101.5px] text-[14px] sm:text-lg font-medium">
                                500 $
                            </td>
                        </tr>
                        {isTableDetails.isMaxLoss ? (
                            <tr className="plan-detail-anime">
                                <td colSpan={4}>
                                    <TablePlanDetails/>
                                </td>
                            </tr>
                        ) : null}
                        <tr className="bg-[#F5F5F5]">
                            <td className={`${i18next.language == 'en' ? 'border-r' : 'border-l'} w-[50%] h-[101.5px] px-[40px] drop-shadow-[5px_5px_40px_rgba(0,0,0,0.05)] bg-[#F4F4F4]`}>
                                <div className='grid sm:grid-flow-col sm:justify-between items-center'>
                                    <p className="text-[12px] sm:text-lg mt-2 mb-2 sm:mb-0 sm:mt-0 font-extrabold">{i18next.t('HomePage.هدف سود','هدف سود')}</p>
                                    <div className='cursor-pointer'>
                                        <div className="inline-block" onClick={() =>
                                            setIsTableDetails({
                                                ...isTableDetails,
                                                isBenefitTarget: !isTableDetails.isBenefitTarget,
                                                isMinTraidingDays: false,
                                                isInvestmentPeriod: false,
                                                isMaxDailyLoss: false,
                                                isMaxLoss: false,
                                                isRefundableFee: false,
                                            })
                                        }>
                                            {isTableDetails.isBenefitTarget ? (
                                                <ArrowTop/>
                                            ) : (
                                                <ArrowDown/>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="w-[50%] h-[101.5px] text-[14px] sm:text-lg font-medium">
                                1000 $
                            </td>
                        </tr>
                        {isTableDetails.isBenefitTarget ? (
                            <tr className="plan-detail-anime">
                                <td colSpan={4}>
                                    <TablePlanDetails/>
                                </td>
                            </tr>
                        ) : null}
                        <tr className="">
                            <td className={`${i18next.language == 'en' ? 'border-r rounded-[0_0_0_25px]' : 'border-l rounded-[0_0_25px_0]'} bg-white w-[50%] h-[101.5px] justify-between items-center px-[40px]`}>
                                <div className='grid sm:grid-flow-col sm:justify-between items-center'>
                                    <p className="text-[12px] sm:text-lg mt-2 mb-2 sm:mb-0 sm:mt-0 font-extrabold">{i18next.t('HomePage.هزینه قابل استرداد','هزینه قابل استرداد')}</p>
                                    <div className='cursor-pointer'>
                                        <div className="inline-block" onClick={() =>
                                            setIsTableDetails({
                                                ...isTableDetails,
                                                isRefundableFee: !isTableDetails.isRefundableFee,
                                                isMinTraidingDays: false,
                                                isInvestmentPeriod: false,
                                                isBenefitTarget: false,
                                                isMaxDailyLoss: false,
                                                isMaxLoss: false,
                                            })
                                        }>
                                            {isTableDetails.isRefundableFee ? (
                                                <ArrowTop/>
                                            ) : (
                                                <ArrowDown/>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className={`${i18next.language == 'en' ? 'rounded-[0_0_25px_0]' : 'rounded-[0_0_0_25px]'} w-[50%] h-[101.5px] text-[14px] sm:text-lg font-medium bg-white`}>
                                30 {i18next.t('HomePage.روزه','روزه')}
                            </td>
                        </tr>
                        {isTableDetails.isRefundableFee ? (
                            <tr className="plan-detail-anime">
                                <td colSpan={4}>
                                    <TablePlanDetails/>
                                </td>
                            </tr>
                        ) : null}
                        </tbody>
                    </table>
                </SwiperSlide> <SwiperSlide className='mt-[50px] mb-[20px]'>
                    <table dir={'auto'} className="rounded-[20px] rounded-tr-[0] text-center w-full">
                        <thead>
                        <tr className="">
                            <th colSpan='2' className="relative leading-[30px] px-[25px]">
                                <div className='bg-[#FDFDFD] rounded-[25px_25px_0_0] h-full pt-[35px] mb-[-2px] pt-[50px] h-[140px]'>
                                    <div className="absolute flex flex-col justify-center w-[76px] h-[76px] bg-white rounded-full shadow-[5px_5px_40px_rgba(0,0,0,0.05)] top-[-38px] left-1/2 -translate-x-1/2">
                                        <div className="text-primary-btn-color text-[40px] font-medium">
                                            2
                                        </div>
                                    </div>
                                    <div className="text-sm font-medium text-secondary-bg-color mb-[10px]">
                                        {i18next.t('HomePage.مرحله دوم','مرحله دوم')}
                                    </div>
                                    <div className="text-xl font-extrabold text-primary-btn-color">
                                        {i18next.t('HomePage.احراز هویت','احراز هویت')}
                                    </div>
                                </div>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr className={`${isTableDetails.isInvestmentPeriod ? "" : ""}`}>
                            <td className={`${i18next.language == 'en' ? 'border-r rounded-[25px_0_0_0]' : 'border-l rounded-[0_25px_0_0]'} w-[50%] h-[101.5px] px-[40px] drop-shadow-[5px_5px_40px_rgba(0,0,0,0.05)] bg-[#F4F4F4]`}>
                                <div className='grid sm:grid-flow-col sm:justify-between items-center'>
                                    <p className="text-[12px] sm:text-lg mt-2 mb-2 sm:mb-0 sm:mt-0  font-extrabold">{i18next.t('HomePage.دوره سرمایه گذاری','دوره سرمایه گذاری')}</p>
                                    <div className='cursor-pointer'>
                                        <div className="inline-block" onClick={() =>
                                            setIsTableDetails({
                                                ...isTableDetails,
                                                isInvestmentPeriod: !isTableDetails.isInvestmentPeriod,
                                                isBenefitTarget: false,
                                                isMaxDailyLoss: false,
                                                isMaxLoss: false,
                                                isMinTraidingDays: false,
                                                isRefundableFee: false,
                                            })
                                        }>
                                            {isTableDetails.isInvestmentPeriod ? (
                                                <ArrowTop/>
                                            ) : (
                                                <ArrowDown/>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className={`${i18next.language == 'en' ? 'border-l rounded-[0_25px_0_0]' : 'border-r rounded-[25px_0_0_0]'} w-[50%] h-[101.5px] bg-[#F5F5F5] text-[14px] sm:text-lg font-medium`}>
                                30 {i18next.t('HomePage.روزه','روزه')}
                            </td>
                        </tr>
                        {isTableDetails.isInvestmentPeriod ? (
                            <tr className="plan-detail-anime">
                                <td colSpan={2}>
                                    <TablePlanDetails/>
                                </td>
                            </tr>
                        ) : null}
                        <tr className="bg-white">
                            <td className={`${i18next.language == 'en' ? 'border-r' : 'border-l'} w-[50%] h-[101.5px] justify-between items-center px-[40px]`}>
                                <div className='grid sm:grid-flow-col sm:justify-between items-center'>
                                    <p className="text-[12px] sm:text-lg mt-2 mb-2 sm:mb-0 sm:mt-0 font-extrabold">{i18next.t('HomePage.حداقل روزهای معاملاتی','حداقل روزهای معاملاتی')}</p>
                                    <div className='cursor-pointer'>
                                        <div className="inline-block" onClick={() =>
                                            setIsTableDetails({
                                                ...isTableDetails,
                                                isMinTraidingDays: !isTableDetails.isMinTraidingDays,
                                                isInvestmentPeriod: false,
                                                isBenefitTarget: false,
                                                isMaxDailyLoss: false,
                                                isMaxLoss: false,
                                                isRefundableFee: false,
                                            })
                                        }>
                                            {isTableDetails.isMinTraidingDays ? (
                                                <ArrowTop/>
                                            ) : (
                                                <ArrowDown/>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="w-[50%] h-[101.5px] text-[14px] sm:text-lg font-medium">
                                10 {i18next.t('HomePage.روزه','روزه')}
                            </td>
                        </tr>
                        {isTableDetails.isMinTraidingDays ? (
                            <tr className="plan-detail-anime">
                                <td colSpan={4}>
                                    <TablePlanDetails/>
                                </td>
                            </tr>
                        ) : null}
                        <tr className="bg-[#F5F5F5]">
                            <td className={`${i18next.language == 'en' ? 'border-r' : 'border-l'} w-[50%] h-[101.5px] px-[40px] drop-shadow-[5px_5px_40px_rgba(0,0,0,0.05)] bg-[#F4F4F4]`}>
                                <div className='grid sm:grid-flow-col sm:justify-between items-center'>
                                    <p className="text-[12px] sm:text-lg mt-2 mb-2 sm:mb-0 sm:mt-0 font-extrabold">{i18next.t('HomePage.حداکثر ضرر روزانه','حداکثر ضرر روزانه')}</p>
                                    <div className='cursor-pointer'>
                                        <div className="inline-block" onClick={() =>
                                            setIsTableDetails({
                                                ...isTableDetails,
                                                isMaxDailyLoss: !isTableDetails.isMaxDailyLoss,
                                                isMinTraidingDays: false,
                                                isInvestmentPeriod: false,
                                                isBenefitTarget: false,
                                                isMaxLoss: false,
                                                isRefundableFee: false,
                                            })
                                        }>
                                            {isTableDetails.isMaxDailyLoss ? <ArrowTop/> : <ArrowDown/>}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="w-[50%] h-[101.5px] text-[14px] sm:text-lg font-medium">
                                30 {i18next.t('HomePage.روزه','روزه')}
                            </td>
                        </tr>
                        {isTableDetails.isMaxDailyLoss ? (
                            <tr className="plan-detail-anime">
                                <td colSpan={4}>
                                    <TablePlanDetails/>
                                </td>
                            </tr>
                        ) : null}
                        <tr className="bg-white">
                            <td className={`${i18next.language == 'en' ? 'border-r' : 'border-l'} w-[50%] h-[101.5px] justify-between items-center px-[40px]`}>
                                <div className='grid sm:grid-flow-col sm:justify-between items-center'>
                                    <p className="text-[12px] sm:text-lg mt-2 mb-2 sm:mb-0 sm:mt-0 font-extrabold">{i18next.t('HomePage.حداکثر ضرر','حداکثر ضرر')}</p>
                                    <div className='cursor-pointer'>
                                        <div className="inline-block" onClick={() =>
                                            setIsTableDetails({
                                                ...isTableDetails,
                                                isMaxLoss: !isTableDetails.isMaxLoss,
                                                isMinTraidingDays: false,
                                                isInvestmentPeriod: false,
                                                isBenefitTarget: false,
                                                isMaxDailyLoss: false,
                                                isRefundableFee: false,
                                            })
                                        }>
                                            {isTableDetails.isMaxLoss ? <ArrowTop/> : <ArrowDown/>}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="w-[50%] h-[101.5px] text-[14px] sm:text-lg font-medium">
                                500 $
                            </td>
                        </tr>
                        {isTableDetails.isMaxLoss ? (
                            <tr className="plan-detail-anime">
                                <td colSpan={4}>
                                    <TablePlanDetails/>
                                </td>
                            </tr>
                        ) : null}
                        <tr className="bg-[#F5F5F5]">
                            <td className={`${i18next.language == 'en' ? 'border-r' : 'border-l'} w-[50%] h-[101.5px] px-[40px] drop-shadow-[5px_5px_40px_rgba(0,0,0,0.05)] bg-[#F4F4F4]`}>
                                <div className='grid sm:grid-flow-col sm:justify-between items-center'>
                                    <p className="text-[12px] sm:text-lg mt-2 mb-2 sm:mb-0 sm:mt-0 font-extrabold">{i18next.t('HomePage.هدف سود','هدف سود')}</p>
                                    <div className='cursor-pointer'>
                                        <div className="inline-block" onClick={() =>
                                            setIsTableDetails({
                                                ...isTableDetails,
                                                isBenefitTarget: !isTableDetails.isBenefitTarget,
                                                isMinTraidingDays: false,
                                                isInvestmentPeriod: false,
                                                isMaxDailyLoss: false,
                                                isMaxLoss: false,
                                                isRefundableFee: false,
                                            })
                                        }>
                                            {isTableDetails.isBenefitTarget ? (
                                                <ArrowTop/>
                                            ) : (
                                                <ArrowDown/>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="w-[50%] h-[101.5px] text-[14px] sm:text-lg font-medium">
                                1000 $
                            </td>
                        </tr>
                        {isTableDetails.isBenefitTarget ? (
                            <tr className="plan-detail-anime">
                                <td colSpan={4}>
                                    <TablePlanDetails/>
                                </td>
                            </tr>
                        ) : null}
                        <tr className="">
                            <td className={`${i18next.language == 'en' ? 'border-r rounded-[0_0_0_25px]' : 'border-l rounded-[0_0_25px_0]'} bg-white w-[50%] h-[101.5px] justify-between items-center px-[40px]`}>
                                <div className='grid sm:grid-flow-col sm:justify-between items-center'>
                                    <p className="text-[12px] sm:text-lg mt-2 mb-2 sm:mb-0 sm:mt-0 font-extrabold">{i18next.t('HomePage.هزینه قابل استرداد','هزینه قابل استرداد')}</p>
                                    <div className='cursor-pointer'>
                                        <div className="inline-block" onClick={() =>
                                            setIsTableDetails({
                                                ...isTableDetails,
                                                isRefundableFee: !isTableDetails.isRefundableFee,
                                                isMinTraidingDays: false,
                                                isInvestmentPeriod: false,
                                                isBenefitTarget: false,
                                                isMaxDailyLoss: false,
                                                isMaxLoss: false,
                                            })
                                        }>
                                            {isTableDetails.isRefundableFee ? (
                                                <ArrowTop/>
                                            ) : (
                                                <ArrowDown/>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className={`${i18next.language == 'en' ? 'rounded-[0_0_25px_0]' : 'rounded-[0_0_0_25px]'} w-[50%] h-[101.5px] text-[14px] sm:text-lg font-medium bg-white`}>
                                30 {i18next.t('HomePage.روزه','روزه')}
                            </td>
                        </tr>
                        {isTableDetails.isRefundableFee ? (
                            <tr className="plan-detail-anime">
                                <td colSpan={4}>
                                    <TablePlanDetails/>
                                </td>
                            </tr>
                        ) : null}
                        </tbody>
                    </table>
                </SwiperSlide> <SwiperSlide className='mt-[50px] mb-[20px]'>
                    <table dir={'auto'} className="rounded-[20px] rounded-tr-[0] text-center w-full">
                        <thead>
                        <tr className="">
                            <th colSpan='2' className="relative leading-[30px] px-[25px]">
                                <div className='bg-[#FDFDFD] rounded-[25px_25px_0_0] h-full pt-[35px] mb-[-2px] pt-[50px] h-[140px]'>
                                    <div className="absolute flex flex-col justify-center w-[76px] h-[76px] bg-white rounded-full shadow-[5px_5px_40px_rgba(0,0,0,0.05)] top-[-38px] left-1/2 -translate-x-1/2">
                                        <div className="text-primary-btn-color text-[40px] font-medium">
                                            3
                                        </div>
                                    </div>
                                    <div className="text-sm font-medium text-secondary-bg-color mb-[10px]">
                                        {i18next.t('HomePage.مرحله سوم','مرحله سوم')}
                                    </div>
                                    <div className="text-xl font-extrabold text-primary-btn-color">
                                        {i18next.t('HomePage.ترید با سرمایه','ترید با سرمایه')}
                                    </div>
                                </div>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr className={`${isTableDetails.isInvestmentPeriod ? "" : ""}`}>
                            <td className={`${i18next.language == 'en' ? 'border-r rounded-[25px_0_0_0]' : 'border-l rounded-[0_25px_0_0]'} w-[50%] h-[101.5px] px-[40px] drop-shadow-[5px_5px_40px_rgba(0,0,0,0.05)] bg-[#F4F4F4]`}>
                                <div className='grid sm:grid-flow-col sm:justify-between items-center'>
                                    <p className="text-[12px] sm:text-lg mt-2 mb-2 sm:mb-0 sm:mt-0  font-extrabold">{i18next.t('HomePage.دوره سرمایه گذاری','دوره سرمایه گذاری')}</p>
                                    <div className='cursor-pointer'>
                                        <div className="inline-block" onClick={() =>
                                            setIsTableDetails({
                                                ...isTableDetails,
                                                isInvestmentPeriod: !isTableDetails.isInvestmentPeriod,
                                                isBenefitTarget: false,
                                                isMaxDailyLoss: false,
                                                isMaxLoss: false,
                                                isMinTraidingDays: false,
                                                isRefundableFee: false,
                                            })
                                        }>
                                            {isTableDetails.isInvestmentPeriod ? (
                                                <ArrowTop/>
                                            ) : (
                                                <ArrowDown/>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className={`${i18next.language == 'en' ? 'border-l rounded-[0_25px_0_0]' : 'border-r rounded-[25px_0_0_0]'} w-[50%] h-[101.5px] bg-[#F5F5F5] text-[14px] sm:text-lg font-medium`}>
                                30 {i18next.t('HomePage.روزه','روزه')}
                            </td>
                        </tr>
                        {isTableDetails.isInvestmentPeriod ? (
                            <tr className="plan-detail-anime">
                                <td colSpan={2}>
                                    <TablePlanDetails/>
                                </td>
                            </tr>
                        ) : null}
                        <tr className="bg-white">
                            <td className={`${i18next.language == 'en' ? 'border-r' : 'border-l'} w-[50%] h-[101.5px] justify-between items-center px-[40px]`}>
                                <div className='grid sm:grid-flow-col sm:justify-between items-center'>
                                    <p className="text-[12px] sm:text-lg mt-2 mb-2 sm:mb-0 sm:mt-0 font-extrabold">{i18next.t('HomePage.حداقل روزهای معاملاتی','حداقل روزهای معاملاتی')}</p>
                                    <div className='cursor-pointer'>
                                        <div className="inline-block" onClick={() =>
                                            setIsTableDetails({
                                                ...isTableDetails,
                                                isMinTraidingDays: !isTableDetails.isMinTraidingDays,
                                                isInvestmentPeriod: false,
                                                isBenefitTarget: false,
                                                isMaxDailyLoss: false,
                                                isMaxLoss: false,
                                                isRefundableFee: false,
                                            })
                                        }>
                                            {isTableDetails.isMinTraidingDays ? (
                                                <ArrowTop/>
                                            ) : (
                                                <ArrowDown/>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="w-[50%] h-[101.5px] text-[14px] sm:text-lg font-medium">
                                10 {i18next.t('HomePage.روزه','روزه')}
                            </td>
                        </tr>
                        {isTableDetails.isMinTraidingDays ? (
                            <tr className="plan-detail-anime">
                                <td colSpan={4}>
                                    <TablePlanDetails/>
                                </td>
                            </tr>
                        ) : null}
                        <tr className="bg-[#F5F5F5]">
                            <td className={`${i18next.language == 'en' ? 'border-r' : 'border-l'} w-[50%] h-[101.5px] px-[40px] drop-shadow-[5px_5px_40px_rgba(0,0,0,0.05)] bg-[#F4F4F4]`}>
                                <div className='grid sm:grid-flow-col sm:justify-between items-center'>
                                    <p className="text-[12px] sm:text-lg mt-2 mb-2 sm:mb-0 sm:mt-0 font-extrabold">{i18next.t('HomePage.حداکثر ضرر روزانه','حداکثر ضرر روزانه')}</p>
                                    <div className='cursor-pointer'>
                                        <div className="inline-block" onClick={() =>
                                            setIsTableDetails({
                                                ...isTableDetails,
                                                isMaxDailyLoss: !isTableDetails.isMaxDailyLoss,
                                                isMinTraidingDays: false,
                                                isInvestmentPeriod: false,
                                                isBenefitTarget: false,
                                                isMaxLoss: false,
                                                isRefundableFee: false,
                                            })
                                        }>
                                            {isTableDetails.isMaxDailyLoss ? <ArrowTop/> : <ArrowDown/>}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="w-[50%] h-[101.5px] text-[14px] sm:text-lg font-medium">
                                30 {i18next.t('HomePage.روزه','روزه')}
                            </td>
                        </tr>
                        {isTableDetails.isMaxDailyLoss ? (
                            <tr className="plan-detail-anime">
                                <td colSpan={4}>
                                    <TablePlanDetails/>
                                </td>
                            </tr>
                        ) : null}
                        <tr className="bg-white">
                            <td className={`${i18next.language == 'en' ? 'border-r' : 'border-l'} w-[50%] h-[101.5px] justify-between items-center px-[40px]`}>
                                <div className='grid sm:grid-flow-col sm:justify-between items-center'>
                                    <p className="text-[12px] sm:text-lg mt-2 mb-2 sm:mb-0 sm:mt-0 font-extrabold">{i18next.t('HomePage.حداکثر ضرر','حداکثر ضرر')}</p>
                                    <div className='cursor-pointer'>
                                        <div className="inline-block" onClick={() =>
                                            setIsTableDetails({
                                                ...isTableDetails,
                                                isMaxLoss: !isTableDetails.isMaxLoss,
                                                isMinTraidingDays: false,
                                                isInvestmentPeriod: false,
                                                isBenefitTarget: false,
                                                isMaxDailyLoss: false,
                                                isRefundableFee: false,
                                            })
                                        }>
                                            {isTableDetails.isMaxLoss ? <ArrowTop/> : <ArrowDown/>}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="w-[50%] h-[101.5px] text-[14px] sm:text-lg font-medium">
                                500 $
                            </td>
                        </tr>
                        {isTableDetails.isMaxLoss ? (
                            <tr className="plan-detail-anime">
                                <td colSpan={4}>
                                    <TablePlanDetails/>
                                </td>
                            </tr>
                        ) : null}
                        <tr className="bg-[#F5F5F5]">
                            <td className={`${i18next.language == 'en' ? 'border-r' : 'border-l'} w-[50%] h-[101.5px] px-[40px] drop-shadow-[5px_5px_40px_rgba(0,0,0,0.05)] bg-[#F4F4F4]`}>
                                <div className='grid sm:grid-flow-col sm:justify-between items-center'>
                                    <p className="text-[12px] sm:text-lg mt-2 mb-2 sm:mb-0 sm:mt-0 font-extrabold">{i18next.t('HomePage.هدف سود','هدف سود')}</p>
                                    <div className='cursor-pointer'>
                                        <div className="inline-block" onClick={() =>
                                            setIsTableDetails({
                                                ...isTableDetails,
                                                isBenefitTarget: !isTableDetails.isBenefitTarget,
                                                isMinTraidingDays: false,
                                                isInvestmentPeriod: false,
                                                isMaxDailyLoss: false,
                                                isMaxLoss: false,
                                                isRefundableFee: false,
                                            })
                                        }>
                                            {isTableDetails.isBenefitTarget ? (
                                                <ArrowTop/>
                                            ) : (
                                                <ArrowDown/>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="w-[50%] h-[101.5px] text-[14px] sm:text-lg font-medium">
                                1000 $
                            </td>
                        </tr>
                        {isTableDetails.isBenefitTarget ? (
                            <tr className="plan-detail-anime">
                                <td colSpan={4}>
                                    <TablePlanDetails/>
                                </td>
                            </tr>
                        ) : null}
                        <tr className="">
                            <td className={`${i18next.language == 'en' ? 'border-r rounded-[0_0_0_25px]' : 'border-l rounded-[0_0_25px_0]'} bg-white w-[50%] h-[101.5px] justify-between items-center px-[40px]`}>
                                <div className='grid sm:grid-flow-col sm:justify-between items-center'>
                                    <p className="text-[12px] sm:text-lg mt-2 mb-2 sm:mb-0 sm:mt-0 font-extrabold">{i18next.t('HomePage.هزینه قابل استرداد','هزینه قابل استرداد')}</p>
                                    <div className='cursor-pointer'>
                                        <div className="inline-block" onClick={() =>
                                            setIsTableDetails({
                                                ...isTableDetails,
                                                isRefundableFee: !isTableDetails.isRefundableFee,
                                                isMinTraidingDays: false,
                                                isInvestmentPeriod: false,
                                                isBenefitTarget: false,
                                                isMaxDailyLoss: false,
                                                isMaxLoss: false,
                                            })
                                        }>
                                            {isTableDetails.isRefundableFee ? (
                                                <ArrowTop/>
                                            ) : (
                                                <ArrowDown/>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className={`${i18next.language == 'en' ? 'rounded-[0_0_25px_0]' : 'rounded-[0_0_0_25px]'} w-[50%] h-[101.5px] text-[14px] sm:text-lg font-medium bg-white`}>
                                30 {i18next.t('HomePage.روزه','روزه')}
                            </td>
                        </tr>
                        {isTableDetails.isRefundableFee ? (
                            <tr className="plan-detail-anime">
                                <td colSpan={4}>
                                    <TablePlanDetails/>
                                </td>
                            </tr>
                        ) : null}
                        </tbody>
                    </table>
                </SwiperSlide> </Swiper>
            </section>

            <div className="flex flex-col justify-center items-center space-y-[23px]">
                <p className="text-white text-[24px] sm:text-[34px] font-extrabold">{i18next.t('HomePage.برای ترید آماده هستید؟','برای ترید آماده هستید؟')}</p>
                <button className="w-full sm:w-[465px] h-[62px] bg-primary-btn-color text-white hover:bg-secondary-btn-color transition-all rounded-[10px] px-[60px] py-[11px]">
                    {i18next.t('HomePage.ثبت سفارش پلن','ثبت سفارش پلن')}
                </button>
            </div>
        </main>
    );
};

export default withTranslation()(SarmayegozarPlan);
