import InfoHeader from "../components/buy/InfoHeader";
import useTitle from "../hooks/useTitle";
import CheckoutSecond from "../components/buy/CheckoutSecond";
import {Navigate, useLocation} from "react-router-dom";
import React from "react";
import {withTranslation} from "react-i18next";
import i18n from "../i18n";

const Buy = ({t}) => {
    useTitle(t('PageTitle.Buy', 'خرید'))

    const location = useLocation()

    if (!location.state) {
        return (<Navigate to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}plans`}/>)
    }

    return (
        <section className='w-full overflow-hidden bg-primary-bg-color'>
            <InfoHeader/>
            <CheckoutSecond package={location.state.package}/>
        </section>
    );
};

export default withTranslation()(Buy);