import React from "react";
import BlogCard from "../BlogCard";
import axios from "axios";
import {Trans, withTranslation} from "react-i18next";

class SuggestedBlog extends React.Component {
    state = {
        Articles: []
    }

    componentDidMount() {
        axios.get(`/api/4/blog`)
            .then(res => {
                const Articles = res.data.data;
                this.setState({Articles});
            })
    }

    render() {
        return (
            <div className="w-full px-[25px] md:px-[72px] mt-[20px] sm:mt-[69px] mb-[72px]">
                <div className="flex items-center gap-[32px] mb-[34px]">
                    <div className="text-secondary-bg-color text-[22px] sm:text-[32px] font-extrabold whitespace-nowrap text-center sm:text-right w-full sm:w-max">
                        <Trans i18nKey='Blog.RelatedPost'> مطالب <span className="font-light">پیشنهادی</span> </Trans>
                    </div>
                    <div className="hidden sm:block w-full h-[1px] bg-[#E8E8E8]"/>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-[24px]">
                    {
                        this.state.Articles ?
                            this.state.Articles.map((article) =>
                                <div key={article.id} className="col-span-1">
                                    <BlogCard title={article.title} desc={article.desc} thumbnail={article.thumbnail_tbl} slug={article.slug}/>
                                </div>
                            ) : null
                    }
                </div>
            </div>
        );
    };
};

export default withTranslation()(SuggestedBlog)
