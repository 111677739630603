import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Trans, withTranslation} from "react-i18next";
import i18next from "i18next";

const PopularQuestions = (props) => {
    const [isQuestionOne, SetIsQuestionOne] = useState(true);
    const [isQuestionTwo, SetIsQuestionTwo] = useState(false);
    const [isQuestionThree, SetIsQuestionThree] = useState(false);
    const [isQuestionFour, SetIsQuestionFour] = useState(false);

    const navigate = useNavigate();

    return (
        <section className="w-full mb-[109px] px-[25px] sm:px-[72px]">
            <div className="flex justify-center sm:justify-between items-center gap-[40px] mb-[46px]">
                <p className="text-[22px] sm:text-[34px] font-extrabold whitespace-nowrap">
                    <Trans i18nKey='HomePage.سوالات متداول'>سوالات <span className="font-light">متداول</span></Trans>
                </p>
                <div className="hidden sm:block w-full h-[1px] bg-[#E8E8E8]"/>
                <button onClick={() => navigate("rules")} className="hidden sm:block text-[#C7C7C7] border hover:text-primary-btn-color hover:border-primary-btn-color transition-all rounded-[10px] whitespace-nowrap px-[30px] py-[18px]">
                    {i18next.t('HomePage.سوالات بیشتر', 'سوالات بیشتر')}
                </button>
            </div>
            <div className="flex flex-wrap lg:flex-nowrap justify-between gap-[46px]">
                <div className="flex flex-col w-full lg:w-min items-center space-y-[20px]">
                    <button className={`w-full lg:w-min text-[12px] sm:text-sm font-bold rounded-[10px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] px-[60px] py-[20px] ${
                        isQuestionOne
                            ? "text-white bg-primary-btn-color"
                            : "text-[#767D80] bg-white"
                    }`} onClick={() => {
                        SetIsQuestionOne(true);
                        SetIsQuestionTwo(false);
                        SetIsQuestionThree(false);
                        SetIsQuestionFour(false);
                    }}>
                        <div className="w-full lg:w-[406px]" dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.question_1_en : props.dataContent.question_1_fa }}/>
                    </button>
                    <button className={`w-full lg:w-min text-[12px] sm:text-sm font-bold rounded-[10px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] px-[60px] py-[20px] ${
                        isQuestionTwo
                            ? "text-white bg-primary-btn-color"
                            : "text-[#767D80] bg-white"
                    }`} onClick={() => {
                        SetIsQuestionOne(false);
                        SetIsQuestionTwo(true);
                        SetIsQuestionThree(false);
                        SetIsQuestionFour(false);
                    }}>
                        <div className="w-full lg:w-[406px]" dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.question_2_en : props.dataContent.question_2_fa }}/>
                    </button>
                    <button className={`w-full lg:w-min text-[12px] sm:text-sm font-bold rounded-[10px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] px-[60px] py-[20px] ${
                        isQuestionThree
                            ? "text-white bg-primary-btn-color"
                            : "text-[#767D80] bg-white"
                    }`} onClick={() => {
                        SetIsQuestionOne(false);
                        SetIsQuestionTwo(false);
                        SetIsQuestionThree(true);
                        SetIsQuestionFour(false);
                    }}>
                        <div className="w-full lg:w-[406px]" dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.question_3_en : props.dataContent.question_3_fa }}/>
                    </button>
                    <button className={`w-full lg:w-min text-[12px] sm:text-sm font-bold rounded-[10px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] px-[60px] py-[20px] ${
                        isQuestionFour
                            ? "text-white bg-primary-btn-color"
                            : "text-[#767D80] bg-white"
                    }`} onClick={() => {
                        SetIsQuestionOne(false);
                        SetIsQuestionTwo(false);
                        SetIsQuestionThree(false);
                        SetIsQuestionFour(true);
                    }}>
                        <div className="w-full lg:w-[406px]" dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.question_4_en : props.dataContent.question_4_fa }}/>
                    </button>
                </div>
                <div className="bg-white rounded-[20px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] px-[40px] py-[30px]">
                    <p className="text-[12px] sm:text-[15px] leading-[35px] font-medium" dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.answer_1_en : props.dataContent.answer_1_fa }}/>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(PopularQuestions);
