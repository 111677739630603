export default ({color = "#C8C8C8"}) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.3125 8.59822H15.0399V5.48165C15.0828 5.02598 15.2916 4.60687 15.6208 4.31518C15.95 4.02349 16.3731 3.88281 16.7984 3.92356H18.5554V0.0197277H15.0383C13.7634 -0.0994013 12.4961 0.324401 11.5099 1.19901C10.5236 2.07361 9.8977 3.329 9.76721 4.69439V8.59822H6.25V12.5017H9.76721V25H15.0383V12.5017H18.5554L20.3125 8.59822Z"
      fill={color}
    />
  </svg>
);
