export default ({color = "#C8C8C8"}) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.9379 4.78145C23.6628 4.26207 23.2367 3.83943 22.7164 3.56984C22.196 3.30026 21.6063 3.19668 21.0259 3.27275C19.5282 3.17435 15.7645 3.125 12.5033 3.125C9.24206 3.125 5.47021 3.17435 3.97582 3.27275C3.39478 3.19751 2.80466 3.3016 2.28374 3.57104C1.76282 3.84049 1.33565 4.26245 1.05878 4.78145C0.360525 5.99507 0 8.06148 0 11.7188V11.7352C0 15.3596 0.360525 17.4588 1.05878 18.6561C1.33684 19.1746 1.76343 19.5973 2.28325 19.8693C2.80306 20.1412 3.39211 20.2501 3.97412 20.1816C5.47014 20.2636 9.23716 20.3125 12.5033 20.3125C15.7694 20.3125 19.5282 20.2636 21.0259 20.1816C21.6078 20.2512 22.197 20.1429 22.7168 19.8709C23.2365 19.5988 23.6627 19.1752 23.9396 18.6561C24.6444 17.4588 25 15.376 25 11.7352V11.7188C24.9935 8.06148 24.6443 5.99507 23.9379 4.78145ZM9.37585 16.4094V7.02848L17.1871 11.7188L9.37585 16.4094Z"
      fill={color}
    />
  </svg>
);
