import React, {useEffect, useState} from "react";
import BlogCard from "../BlogCard";
import axios from "axios";
import {ClipLoader} from "react-spinners";

const CategoryBlogs = () => {
    const [articleData, setArticleData] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        fetchArticle()
    }, [])

    const fetchArticle = async () => {
        axios.get(`/api/4/blog`)
            .then(res => {
                const Articles = res.data.data;
                setArticleData(Articles);
                setLoading(false)
            })
    }

    if (loading) {
        return (
            <div className='text-center py-[80px] bg-primary-bg-color'>
                <ClipLoader color={'#0098ff'} loading={loading} size={60} aria-label="Loading Spinner" data-testid="loader"/>
            </div>
        )
    }else {
        if (articleData) {
            return (
                <>
                    <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-[24px] px-[25px] md:px-[72px] mb-[80px]">
                        {
                            articleData ?
                                articleData.map((item) =>
                                    <div key={item.id} className="w-full col-span-1">
                                        <BlogCard title={item.title} desc={item.desc} thumbnail={item.thumbnail_tbl} slug={item.slug}/>
                                    </div>
                                ) : null
                        }
                    </section>
                    <>
                        {/*<ReactPaginate*/} {/*  previousLabel={<RightSliderArrow color="#C3C3C3" />}*/} {/*  nextLabel={<LeftSliderArrow color="#C3C3C3" />}*/} {/*  pageCount={999}*/} {/*  // onPageChange={pageClick}*/} {/*  containerClassName="flex justify-center items-center text-[#C3C3C3] font-bold gap-[40px] mt-[79px] mb-[106px]"*/} {/*  pageClassName="hover:text-primary-btn-color transition duration-[300ms]"*/} {/*  nextClassName=""*/} {/*  previousClassName=""*/} {/*  breakClassName="hover:text-primary-btn-color"*/} {/*/>*/}
                    </>
                </>
            );
        }
    }
};

export default CategoryBlogs
