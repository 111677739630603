export default () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.02 5.31999L13.23 8.52999L15.2 10.49C16.03 11.32 16.03 12.67 15.2 13.5L10.02 18.68C9.34001 19.36 8.18001 18.87 8.18001 17.92V12.31V6.07999C8.18001 5.11999 9.34001 4.63999 10.02 5.31999Z"
      fill="#0098FF"
    />
  </svg>
);
