import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import en from './en';
import fa from './fa';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from "i18next-http-backend"

i18n
    .use(XHR)
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        // supportedLngs: ['en1', 'fa'],
        supportedLngs: ['en'],
        resources: {
            en: {
                translation: en
            },
            fa: {
                translation: fa
            }
        },
        // lng: document.querySelector('html').lang,
        // fallbackLng: "fa",
        fallbackLng: "en",
        detection: {
            // order: ['path', 'cookie', 'htmlTag', 'querystring', 'localStorage', 'sessionStorage', 'navigator', 'subdomain'],
            order: ['path'],
            caches: ['cookie'],
        }

        // interpolation: {
        //     escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        // }
    });

export default i18n