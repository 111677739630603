import PlansArrowButton from "../../assets/svg/PlansArrowButton";
import {Link, useNavigate} from "react-router-dom";
import i18next from "i18next";
import {Trans, withTranslation} from "react-i18next";
import i18n from "../../i18n";

const PlansTable = ({t}) => {
    const navigate = useNavigate();

    return (
        <div className="sm:h-[723px] mb-[30px] sm:mb-0 mx-[15px] md:mx-[72px] overflow-hidden">
            <div className="relative mx-auto text-[#1B262C]">
                <div className="grid grid-flow-col justify-center auto-cols-auto w-full h-full top-0 left-0 ">
                    <div className={`overflow-hidden z-20 right-0 h-[507px] w-max sm:w-[196px] flex flex-col items-center ${i18next.language === 'fa' ? 'shadow-[5px_5px_40px_rgba(0,0,0,0.05)] rounded-[0px_20px_20px_0]' : 'shadow-[-5px_5px_40px_rgba(0,0,0,0.05)] rounded-[20px_0_0_20px]'}`}>
                        <div className="bg-white w-full h-[82px] text-center text-[14px] sm:text-[18px] font-extrabold pt-2">
                            <Trans i18nKey='PlansPage.PlansTable.پلن ها'> پلن ها <span className="font-light text-[12px] sm:text-[14px] leading-[70px]">(یکبار پرداخت)</span> </Trans>
                        </div>
                        <ul className="flex flex-col items-center p-0 w-full h-[425px]">
                            <li className="flex justify-center gap-[10px] px-[10px] bg-white w-full py-[7.5px] border-t-[1px] border-[#F3F3F3]">
                                <p className="leading-[70px] text-[11px] sm:text-[13px] font-bold">{t('PlansPage.PlansTable.تارگت مرحله اول', 'تارگت مرحله اول')}</p>
                            </li>
                            <li className="flex justify-center gap-[10px] px-[10px] bg-[#F1F1F1] w-full py-[7.5px]">
                                <p className="leading-[70px] text-[11px] sm:text-[13px] font-bold">{t('PlansPage.PlansTable.تارگت مرحله دوم', 'تارگت مرحله دوم')}</p>
                            </li>
                            <li className="flex justify-center gap-[10px] px-[10px] bg-white w-full py-[7.5px]">
                                <p className="leading-[70px] text-[11px] sm:text-[13px] font-bold">Daily Drawdown</p>
                            </li>
                            <li className="flex justify-center gap-[10px] px-[10px] bg-[#F1F1F1] w-full py-[7.5px]">
                                <p className="leading-[70px] text-[11px] sm:text-[13px] font-bold">
                                    (12%) Maximum Drawdown </p>
                            </li>
                            <li className="flex justify-center gap-[10px] px-[10px] bg-white w-full py-[7.5px]">
                                <p className="leading-[70px] text-[11px] sm:text-[13px] font-bold">{t('PlansPage.PlansTable.رشد سرمایه', 'رشد سرمایه')}</p>
                            </li>
                        </ul>
                    </div>
                    <div className={`grid grid-flow-col auto-cols-auto right-[190px] xl:right-[178px] z-10 h-fit shadow-[5px_5px_40px_rgba(0,0,0,0.05)] bg-[#f6f6f6] overflow-x-auto ${i18next.language === 'fa' ? 'rounded-tl-[20px]' : 'rounded-tr-[20px]'}`}>
                        <div className="w-[186.33px] h-full ">
                            <div className="flex flex-col items-center">
                                <div className="bg-[#F1F1F1] w-full h-[82px] text-center text-[24px] font-extrabold pt-2 " dir='rtl'>
                                    5,000 <span className="font-bold text-[16px] leading-[70px]">{t('دلار', 'دلار')}</span>
                                </div>
                                <ul className={`flex flex-col items-center p-0 w-full h-full overflow-hidden ${i18next.language === 'fa' ? 'rounded-br-[20px]' : 'rounded-bl-[20px]'}`}>
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-white w-full py-[7.5px] border-t-[1px] border-[#F3F3F3]">
                                        <p className="leading-[70px] text-[13px] font-bold" dir='rtl'>400 $ </p>
                                    </li>
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-[#F1F1F1] w-full py-[7.5px]">
                                        <p className="leading-[70px] text-[13px] font-bold" dir='rtl'>
                                            200 $ </p>
                                    </li>
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-white w-full py-[7.5px]">
                                        <p className="leading-[70px] text-[13px] font-bold" dir='rtl'>
                                            5% </p>
                                    </li>
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-[#F1F1F1] w-full py-[7.5px]">
                                        <p className="leading-[70px] text-[13px] font-bold" dir='rtl'>
                                            4,400 $ </p>
                                    </li>
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-white w-full py-[7.5px]">
                                        <p className="leading-[70px] text-[13px] font-bold">{t('PlansPage.PlansTable.تا سقف 1,000,000$', 'تا سقف 1,000,000$')}</p>
                                    </li>
                                    <li className="bg-white w-full py-[30px]">
                                        <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}buy`} state={{package: 1}} className="rounded-[10px] text-white flex justify-center mx-auto items-center text-center bg-primary-btn-color hover:bg-secondary-btn-color w-[116.33px] h-[47px]">
                                            <div className="w-max mx-[10px] text-[16px] font-bold" dir='rtl'>
                                                66 <span className='text-[13px]'>{t('دلار', 'دلار')}</span>
                                            </div>
                                            <div className="w-max">
                                                <PlansArrowButton/>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="w-[186.33px] h-full ">
                            <div className="flex flex-col items-center">
                                <div className="bg-[#F1F1F1] w-full h-[82px] text-center text-[24px] font-extrabold pt-2" dir='rtl'>
                                    10,000 <span className="font-bold text-[16px] leading-[70px]">{t('دلار', 'دلار')}</span>
                                </div>
                                <ul className="flex flex-col items-center p-0 w-full h-full">
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-white w-full py-[7.5px] border-t-[1px] border-[#F3F3F3]">
                                        <p className="leading-[70px] text-[13px] font-bold" dir='rtl'>
                                            400 $ </p>
                                    </li>
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-[#F1F1F1] w-full py-[7.5px]">
                                        <p className="leading-[70px] text-[13px] font-bold" dir='rtl'>
                                            200 $ </p>
                                    </li>
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-white w-full py-[7.5px]">
                                        <p className="leading-[70px] text-[13px] font-bold">
                                            5% </p>
                                    </li>
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-[#F1F1F1] w-full py-[7.5px]">
                                        <p className="leading-[70px] text-[13px] font-bold" dir='rtl'>
                                            4,400 $ </p>
                                    </li>
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-white w-full py-[7.5px]">
                                        <p className="leading-[70px] text-[13px] font-bold">{t('PlansPage.PlansTable.تا سقف 1,000,000$', 'تا سقف 1,000,000$')}</p>
                                    </li>
                                    <li className="bg-white w-full py-[30px] text-center">
                                        <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}buy`} state={{package: 2}} className="rounded-[10px] text-white flex mx-auto items-center justify-center bg-primary-btn-color hover:bg-secondary-btn-color w-[116.33px] h-[47px]">
                                            <div className="w-max mx-[10px] text-[16px] font-bold" dir='rtl'>
                                                88 <span className='text-[13px]'>{t('دلار', 'دلار')}</span>
                                            </div>
                                            <div className="w-max">
                                                <PlansArrowButton/>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="w-[186.33px] h-full ">
                            <div className="flex flex-col items-center">
                                <div className="bg-[#F1F1F1] w-full h-[82px] text-center text-[24px] font-extrabold pt-2" dir='rtl'>
                                    25,000 <span className="font-bold text-[16px] leading-[70px]">{t('دلار', 'دلار')}</span>
                                </div>
                                <ul className="flex flex-col items-center p-0 w-full h-full">
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-white w-full py-[7.5px] border-t-[1px] border-[#F3F3F3]">
                                        <p className="leading-[70px] text-[13px] font-bold" dir='rtl'>
                                            400 $ </p>
                                    </li>
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-[#F1F1F1] w-full py-[7.5px]">
                                        <p className="leading-[70px] text-[13px] font-bold" dir='rtl'>
                                            200 $ </p>
                                    </li>
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-white w-full py-[7.5px]">
                                        <p className="leading-[70px] text-[13px] font-bold">
                                            5% </p>
                                    </li>
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-[#F1F1F1] w-full py-[7.5px]">
                                        <p className="leading-[70px] text-[13px] font-bold" dir='rtl'>
                                            4,400 $ </p>
                                    </li>
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-white w-full py-[7.5px]">
                                        <p className="leading-[70px] text-[13px] font-bold">{t('PlansPage.PlansTable.تا سقف 1,000,000$', 'تا سقف 1,000,000$')}</p>
                                    </li>
                                    <li className="bg-white w-full py-[30px] text-center">
                                        <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}buy`} state={{package: 3}} className="rounded-[10px] text-white flex mx-auto items-center justify-center bg-primary-btn-color hover:bg-secondary-btn-color w-[116.33px] h-[47px]">
                                            <div className="w-max mx-[10px] text-[16px] font-bold" dir='rtl'>
                                                157 <span className='text-[13px]'>{t('دلار', 'دلار')}</span>
                                            </div>
                                            <div className="w-max">
                                                <PlansArrowButton/>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="w-[186.33px] h-full ">
                            <div className="flex flex-col items-center">
                                <div className="bg-[#F1F1F1] w-full h-[82px] text-center text-[24px] font-extrabold  pt-2" dir='rtl'>
                                    50,000 <span className="font-bold text-[16px] leading-[70px]">{t('دلار', 'دلار')}</span>
                                </div>
                                <ul className="flex flex-col items-center p-0 w-full h-full">
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-white w-full py-[7.5px] border-t-[1px] border-[#F3F3F3]">
                                        <p className="leading-[70px] text-[13px] font-bold" dir='rtl'>
                                            400 $ </p>
                                    </li>
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-[#F1F1F1] w-full py-[7.5px]">
                                        <p className="leading-[70px] text-[13px] font-bold" dir='rtl'>
                                            200 $ </p>
                                    </li>
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-white w-full py-[7.5px]">
                                        <p className="leading-[70px] text-[13px] font-bold">
                                            5% </p>
                                    </li>
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-[#F1F1F1] w-full py-[7.5px]">
                                        <p className="leading-[70px] text-[13px] font-bold" dir='rtl'>
                                            4,400 $ </p>
                                    </li>
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-white w-full py-[7.5px]">
                                        <p className="leading-[70px] text-[13px] font-bold">{t('PlansPage.PlansTable.تا سقف 1,000,000$', 'تا سقف 1,000,000$')}</p>
                                    </li>
                                    <li className="bg-white w-full py-[30px] text-center">
                                        <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}buy`} state={{package: 4}} className="rounded-[10px] text-white flex mx-auto items-center justify-center bg-primary-btn-color hover:bg-secondary-btn-color w-[116.33px] h-[47px]">
                                            <div className="w-max mx-[10px] text-[16px] font-bold" dir='rtl'>
                                                314 <span className='text-[13px]'>{t('دلار', 'دلار')}</span>
                                            </div>
                                            <div className="w-max">
                                                <PlansArrowButton/>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="w-[186.33px] h-full ">
                            <div className="flex flex-col items-center">
                                <div className="bg-[#F1F1F1] w-full h-[82px] text-center text-[24px] font-extrabold pt-2" dir='rtl'>
                                    100,000 <span className="font-bold text-[16px] leading-[70px]">{t('دلار', 'دلار')}</span>
                                </div>
                                <ul className="flex flex-col items-center p-0 w-full h-full">
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-white w-full py-[7.5px] border-t-[1px] border-[#F3F3F3]">
                                        <p className="leading-[70px] text-[13px] font-bold" dir='rtl'>
                                            400 $ </p>
                                    </li>
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-[#F1F1F1] w-full py-[7.5px]">
                                        <p className="leading-[70px] text-[13px] font-bold" dir='rtl'>
                                            200 $ </p>
                                    </li>
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-white w-full py-[7.5px]">
                                        <p className="leading-[70px] text-[13px] font-bold">
                                            5% </p>
                                    </li>
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-[#F1F1F1] w-full py-[7.5px]">
                                        <p className="leading-[70px] text-[13px] font-bold" dir='rtl'>
                                            4,400 $ </p>
                                    </li>
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-white w-full py-[7.5px]">
                                        <p className="leading-[70px] text-[13px] font-bold">{t('PlansPage.PlansTable.تا سقف 1,000,000$', 'تا سقف 1,000,000$')}</p>
                                    </li>
                                    <li className="bg-white w-full py-[30px] text-center">
                                        <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}buy`} state={{package: 5}} className="rounded-[10px] text-white flex mx-auto items-center text-center bg-primary-btn-color hover:bg-secondary-btn-color w-[116.33px] h-[47px]">
                                            <span className="w-max mr-[21.67px] ml-[19.25px] text-[16px] font-bold" dir='rtl'>
                                                502 <span className='text-[13px]'>{t('دلار', 'دلار')}</span>
                                            </span> <span className="w-max">
                                                <PlansArrowButton/>
                                              </span> </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="w-[186.33px] h-full">
                            <div className="flex flex-col items-center">
                                <div className={`bg-[#F1F1F1] w-full h-[82px] text-center text-[24px] font-extrabold pt-2 ${i18next.language === 'fa' ? 'rounded-tl-[20px]' : 'rounded-tr-[20px]'}`} dir='rtl'>
                                    200,000 <span className="font-bold text-[16px] leading-[70px]">{t('دلار', 'دلار')}</span>
                                </div>
                                <ul className={`flex flex-col items-center p-0 w-full h-full overflow-hidden ${i18next.language === 'fa' ? 'rounded-bl-[20px]' : 'rounded-br-[20px]'}`}>
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-white w-full py-[7.5px] border-t-[1px] border-[#F3F3F3]">
                                        <p className="leading-[70px] text-[13px] font-bold" dir='rtl'>
                                            400 $ </p>
                                    </li>
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-[#F1F1F1] w-full py-[7.5px]">
                                        <p className="leading-[70px] text-[13px] font-bold" dir='rtl'>
                                            200 $ </p>
                                    </li>
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-white w-full py-[7.5px]">
                                        <p className="leading-[70px] text-[13px] font-bold">
                                            5% </p>
                                    </li>
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-[#F1F1F1] w-full py-[7.5px]" dir='rtl'>
                                        <p className="leading-[70px] text-[13px] font-bold" dir='rtl'>
                                            4,400 $ </p>
                                    </li>
                                    <li className="flex justify-center gap-[10px] px-[10px] bg-white w-full py-[7.5px]">
                                        <p className="leading-[70px] text-[13px] font-bold">{t('PlansPage.PlansTable.تا سقف 1,000,000$', 'تا سقف 1,000,000$')}</p>
                                    </li>
                                    <li className="bg-white w-full py-[30px] text-center">
                                        <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}buy`} state={{package: 6}} className={`text-white flex mx-auto items-center text-center bg-primary-btn-color hover:bg-secondary-btn-color w-[116.33px] h-[47px] rounded-[10px]`}>
                                          <span className="w-max mr-[21.67px] ml-[19.25px] text-[16px] font-bold" dir='rtl'>
                                            858 <span className='text-[13px]'>{t('دلار', 'دلار')}</span>
                                          </span> <span className="w-max">
                                            <PlansArrowButton/>
                                          </span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(PlansTable);