import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import {Outlet} from "react-router-dom";
import {useTranslation} from 'react-i18next';

const MainLayout = () => {
    const {t, i18n} = useTranslation();
    document.body.dir = i18n.dir();

    return (
        <>
            <Navbar/>
            <Outlet/>
            <Footer/>
        </>
    );
};

export default MainLayout;
