export default () => (
  <svg
    width="576"
    height="199"
    viewBox="0 0 576 199"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_37_2780" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C4.47715 0 0 4.47716 0 10V189C0 194.523 4.47715 199 10 199H566C571.523 199 576 194.523 576 189V10C576 4.47716 571.523 0 566 0H444C434.059 0 426 8.05887 426 18V15C426 26.0457 417.046 35 406 35H169C157.954 35 149 26.0457 149 15V18C149 8.05887 140.941 0 131 0H10Z"
      />
    </mask>
    <path
      d="M-1 10C-1 3.92487 3.92487 -1 10 -1V1C5.02944 1 1 5.02945 1 10H-1ZM-1 189V10H1V189H-1ZM10 200C3.92486 200 -1 195.075 -1 189H1C1 193.971 5.02943 198 10 198V200ZM566 200H10V198H566V200ZM577 189C577 195.075 572.075 200 566 200V198C570.971 198 575 193.971 575 189H577ZM577 10V189H575V10H577ZM566 -1C572.075 -1 577 3.92487 577 10H575C575 5.02945 570.971 1 566 1V-1ZM444 -1H566V1H444V-1ZM425 18C425 7.50658 433.507 -1 444 -1V1C434.611 1 427 8.61116 427 18H425ZM427 15V18H425V15H427ZM406 34C416.493 34 425 25.4934 425 15H427C427 26.598 417.598 36 406 36V34ZM169 34H406V36H169V34ZM150 15C150 25.4934 158.507 34 169 34V36C157.402 36 148 26.598 148 15H150ZM148 18V15H150V18H148ZM131 -1C141.493 -1 150 7.50658 150 18H148C148 8.61116 140.389 1 131 1V-1ZM10 -1H131V1H10V-1Z"
      fill="url(#paint0_linear_37_2780)"
      mask="url(#path-1-inside-1_37_2780)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_37_2780"
        x1="288"
        y1="311"
        x2="288"
        y2="-9.26852e-06"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor="#F0F0F0" />
      </linearGradient>
    </defs>
  </svg>
);
