import React from "react";
import ButtonOfScrollTo from "../../assets/svg/ButtonOfScrollTo";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

const InfoHeader = (props) => {
    return (
        <>
            <div className="w-full relative bg-secondary-bg-color sm:px-[72px] pb-[40px]">
                <div className="w-full h-[1px] xl:bg-[#495156]"/>
                <div className="text-center mt-[10px] xl:mt-[40px]">
                    <div className="text-white text-[18px] sm:text-[22px] md:text-[32px] font-extrabold mb-3 xl:mb-0 section-heading " dangerouslySetInnerHTML={{__html: i18next.language == 'en' ? props.dataContent.title_en : props.dataContent.title_fa}}/>
                    <p className="text-[rgba(255,255,255,0.7)] text-[12px] md:text-sm font-medium leading-[30px] md:leading-[40px] mb-[30px]" dangerouslySetInnerHTML={{__html: i18next.language == 'en' ? props.dataContent.desc_en : props.dataContent.desc_fa}}/>
                </div>
            </div>
            <div className="w-full px-[25px] lg:px-0 text-center top-[-30px] relative">
                <div className="relative text-center">
                    <div className="mx-auto lg:w-[966px] text-center bg-white rounded-[20px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] p-[50px_40px_60px] sm:p-[50px_100px_60px] lg:p-[40px]">
                        <div className="leading-[30px] text-sm font-medium" dangerouslySetInnerHTML={{__html: i18next.language == 'en' ? props.dataContent.under_header_en : props.dataContent.under_header_fa}}/>
                    </div>
                    <div className="inline-block mt-[-28px]" onClick={() =>
                        window.scrollTo({
                            top: 550,
                            behavior: "smooth",
                        })
                    }>
                        <ButtonOfScrollTo/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default withTranslation()(InfoHeader);