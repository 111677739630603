import React from "react";
import Edit from "../../assets/svg/Edit";
import Calender from "../../assets/svg/Calender";
import Category from "../../assets/svg/Category";
import momentJalali from "jalali-moment";
import useTitle from "../../hooks/useTitle";
import {useTranslation} from "react-i18next";

const TitleInfo = (props) => {
    const {t} = useTranslation()
    useTitle(props.title)
    return (
        <div className="w-full lg:h-[248px] bg-secondary-bg-color px-[25px] md:px-[72px]">
            <div className="w-full h-[1px] bg-[#495156]"/>
            <h2 className="text-center text-white text-[22px] md:text-[32px] font-extrabold mt-[40px] mb-[30px] md:mb-[20px]">{props.title}</h2>
            <div className="text-center text-[#BFBFBF] text-sm font-medium mb-[30px] leading-[26px]">
                {t('Blog.صفحه اصلی', 'صفحه اصلی')} {">"} {t('Blog.آرشیو مطالب', 'آرشیو مطالب')} {">"} {props.categoryTitle} {">"}{" "} <span className="text-white text-opacity-20">{props.title}</span> <span className="text-white text-opacity-20">نمایش مطلب</span>
            </div>
            <div className="flex flex-col lg:flex-row justify-center items-center text-[#BFBFBF] gap-[24px] lg:gap-[71px] pb-[60px] lg:pb-0">
                {/*<div className="flex items-center gap-[5px]">*/} {/*  <Message />*/} {/*  <span>۵ دیدگاه</span>*/} {/*</div>*/}
                <div className="flex items-center gap-[5px]">
                    <Edit/> <span>{t('Blog.نویسنده', 'نویسنده')}: {props.author}</span>
                </div>
                <div className="flex items-center gap-[5px]">
                    <Calender/> <span>{t('Blog.تاریخ انتشار مطلب', 'تاریخ انتشار مطلب')}: {momentJalali(props.created_at).locale('fa').format('YYYY/MM/DD')}</span>
                </div>
                <div className="flex items-center gap-[5px]">
                    <Category/> <span>{t('Blog.دسته بندی', 'دسته بندی')}: {props.categoryTitle}</span>
                </div>
            </div>
        </div>
    );
};

export default TitleInfo;
