export default ({bgColor= "#1B262C", color="white"}) => (
  <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
    {
      color === "black" ?
        <circle cx="43.2588" cy="43.2588" r="42.2588" fill="white" stroke="#D1D1D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="13 13" />
        :
        <circle cx="42.2588" cy="42.2588" r="42.2588" fill={bgColor} />
    }
    <path fillRule="evenodd" clipRule="evenodd" d="M22.2435 42.9044L26.0728 46.7583L26.0759 51.9914L28.3586 53.9094L30.6412 51.9914L30.6443 46.7583L24.1789 40.2468L22.2422 38.3091L22.2415 29.529C22.7532 29.1053 28.3547 24.4639 28.3571 24.4656C28.3596 24.4639 33.9611 29.1053 34.4728 29.529L34.4721 40.5004L30.6429 36.6465L30.6398 31.4134L28.3571 29.4954L26.0745 31.4134L26.0714 36.6465L32.5368 43.1581L34.4735 45.0957L34.4743 53.8758C32.4339 55.5651 30.4002 57.2507 28.3596 58.9399L28.3585 58.9391L28.3574 58.9399C26.3168 57.2507 24.2831 55.565 22.2428 53.8758L22.2435 42.9044V42.9044Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M39.7665 41.7028L39.7684 51.9914L42.2588 53.9094L44.7492 51.9914L44.7526 46.76L41.8385 44.0704V38.62L48.9304 45.0955L48.9312 53.8757C46.7051 55.565 44.4863 57.2507 42.2599 58.9399L42.2587 58.9391L42.2576 58.9399C40.0312 57.2507 37.8123 55.5649 35.5863 53.8757L35.5867 41.7027L35.5863 29.5297C37.8123 27.8405 40.0312 26.1548 42.2576 24.4656C42.2648 24.4656 48.358 29.0949 48.9311 29.5297C48.9307 33.1937 48.9296 36.8562 48.9296 40.5188L44.7517 36.6648L44.7483 31.4317L42.2579 29.5136C41.4216 30.2322 40.6187 30.7595 39.7683 31.4141L39.7665 41.7027L39.7665 41.7028Z" fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M56.1596 53.9052L58.4358 51.9913L58.439 46.7599L55.9301 44.2317L55.7797 44.0875V44.0802L55.7699 44.0704V39.3352L56.1267 38.9758L56.1316 38.9807L58.4446 36.6498L58.4391 36.6473L58.4359 31.4141L56.1597 29.5002L53.8836 31.4141L53.8818 41.7027L53.8836 51.9912L56.1597 53.9051L56.1596 53.9052ZM58.8659 41.7066L62.2656 45.0955V45.0997L62.2754 45.0955L62.276 53.8757C60.2371 55.565 58.2048 57.2507 56.1656 58.9399L56.1645 58.9391L56.1634 58.9399L56.1596 58.9368L56.1558 58.9399L56.1547 58.9391L56.1537 58.9399L53.1403 56.4439L53.1417 56.4428L50.0432 53.8757L50.0435 41.7027L50.0432 29.5297C50.5681 29.0948 56.1492 24.4656 56.1558 24.4656C58.1603 26.1737 60.2444 27.8461 62.276 29.5297L62.2754 38.3099L58.8659 41.7066V41.7066Z" fill={color}/>
</svg>
)