const Terms = () => {
    return (
        <div dangerouslySetInnerHTML={{
            __html: `<p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">SGB GENERAL TERMS AND CONDITIONS</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">Contents</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; INTRODUCTORY PROVISIONS&nbsp;&nbsp; </span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Restrictions on License Grant&nbsp;&nbsp; </span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SERVICES AND THEIR ORDER&nbsp;&nbsp;&nbsp; </span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Your Obligations&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Eligibility&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Account Access&nbsp;</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; PAYMENT TERMS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Publication and Distribution of Content&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; RULES OF demo TRADING&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">10.&nbsp;&nbsp;&nbsp;&nbsp; SGB CHALLENGE AND VERIFICATION&nbsp;&nbsp;&nbsp; </span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">11&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; COMMUNICATION&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">12&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; RIGHT TO WITHDRAW FROM A CONTRACT&nbsp;&nbsp;&nbsp;&nbsp; </span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">14.&nbsp;&nbsp;&nbsp;&nbsp; CLIENT SECTION AND TRADING PLATFORM&nbsp;&nbsp;&nbsp;&nbsp; </span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">15&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Privacy&nbsp;&nbsp;&nbsp; </span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">16&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Intellectual Property Ownership&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">17&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Trademarks&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">18&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Refund Policy&nbsp;&nbsp;&nbsp;&nbsp; </span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">19&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Term; Termination; Suspension of Service&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">20&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Representations &amp; Warranties </span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">21&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Indemnification </span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">22&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Disclaimer of Warranties&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">23&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Limitation of Liability&nbsp;&nbsp;&nbsp;&nbsp; </span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">24&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Notice&nbsp;&nbsp;&nbsp;&nbsp; </span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">25&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Modification to Terms&nbsp; </span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">26&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Assignment; Change in Control&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">27&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Customer Communications; Disclosure&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">28&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Trade Risk Disclaimer&nbsp;&nbsp;&nbsp; </span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">29&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; DEFINITIONS, EXPRESSIONS AND ABBREVIATIONS USED&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;"><br /></p>
        <p style="text-align: left;"><br /></p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">Summery</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            This Terms of Use Agreement (this “Agreement”) is a legal agreement between you (“you” or “your”) and Sarmaye Gozar Bartar Investment(“SGB”) governing your use of (i) services developed, operated, or maintained by SGB,
                            or accessible via www.SGBtrading.com or any other web site or IP address designated by SGB (the “Site”), (ii) the Content (as defined below) contained therein, (iii) any mobile applications offered by SGB, and (iv) any
                            offline components provided by SGB for use in connection therewith (collectively, the “Service”).
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            By using the service, or downloading, installing, activating, or using any content available through the site, you agree to comply with and be beyond by, the terms of this agreement in their entirety without limitation
                            or qualification and all applicable laws and regulations. Please read these GTC carefully. You are under no obligation to use the Services if you do not agree or understand any portion of these Terms, nor should you use
                            the Services unless you understand and agree to these Terms.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">INTRODUCTORY PROVISIONS </span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            The Services consist of the provision of tools for simulated foreign exchange trading on the FOREX market or simulated trading with other instruments on other financial markets, provision of analytical tools, training
                            and educational materials, the access to the Client Section, and other ancillary services, in particular through the Client Section or by the provision of access to applications provided by the Provider or third parties.
                            Financial market information is used in the simulated trading; however, you acknowledge that any trading that you perform through the Services is not real. You also acknowledge that the funds provided to you for demo
                            trading are fictitious and that you have no right to possess those fictitious funds beyond the scope of their use within the Services, and in particular that they may not be used for any actual trading and that you are
                            not entitled to the payment of those funds. Unless expressly agreed otherwise, you will not be paid any remuneration or profits based on the results of your simulated trading, nor will you be required to pay any losses.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            NONE OF THE SERVICES PROVIDED TO YOU BY THE PROVIDER CAN BE CONSIDERED INVESTMENT SERVICES IN ACCORDANCE WITH APPLICABLE LAWS. THE PROVIDER DOES NOT GIVE OR PROVIDE TO YOU ANY GUIDANCE, INSTRUCTIONS, OR INFORMATION ABOUT
                            HOW OR IN WHICH MANNER YOU SHOULD PERFORM TRANSACTIONS WHEN USING THE SERVICES OR OTHERWISE, OR ANY OTHER SIMILAR INFORMATION ABOUT THE INVESTMENT TOOLS TRADED, NOR DOES THE PROVIDER ACCEPT ANY SUCH GUIDANCE,
                            INSTRUCTIONS, OR INFORMATION FROM YOU. NONE OF THE SERVICES CONSTITUTE INVESTMENT ADVICE OR RECOMMENDATIONS. NO EMPLOYEES, STAFF, OR REPRESENTATIVES OF THE PROVIDER ARE AUTHORIZED TO PROVIDE INVESTMENT ADVICE OR
                            RECOMMENDATIONS. SHOULD ANY INFORMATION OR STATEMENT OF ANY EMPLOYEE, STAFF, OR REPRESENTATIVES OF THE PROVIDER BE INTERPRETED AS INVESTMENT ADVICE OR RECOMMENDATIONS, THE PROVIDER EXPLICITLY DISCLAIMS THAT THE SAME IS
                            INVESTMENT ADVICE OR RECOMMENDATIONS AND SHALL NOT BE RESPONSIBLE FOR THEM.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">Restrictions on License Grant</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            Your use of the Service is limited to the scope of the license granted in this Agreement and this Agreement does not permit you to use the Service other than as provided herein. You acknowledge that the Service
                            constitutes valuable trade secrets and/or the confidential information of SGB or its licensors. You acknowledge and agree that except as otherwise authorized under this Agreement or otherwise specified in writing between
                            the parties:
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You shall not license, sublicense, sell, resell, rent, lease, transfer, assign, distribute, grant a security interest in, or otherwise transfer any rights to, or commercially exploit, the Service or any information,
                            documents, software, products and services contained or made available to you in the course of using the Service (the “Content”) or use the Service to run or as part of a service bureau, outsourced, or managed services
                            arrangement;
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">You shall not republish, upload, post, transmit or distribute the Service or the Content in any way;</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You shall not modify, translate, alter, adapt, decompile, disassemble (except to the extent applicable laws specifically prohibit such restriction), reproduce, distribute or display, or create derivative works,
                            compilations or collective works based on the Service or the Content;
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You shall not knowingly or negligently permit other individuals or entities to use or copy the Service, or create Internet “links” to the Service or “frame” or “mirror” the Service on any other server or wireless or
                            Internet-based device;
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You shall not access the Service to (i) build a competitive product or service, (ii) build a product using similar ideas, features, functions or graphics of the Service, (iii) copy any ideas, features, functions or
                            graphics of the Service, (iv) monitor its availability, performance or functionality, or (iiv) for any other benchmarking or competitive purposes;
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">You shall not access the Service if you sell or provide any service, software, or product that may compete with SGB’ services, software, or products (a “Competitor”);</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You shall not attempt to use or gain unauthorized access to data, accounts, hosts, systems or networks of SGB or any of its customers or suppliers, or those of any other party; breach the security of another user or
                            system, or attempt to circumvent the user authentication or security of any host, network, or account, including, without limitation, accessing data not intended for you or logging into or making use of a server or
                            account you are not expressly authorized to access;
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You shall not attempt to probe, scan or test the vulnerability of a system, account or network of SGB or any of its customers or suppliers, any SGB product or service, or those of any other party;
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You shall not interfere, or any attempt to interfere, with service to any user, host or network including, without limitation, mail-bombing, flooding, and attempting to deliberately overload the system or distribute
                            programs that “crack,” or make unauthorized changes to, the software;
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You shall not forge any TCP-IP packet header or any part of any header information, falsify, alter or remove address information or other modification of e-mail headers; collect responses from unsolicited bulk messages,
                            falsify references to SGB or the Service, by name or other identifier, in messages; impersonate any person or entity, engage in sender address falsification, forge anyone else’s digital or manual signature, or perform
                            any other similar fraudulent activity;
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You shall not restrict, inhibit, or otherwise interfere with the ability of any other person, regardless of intent, purpose or knowledge, to use or enjoy the Service (except for tools for safety and security functions),
                            including, without limitation, posting or transmitting any information or software which contains a worm, virus, or other harmful feature, or generating levels of traffic sufficient to impede others’ ability to use,
                            send, or retrieve information;
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You shall not restrict, inhibit, interfere with, or otherwise disrupt or cause a performance degradation, regardless of intent, purpose or knowledge, to the Service or any SGB (or SGB supplier) host, server, backbone
                            network, node or service, or otherwise cause a performance degradation to any SGB (or SGB supplier) facilities used to deliver the Service;
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You shall not create or use any program, tags, markers, bots, mousetraps, highjackers or other similar computer routines or sub-routines to automatically access or manipulate the Service; and
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You shall not knowingly use the Service to: (i) send spam or otherwise duplicative or unsolicited messages in violation of applicable laws; or (ii) send or store infringing, obscene, threatening, libelous, or otherwise
                            unlawful or tortious material, including material harmful to children or violative of third party privacy rights.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            Because of the difficulty associated with quantifying damages, in addition to any other damages to which SGB may be entitled, if actual damages cannot be reasonably calculated then you agree to pay SGB liquidated damages
                            of $250 for each violation of this Section 2 or the maximum liquidated damages permitted under law, whichever is greater; otherwise you agree to pay SGB’ actual damages, to the extent such actual damages can be
                            reasonably calculated.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">SERVICES AND THEIR ORDER</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You can order the Services through the Website by completing the appropriate registration or order form. After registration, we will e-mail you the login details for the Client Section and/or Trading Platform and allow
                            you to access them.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            &nbsp;All data that you provide to us through the registration or order form, the Client Section, or otherwise must be complete, true, and up to date. You must immediately notify us of any change in your data or update
                            the data in your Client Section. The Customer is responsible for all the provided data being accurate and up to date; the Provider is not obligated to verify the data.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You acknowledge that if you provide an identification number, tax registration number or other similar information in the registration or order form or in the Client Section, or if you state that you are a legal entity,
                            you will be considered as an entrepreneur (trader) for the purposes of these GTC and when using the Services, and the provisions of these GTC or the applicable law that grant rights to consumers will not apply to you.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            The fee for the SGB Challenge varies according to the option selected and depends on the amount of the initial capital, the degree of the acceptable risk, the parameters that must be fulfilled so that the conditions of
                            the SGB Challenge and the subsequent Verification are met, and possibly other configurations. More detailed information on individual options and fees for those options are provided on our website here. The final fee
                            will be determined based on the option you select when completing the form for ordering the SGB Challenge. The Provider reserves the right to also provide the Services under individually agreed conditions. All
                            individually agreed conditions shall be determined by the Provider at its own discretion. Individual discounts and other benefits may not be combined, unless expressly stipulated otherwise by the Provider.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            The fee is paid for allowing you to access the SGB Challenge, or the Services provided under the SGB Challenge. The Customer is not entitled to a refund of the fee, for example, if the Customer cancels the Customer’s
                            Client Section or requests the cancellation by e-mail, if the Customer terminates the use of the Services prematurely (for example, fails to complete the SGB Challenge or the Verification), fails to meet the conditions
                            of the SGB Challenge or the Verification, or violates these GTC.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            If the Customer lodges an unjustifiable complaint regarding the paid fee or disputes the paid fee with the Customer’s bank or payment service provider (e.g. through chargeback services, dispute services, or other similar
                            services), on the basis of which an annulment, cancellation or refund of the fee or any part thereof is requested, the Provider is entitled, at its own discretion, to stop providing to the Customer any services and
                            refuse any future provision of any services.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            Your choice of the option of the SGB Challenge that you select when making an order shall also apply to the subsequent Verification. You will start the subsequent Verification and, possibly, other products related
                            thereto, with the parameters and the same currency that correspond to the option of the SGB Challenge selected by you. Once you make a selection, it is not possible to change it.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            the Provider reserves the right to unilaterally change the fees and parameters of the Services at any time, including the parameters for their successful completion. The change does not affect the Services purchased
                            before the change is notified.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            Any data entered in the order form can be checked, corrected, and amended until the binding order of the Services. The order of the Services of your choice is made by submitting the order form. In the case of the SGB
                            Challenge, the order is completed upon the payment of the fee for the selected option (more on this in clause 7.3), whereby the contract between you and the Provider is executed, the subject of which is the provision of
                            the SGB Challenge and, if the conditions of the SGB Challenge are met, the Verification. The contract is concluded in English. We archive the contract in electronic form and do not allow access to it.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You acknowledge that the operators of trading platforms are persons or entities different from the Provider and that their own terms and conditions and privacy policies will apply when you use their services and
                            products. Before sending an order form, you are obligated to read those terms and conditions and privacy policies.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            If the Customer places an unusually large number of orders for the Services within an unreasonably short period of time, the Provider may notify the Customer through the Client Section as a protective precaution to
                            mitigate potentially harmful behavior of the Customer. If such unreasonable behavior continuous after such notice, we reserve the right to suspend any further orders of the Services by the Customer. If we identify that
                            the unusual behavior as per this paragraph relates to the Customer’s involvement in Forbidden Trading Practices, we may take respective actions as perceived in Section 5 of this GTC. The Provider reserves the right to
                            determine, at its own discretion, the nature of the behavior described above and reasonable boundaries for such determination.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">Your Obligations</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You shall abide by all applicable local, state, national and foreign laws, treaties and regulations in connection with your use of the Service, including those related to data privacy, international communications and
                            the transmission of technical or personal data. You are also solely and exclusively responsible for providing and maintaining all computer hardware, telephone and other equipment necessary to operate the licensed Service
                            and the internet service required to access the Service over the Internet. SGB shall have no responsibility for the procurement, operation or compatibility of your hardware or capabilities of your internet service
                            provider.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">Eligibility</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You represent and warrant that you are at least 18 years of age. In jurisdictions, territories and locations where the minimum age for permissible use of the Sites or Services is greater than 18 years of age, you
                            represent and warrant that you meet the age requirement for the minimum age for permissible use of the Sites or Services. If you are under the minimum age for permissible use of the Sites or Services in your
                            jurisdiction, territory or location, you may not utilize the Sites or Services. You acknowledge that your access to and use of the Services may be restricted or prohibited by law in some countries, and you undertake to
                            only access and use the Services in accordance with applicable laws.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You represent and warrant that you (i) have not a criminal record related to financial crime or terrorism, (ii) have not been convicted of a felony, disciplined by the National Futures Association or disciplined by any
                            Trading Commission, (iii) do not have an outstanding balance with a trading firm. The Provider reserves the right to refuse, restrict or terminate the provision of any Services to Customer as per this Clause 1.2. and
                            such Customer is prohibited to use the Services, which also includes the use of the Client Section and/or Trading Platform.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You are solely responsible for ensuring that these Terms are in compliance with all laws, rules and regulations applicable to you. The right to access the Sites or Services is revoked where these Terms or use of the
                            Sites or Services are prohibited or to the extent offering, sale or provision of the Sites or Services conflicts with any applicable law, rule or regulation. The Sites or Services are offered only for your use, and not
                            for the use or benefit of any third party.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">Account Access</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            Where use of the Service is contingent on accessing an “account” and/or inserting a “user-identification” and/or “password”, you agree that you will be solely responsible for the user-ids and passwords that are provided
                            to you (as such passwords may be changed from time to time in accordance with features of the Service) to log-in to the password protected Service. If non-authorized individuals have access to your systems or to your
                            users’ user-id and password, they may be able to use the Service. You and your users shall keep any correspondence you receive relating to or through the use of the Service (including, but not limited to, your user-id,
                            passwords, and other registration or sign-in information) confidential and in a safe place and not disclose it to any third party. You will be responsible and liable for all communications and actions that take place
                            through the use of your user-ids, including without limitation, any actions that occur without your authorization. Accordingly, it is your responsibility to take appropriate actions immediately if any password has been
                            stolen, leaked, compromised or otherwise used without proper consent. You shall: (i) notify SGB immediately of any unauthorized use of any password or account or any other known or suspected breach of security; (ii)
                            report to SGB immediately and use reasonable efforts to stop immediately any copying or distribution of Content that is known or suspected by you; (iii) notify SGB when you no longer require access to the Service; and
                            (v) keep all of your profile information current.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">PAYMENT TERMS</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            Service charges are inclusive of all taxes. If the Customer is an entrepreneur (trader), he is obliged to fulfil all his tax obligations in connection with the use of our Services in accordance with applicable law, and
                            in the event of an obligation, he is obliged to pay tax or other fees properly.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You can pay the fee for the selected option of the SGB Challenge by a payment card, via a bank transfer, or using other means of payment that the Provider currently offers on the Website.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            In the event of payment by a payment card or via any other express payment method, the payment shall be made immediately. If you select a bank transfer for payment, we will subsequently send you a proforma invoice in
                            electronic form with the amount of the fee for the option of the SGB Challenge you have chosen on the Website. You undertake to pay the amount within the period specified in the proforma invoice. The fee is considered
                            paid when its full amount is credited to the Provider’s account. If you do not pay the amount on time, the Provider is entitled to cancel your order. Customer bears all fees charged to Customer by the selected payment
                            service provider (according to the valid pricelist of the payment service provider) in connection with the transaction and the Customer is obliged to ensure that the respective fee for the selected SGB Challenge is paid
                            in full.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">Publication and Distribution of Content</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">SGB cannot and does not guarantee the accuracy, integrity, quality, or appropriateness of any Content transmitted to or through the Site and Service. </span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You acknowledge that SGB acts only as a passive conduit and an interactive computer service provider for the publication and distribution of user-generated content. You acknowledge that all Content posted on, transmitted
                            through or linked through the Service, are the sole responsibility of the person from whom such Content originated. You understand that SGB does not control and is not responsible for Content made available through the
                            Site and Service, and that by using the Site and Service, you may be exposed to Content that is inaccurate, misleading, or offensive.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You acknowledge and agree that you must evaluate and make your own judgment, and bear all risks associated with, the use of any Content. You further acknowledge that SGB has no obligation to screen, preview, monitor or
                            approve any user-generated Content on the Sites and Services. However, SGB reserves the right to review, modify and/or delete any Content that, in its sole judgment, violates the terms of this Agreement.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            By using the Site and Service, you agree that it is solely your responsibility to evaluate the risk associated with the use, accuracy, usefulness, completeness, or appropriateness of any Content that you submit, receive,
                            access, transmit or otherwise convey through the Site and Service. Under no circumstances will SGB be liable in any way for any Content, including, but not limited to, any Content that contains any errors, omissions,
                            defamatory statements, or confidential or private information or for any loss or damage of any kind incurred as a result of the use of any Content submitted, accessed, transmitted or otherwise conveyed through the Site
                            or Service.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You waive the right to bring or assert any claim against SGB relating to Content, and release SGB from any and all liability for or relating to any Content. If you encounter Content that you believe violates the terms of
                            this Agreement or is otherwise unlawful, you may send an email to support@sgbtrading.com.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">RULES OF demo TRADING</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            During the demo trading on the Trading Platform, you may perform any transactions, unless these constitute forbidden trading strategies or practices within the meaning of clause 9.4. You also agree to follow good market
                            standard rules and practices for trading on financial markets (e.g., risk management rules). Restrictions may also be imposed by the trading conditions of the Trading Platform that you have selected for trading.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You acknowledge that the Provider has access to information about the demo trades that you perform on the Trading Platform. You grant the Provider your consent to share this information with persons/entities who are in a
                            group with the Provider or who are otherwise affiliated with the Provider, and you grant the Provider and these persons/entities your consent and authorization to handle this information at their own will. You agree that
                            these activities may be performed automatically without any further consent, consultation, or approval on your part being necessary, and that you are not entitled to any remuneration or revenue associated with the use of
                            the data by the Provider. The Provider is aware that you do not provide the Provider with any investment advice or recommendations through your demo trading. You acknowledge that you may suspend your demo trading on the
                            Trading Platform at any time.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            &nbsp;The provider bears no responsibility for the information displayed on the Trading Platform, nor for any interruption of, or delay or inaccuracy in the market information displayed through your Client Section.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">FORBIDDEN TRADING PRACTICES.</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">DURING THE DEMO TRADING, IT IS PROHIBITED TO:</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">KNOWINGLY OR UNKNOWINGLY USE TRADING STRATEGIES THAT EXPLOIT ERRORS IN THE SERVICES SUCH AS ERRORS IN DISPLAY OF PRICES OR DELAY IN THEIR UPDATE.</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">PERFORM TRADES USING AN EXTERNAL OR SLOW DATA FEED.</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            PERFORM, ALONE OR IN CONCERT WITH ANY OTHER PERSONS, INCLUDING BETWEEN CONNECTED ACCOUNTS, OR ACCOUNTS HELD WITH DIFFERENT SGB ENTITIES, TRADES OR COMBINATIONS OF TRADES THE PURPOSE OF WHICH IS TO MANIPULATE TRADING, FOR
                            EXAMPLE BY SIMULTANEOUSLY ENTERING INTO OPPOSITE POSITIONS.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">PERFORM TRADES IN CONTRADICTION WITH THE TERMS AND CONDITIONS OF THE PROVIDER AND THE TRADING PLATFORM.</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">USE ANY SOFTWARE, ARTIFICIAL INTELLIGENCE, ULTRA-HIGH SPEED, OR MASS DATA ENTRY WHICH MIGHT MANIPULATE, ABUSE, OR GIVE YOU AN UNFAIR ADVANTAGE WHEN USING OUR SYSTEMS OR SERVICES.</span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">PERFORM GAP TRADING BY OPENING TRADE(S):</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            WHEN MAJOR GLOBAL NEWS, MACROECONOMIC EVENT OR CORPORATE REPORTS OR EARNINGS (“EVENTS”), THAT MIGHT AFFECT THE RELEVANT FINANCIAL MARKET (I.E. MARKET THAT ALLOWS TRADING OF FINANCIAL INSTRUMENTS THAT MIGHT BE AFFECTED BY
                            THE EVENTS), ARE SCHEDULED; AND
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">2 HOURS OR LESS BEFORE A RELEVANT FINANCIAL MARKET IS CLOSED FOR 2 HOURS OR LONGER.</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            PERFORM TRADES IN CONTRADICTION WITH HOW TRADING IS ACTUALLY PERFORMED IN THE FOREX MARKET OR IN ANY OTHER FINANCIAL MARKET, OR IN A WAY THAT ESTABLISHES JUSTIFIED CONCERNS THAT THE PROVIDER MIGHT SUFFER FINANCIAL OR
                            OTHER HARM AS A RESULT OF THE CUSTOMER’S ACTIVITIES (E.G. OVERLEVERAGING, OVEREXPOSURE, ONE-SIDED BETS, ACCOUNT ROLLING).
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            As our customer, you should understand and agree that all our Services are for Customer’s personal use only, meaning that only you personally can access your SGB Challenge and Verification accounts and perform trades.
                            For that reason, you should not, and you agree not to,
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            allow access to and trading on your SGB Challenge and Verification accounts by any third party nor you shall engage or cooperate with any third party in order to have such third party perform trades for you, whether such
                            third party is a private person or a professional.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            access any third-party SGB Challenge and Verification accounts, trade on behalf of any third party or perform any account management or similar services, where you agree to trade, operate or manage the SGB Challenge and
                            Verification accounts on behalf of another user, all whether performed as a professional or otherwise.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            9.4.2.2.1&nbsp;&nbsp;&nbsp; Please note that if you act or behave in contradiction with the aforesaid, we will consider such action/behavior as a Forbidden Trading Practice under Section 9.4. with respective consequences
                            as perceived under this GTC.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            Furthermore, Customer shall not exploit the Services by performing trades without applying market standard risk management rules for trading on financial markets, this includes, among others, the following practices (i)
                            opening substantially larger position sizes compared to Customer’s other trades, whether on this or any other Customer’s account, or (ii) opening substantially smaller or larger number of positions compared to Customer’s
                            other trades, whether on this or any other Customer’s account. The Provider reserves the right to determine, at its own discretion, whether certain trades, practices, strategies, or situations are Forbidden Trading
                            Practices.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">If the Customer engages in any of the Forbidden Trading Practices described in clause 9.4,</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            (i) the Provider may consider it as a failure to meet the conditions of the particular SGB Challenge or Verification, (ii) the Provider may remove the transactions that violate the prohibition from the Customer’s trading
                            history and/or not count their results in the profits and/or losses achieved by the demo trading, (iii) to immediately cancel all Services provided to the Customer and subsequently terminate this Agreement, or (iv)
                            reduce the offered leverage on products to 1:10 on any or all Customer’s accounts.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            In case when some or all Forbidden Trading Practices are executed on one or more SGB Challenge and Verification accounts of one Customer, or accounts of various Customers, or by combining trading through SGB Challenge
                            and Verification accounts and SGB Trader accounts, then the Provider is entitled to cancel all Services and terminate all respective contracts related to any and all Customer’s SGB Challenge and Verification accounts
                            and/or apply other measures in Clause 9.5. The Provider may exercise any and all actions in Clauses 9.5 and 9.6 at its own discretion.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            If any SGB Trader accounts were used for or were involved in the Forbidden Trading Practices, this may and will constitute a breach of respective terms and conditions for SGB Trader account with third-party provider and
                            may result in cancellation of all such user accounts and termination of respective agreements by the third-party provider.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            If the Customer engages in any of the practices described in clause 9.4 repeatedly, and the Provider has previously notified the Customer thereof, the Provider may prevent the Customer from accessing all Services or
                            their parts, including access to the Client Section and Trading Platform, without any compensation. In such a case, the Customer is not entitled to a refund of the fees paid.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            The Provider does not bear any responsibility for trading or other investment activities performed by the Customer outside the relationship with the Provider, for example by using data or other information from the
                            Client Section, Trading Platform, or otherwise related to the Services in real trading on financial markets, not even if the Customer uses for such trading the same Trading Platform that the Customer uses for demo
                            trading.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            DEVELOPMENTS IN FINANCIAL MARKETS ARE SUBJECT TO FREQUENT AND ABRUPT CHANGES. TRADING ON FINANCIAL MARKETS MAY NOT BE PROFITABLE AND CAN LEAD TO SIGNIFICANT FINANCIAL LOSSES. ANY PREVIOUS PERFORMANCES AND PROFITS OF THE
                            CUSTOMER’S DEMO TRADING ARE NOT A GUARANTEE OR INDICATION OF ANY FURTHER PERFORMANCE.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">SGB CHALLENGE AND VERIFICATION</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            After paying the fee for the selected option of the SGB Challenge, the Customer will receive the relevant login data for the Trading Platform at the e-mail address provided by the Customer or in the Client Section. The
                            Customer activates the SGB Challenge by opening the first demo trade in the Trading Platform. IF YOU ARE A CONSUMER, YOU ACKNOWLEDGE THAT, BY OPENING THE FIRST DEMO TRADE, YOU EXPRESSLY DEMAND THE PROVIDER TO COMPLETE
                            THE SERVICES BEFORE THE EXPIRY OF THE PERIOD FOR WITHDRAWAL FROM THE CONTRACT, WHICH AFFECTS YOUR RIGHT TO WITHDRAW FROM THE CONTRACT, AS SPECIFIED IN MORE DETAIL IN CLAUSE 12. If you do not activate the SGB Challenge
                            within 30 calendar days of the date on which it was made available to you, your access to it will be suspended. You can request the renewal of access via the Client Section or by sending an e-mail to
                            support@SGBtrading.com within 6 months of the initial suspension, otherwise we will terminate the provision of the Services without any right to a refund of the fee. The SGB Challenge lasts for 30 calendar days from the
                            date of its activation.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">In order for the Customer to meet the conditions of the SGB Challenge, the Customer must fulfil all of the following parameters at the same time by the end of the SGB Challenge:</span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">&nbsp;During the SGB Challenge, the Customer has opened at least one demo trade on at least 5 different calendar days;</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            in the course of none of the calendar days during the SGB Challenge did the Customer report a loss on any demo trades opened and closed on that day, which would exceed the 5 percent of the balance at the beginning of the
                            day.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">at no time during the SGB Challenge did the Customer report a loss on any opened and closed demo transactions, which would exceed in total 12 percent of the initial capital.</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">during the SGB Challenge, the Customer reported a total profit on all closed demo trades amounting to at least 8 percent of the initial capital.</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">The above parameters are explained in more detail here on the Website.</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            If the Customer has met the conditions of the SGB Challenge specified in clause 10.2, and at the same time has not violated these GTC, in particular the rules of demo trading under clause 9.4, the Provider will evaluate
                            the SGB Challenge as successful and will make the Verification available to the Customer free of charge by sending login details to the Customer’s e-mail address or Client Section. The Customer may request the evaluation
                            of the SGB Challenge at any time by sending a request to support@SGB.com or via the Client Section. The Provider does not have to evaluate the SGB Challenge if the Customer has not closed all trades.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            The Customer activates the Verification by opening the first demo trade in the Trading Platform. If the Customer does not activate the Verification within 30 calendar days from the day on which the Customer received the
                            new login data, the Customer’s access to the Verification will be suspended. The Customer may request the renewal of access via the Client Section or by sending an e-mail to support@SGB.com within 6 months of the
                            suspension, otherwise we will terminate the provision of the Services without any right to a refund. The Verification lasts for 60 calendar days from the date of its activation.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">In order for the Customer to meet the conditions of the Verification, the Customer must fulfil all of the following parameters at the same time by the end of the Verification.</span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">During the Verification, the Customer has opened at least one demo trade on at least 5 different calendar days.</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            in the course of none of the calendar days during the Verification did the Customer report a loss on any demo trades opened and closed on that day, which would exceed 5 percent of the initial capital.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">at no time during the Verification did the Customer report a loss on the sum of the opened and closed demo trades, which would exceed in total 12 percent of the initial capital.</span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">at the end of the Verification, the Customer reported a closed profit from all performed demo trades amounting to at least 4 percent of the initial capital.</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">The above parameters are explained in more detail here on the Website.</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">For the Customer to meet conditions of the Verification, the Customer shall comply with the following:</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">Customer has met the conditions of the Verification specified in clause 10.5;</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">Customer has not violated these GTC, in particular, the rules of demo trading under clause and 9.4.</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            Customer has not exceeded the maximum total amount of the capital allocation of USD 250,000, individually or in combination, per Customer or per each trading strategy, within the meaning of applicable SGB Trader Program
                            agreement, if Customer is already participating in the SGB Trader Program. If the above conditions are met, the Provider will evaluate the Verification as successful and will recommend the Customer as a candidate for SGB
                            Trader program. The Customer may request the evaluation of the Verification at any time by sending a request to support@SGB.com or via the Client Section. The Provider does not have to evaluate the Verification if the
                            Customer has not closed all transactions.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            If during the SGB Challenge the Customer does not comply with some of the conditions specified in clause 10.2, the SGB Challenge will be evaluated as unsuccessful, and the Customer will not be allowed access to the
                            subsequent Verification. If during the Verification the Customer does not comply with any of the conditions specified in clause 10.5, the Verification will be evaluated as unsuccessful, and the Customer will not be
                            recommended as a candidate for the SGB Trader program. In such cases, the Customer’s account and Services will be cancelled without refund of fees already paid.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            Provider recommending Customer as a candidate for the SGB Trader Program in no way guarantees Customer’s acceptance into the SGB Trader Program. The Provider is not responsible for Customer being rejected by the SGB
                            Trader Program for any or no reason.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">COMMUNICATION</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You acknowledge that all communication from the Provider or its partners in connection with the provision of Services will take place through the Client Section or your e-mail address, which you register with us. Written
                            electronic communication by e-mail or through the Client Section is also considered to be written communication.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">our contact e-mail address is support@SGB.com and our contact address is Flexi Desk, DUQE SQUARE BUSINESS CENTER, QUARTER DECK,MINA RASHID, QE2.</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;"><br /></p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">RIGHT TO WITHDRAW FROM A CONTRACT</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            If you are a consumer, you have the right to withdraw from a contract without giving a reason within 14 days of its execution (see clause 3.9 for details on the time of execution of the agreement). PLEASE NOTE THAT IF
                            YOU START PERFORMING DEMO TRADES BEFORE THE EXPIRY OF THE SPECIFIED TIME LIMIT, YOU LOSE YOUR RIGHT TO WITHDRAW FROM THE CONTRACT.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            Your withdrawal from the contract must be sent to our e-mail address support@SGB.com within the specified time limit. You can use the template form available here to withdraw. We will confirm the receipt of the form to
                            you in text form without undue delay. If you withdraw from the contract, we will refund you without undue delay (no later than 14 days after your withdrawal from the contract) all fees we have received from you, in the
                            same way in which you paid them.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">The Provider is entitled to immediately withdraw from the Contract in the case of any breach by the Customer.</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">DEFECTIVE PERFORMANCES</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            If the Services do not correspond to what was agreed or have not been provided to you, you can exercise your rights from defective performance. The Provider does not provide any guarantee for the quality of the services.
                            You must notify us of the defect without undue delay at our e-mail address or at our address listed in clause 11.2. When exercising the rights from defective performance, you may request that we remedy the defect or
                            provide you with a reasonable discount. If the defect cannot be remedied, you can withdraw from the contract or claim a reasonable discount.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            We will try to resolve any complaint you may lodge as soon as possible (no later than within 30 calendar days), and we will confirm its receipt and settlement to you in writing. If we do not settle the complaint in time,
                            you have the right to withdraw from the contract. You can file a complaint by sending an e-mail to our e-mail address support@SGB.com.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">CLIENT SECTION AND TRADING PLATFORM</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">Only one Client Section is permitted per Customer and all of the Customer’s Services must be maintained in the Client Section.</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            The total number of SGB Challenges and Verifications per one Client Section may be limited depending on the total sum of the initial capital amounts of the products ordered by the Customer or on the basis of other
                            parameters. Unless the Provider grants an exception to the Customer, the initial capital amounts may not be transferred between the individual products or mutually combined. You may also not transfer or combine your
                            performance, Service parameters, data, or any other information between the products.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            The Customer acknowledges that the Services may not be available around the clock, particularly with respect to maintenance, upgrades, or any other reasons. In particular, the Provider bears no responsibility, and the
                            Customer is not entitled to any compensation, for the unavailability of the Client Section or Trading Platform and for damage or loss of any data or other content that Customer uploads, transfers or saves through the
                            Client Section or Trading Platform.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            The Customer may at any time request the cancellation of the Client Section by sending an e-mail to support@SGB.com. Sending a request for the cancellation of the Client Section is considered as a request for termination
                            of the contract by the Customer, with the Customer being no longer entitled to use the Services, including the Client Section and Trading Platform. The Provider will immediately confirm the receipt of the request to the
                            Customer by e-mail, whereby the contractual relationship between the Customer and the Provider will be terminated. In such a case, the Customer is not entitled to any refund of the fees already paid or costs otherwise
                            incurred.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">Privacy</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            SGB may collect certain personal information from you in order to provide services and to complete transactions. In such cases, SGB will ask you for personally identifiable information (“PII”), which information about
                            you that can be used to contact or identify you, such as your name, phone number, email address, postal address, username, password, zip code, age and income.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            SGB uses the information you provide to manage and improve its Site and Service, including, for example, to deliver advertising of interest to you, to assist with social sharing functionality, to develop new products and
                            services, to contact you regarding our products or services, and to detect and prevent security threats, fraud or other malicious activity. SGB may share your Identify Information with its affiliates or with third
                            parties which provide services to SGB and which agree to keep such PII confidential.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            SGB retains the right to disclose PII as allowed or required by law, as well as under the following circumstances: (i) to respond to duly authorized information requests of police and governmental authorities; (ii) to
                            comply with any law, regulation, subpoena, or court order; (iii) to investigate and help prevent security threats, fraud or other malicious activity, (v) to investigate and help prevent a violation of any contractual or
                            other relationship with SGB or the perpetration of any illegal or harmful activity; (iv) to enforce and protect the rights and properties of SGB, its affiliates and its business partners; or (iiv) to protect the personal
                            safety of SGB’ employees, agents, affiliates, business partners, customers or site users. In connection with or in contemplation of a merger, consolidation, or sale or transfer of all or substantially all of the assets
                            of SGB, your PII may be transferred to the new owner or the successor entity.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            &nbsp;In addition to the foregoing, SGB may collect certain data relating to your use of the Site or the Service. You agree that SGB has an unrestricted (except as set forth herein), royalty free right to use statistical
                            and other aggregated data derived from your use of Site or the Service (the “Aggregated Data”) for the provision, development and improvement of products and services to SGB’ customers. The Aggregated Data does not
                            include (directly or by inference) any information identifying you or any identifiable individual and may only be used by SGB if it is combined with similar aggregated data from SGB’s other customers. SGB acknowledges
                            and agrees that the Aggregated Data is provided AS IS and SGB uses the same at its own risk. Notwithstanding anything to the contrary, you assume no risk and shall have no liability and no responsibility with respect to
                            the Aggregated Data.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            SGB may record information that your browser sends whenever you visit the Site or use the Service (“Log Data”). This Log Data may include information such as your Internet Service Provider, your computer’s Internet
                            Protocol address, browser type, and operating system, referring/exit pages, clickstream data, and other data. SGB may use this information for its own internal purposes, including improving the functionality and
                            user-friendliness of SGB’ Site and Service.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            SGB uses standard Internet technologies such as “cookies “and “web beacons” in order to retrace your interaction with our sites and services. “Cookies” are small files that are placed on your hard drive for
                            identification purposes. These files are used for site registration and customization the next time you visit us. Your web browser may allow you to be notified when you are receiving a cookie, giving you the choice to
                            accept it or not. You have the ability to prevent cookies from being saved on your computer by making the appropriate adjustments in your browser options. By not accepting cookies, some pages may not fully function and
                            you may not be able to access certain information on this site.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">Intellectual Property Ownership</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            All right, title and interest in the Service and the Site, including technology and trade secrets embodied therein and any Content or developments created or provided in connection with or related to the Service and the
                            Site, including all copyrights, patents, trade secrets, trade dress and other proprietary rights, and any derivative works thereof, shall belong solely and exclusively to SGB or its licensors, and you shall have no
                            rights whatsoever in any of the foregoing.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You acknowledge that the Service constitutes a valuable trade secret and/or is the confidential information of SGB or its licensors. Nothing in this Agreement or otherwise will be deemed to grant to you an ownership
                            interest in the Service, in whole or in part.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            All Content and materials included as part of the Service, such as text, graphics, logos, button icons, images, audio clips, information, data, forms, photographs, graphs, videos, typefaces, graphics, music, sounds, and
                            other material, and software (the “Works”) are the property of SGB or its content suppliers and is protected by copyrights, trademarks, trade secrets, or other proprietary rights and these rights are valid and protected
                            in all forms, media and technologies existing now or hereinafter developed.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            All Works are copyrighted as individual works and as a collective work under the international treaty provisions, and SGB owns a copyright in the selection, coordination, arrangement, and enhancement thereof.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You may not modify, remove, delete, augment, add to, publish, transmit, adapt, translate, participate in the transfer or sale of, create derivative works from, or in any way exploit any of the Works, in whole or in part.
                            Any use other than as contemplated herein, including the reproduction, modification, distribution, transmission, adaptations, translation, republication, display, or performance, of the Works, except as specifically
                            permitted herein, is strictly prohibited.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You understand and acknowledge that unauthorized disclosure, use or copying of the proprietary products and services provided pursuant to this Agreement may cause SGB and its licensors irreparable injury, which may not
                            be remedied at law, and you agree that SGB and its licensors’ remedies for breach of this Agreement may be in equity by way of injunctive or other equitable relief.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">Trademarks</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            The SGB name and logo and other related names, design marks, product names, feature names and related logos are trademarks of SGB may not be used, copied or imitated, in whole or in part, without the express prior
                            written permission of SGB. In addition, the look and feel of the Service (including all page headers, custom graphics, button icons, and scripts) constitutes the service mark, trademark and/or trade dress of SGB may not
                            be copied imitated or used, in whole or in part, without the express prior written permission of SGB.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">Refund Policy</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            SGB offers non-tangible, irrevocable goods thus we do not provide refunds after purchase, which you acknowledge prior to purchasing any product on our websites. Please make sure that you've carefully read service
                            description before making a purchase.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">Term; Termination; Suspension of Service</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            The term of this Agreement commences on your first use of the Service and continues through any subsequent use of the Service thereafter. Any unauthorized use of the Service will be deemed a material breach of this
                            Agreement. SGB, in its sole discretion, may terminate your password, account or use of the Service if you breach or otherwise fail to comply with this Agreement.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">Representations &amp; Warranties</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            Each party represents and warrants that it has the legal power and authority to enter into this Agreement. You represent and warrant that you have not falsely identified yourself nor provided any false information to
                            gain access to the Service. If you are entering into this Agreement on behalf of a company or other legal entity, you represent that you have the authority to bind such entity to these terms and conditions, in which case
                            the terms “you” or “your” shall refer to such entity. If you do not have such authority, you must immediately stop using the Service.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">Indemnification</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            You shall defend, indemnify, and hold SGB harmless from and against any and all losses, damages, liabilities, costs, judgments, charges and expenses, including reasonable attorneys’ fees, arising out of or relating to
                            any act or omission by you with respect to (i) your use of the Service or (ii) your breach or violations of this Agreement.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">Disclaimer of Warranties</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            EXCEPT AS OTHERWISE EXPRESSLY PROVIDED IN THIS AGREEMENT, (A) THE SERVICE IS PROVIDED “AS-IS” AND “WITH ALL FAULTS”, AND, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, SGB, INCLUDING ITS AFFILIATES, SUBSIDIARIES,
                            LICENSORS, SUBCONTRACTORS, DISTRIBUTORS, SERVICES PARTNERS, AGENTS AND MARKETING PARTNERS) AND EACH OF THEIR RESPECTIVE EMPLOYEES, DIRECTORS AND OFFICERS (COLLECTIVELY, THE “ SGB PARTY(IES)”) DISCLAIM ALL
                            REPRESENTATIONS, WARRANTIES AND CONDITIONS OF ANY KIND, EXPRESS OR IMPLIED, REGARDING THE SERVICE, OR OTHERWISE RELATING TO THIS AGREEMENT, INCLUDING WARRANTIES AND CONDITIONS OF FITNESS FOR A PARTICULAR PURPOSE,
                            MERCHANTABILITY, MERCHANTABLE QUALITY, NON-INFRINGEMENT AND ACCURACY AND NON-INTERFERENCE; (B) NEITHER SGB NOR ANY SGB PARTY WARRANTS THAT (I) THE SERVICE IS OR WILL BE SECURE, ACCURATE, COMPLETE, UNINTERRUPTED, WITHOUT
                            ERROR, OR FREE OF VIRUSES, WORMS, OTHER HARMFUL COMPONENTS, OR OTHER PROGRAM LIMITATIONS, (II) THE SERVICE WILL MEET YOUR REQUIREMENTS, (III) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE
                            OR RELIABLE, (IV) ANY ERRORS IN THE SERVICE WILL BE CORRECTED; (C) YOU ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR CORRECTION OF PROBLEMS CAUSED BY VIRUSES OR OTHER HARMFUL COMPONENTS, UNLESS SUCH
                            ERRORS OR VIRUSES ARE THE DIRECT RESULT OF SGB’ GROSS NEGLIGENCE OR WILLFUL MISCONDUCT; (D) SGB AND THE SGB PARTIES, JOINTLY AND SEVERALLY, DISCLAIM AND MAKE NO WARRANTIES OR REPRESENTATIONS AS TO THE ACCURACY, QUALITY,
                            RELIABILITY, SUITABILITY, COMPLETENESS, TRUTHFULNESS, USEFULNESS, OR EFFECTIVENESS OF THE FORMS, DATA, REPORTS, RESULTS OR OTHER INFORMATION OBTAINED, GENERATED OR OTHERWISE RECEIVED BY YOU FROM ACCESSING AND/OR USING
                            THE SERVICE OR OTHERWISE RELATING TO THIS AGREEMENT, AND (E) USE OF THE SERVICE IS ENTIRELY AT YOUR OWN RISK AND NEITHER SGB NOR ANY OF THE SGB PARTIES SHALL HAVE ANY LIABILITY OR RESPONSIBILITY THEREFOR.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">Limitation of Liability</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT, IN NO EVENT WHATSOEVER SHALL SGB BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF
                            PROFITS, LOST TIME OR GOOD WILL, EVEN IF SGB HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE. SGB SHALL NOT BE LIABLE FOR ANY CLAIMS
                            AGAINST YOU BY THIRD PARTIES. IN NO EVENT SHALL THE MAXIMUM CUMULATIVE LIABILITY OF SGB IN CONNECTION WITH THE SERVICE AND/OR THIS AGREEMENT, REGARDLESS OF THE FORM OF ACTION, EXCEED THE AMOUNT OF THE PAYMENTS MADE TO
                            SGB FOR THE SPECIFIC SERVICE(S) GIVING RISE TO SUCH CLAIM IN THE ONE MONTH PERIOD PRIOR TO SUCH CLAIM. NO ACTION, REGARDLESS OF FORM, ARISING FROM OR PERTAINING TO THE SERVICE MAY BE BROUGHT BY YOU MORE THAN ONE (1) YEAR
                            AFTER SUCH ACTION HAS ACCRUED. YOU ACKNOWLEDGE THAT THESE LIMITATIONS OF LIABILITY SHALL APPLY EVEN IF THE REMEDIES FAIL THEIR ESSENTIAL PURPOSE AND THAT, WITHOUT THESE LIMITATIONS, YOU WOULD HAVE PAID A HIGHER FEE FOR
                            THE SERVICE PROVIDED HEREUNDER.Certain states and/or jurisdictions do not allow the exclusion of implied warranties or limitation of liability for incidental, consequential or certain other types of damages, so the
                            exclusions set forth above may not apply to you.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">Notice</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            SGB may give notice by means of a general notice via the Service, electronic mail to your e-mail address on record in SGB’s account information. Such notice shall be deemed to have been given upon the expiration of 12
                            hours after sending (if sent by email). You may give notice to SGB (such notice shall be deemed given when received by SGB) at any time by any of the following: letter sent by pdf to SGB at the following email address:
                            support@sgbtrading.com.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">Modification to Terms</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            SGB reserves the right to modify the terms and conditions of this Agreement or its policies relating to the Service at any time, effective upon posting of an updated version of this Agreement on the Service. You are
                            responsible for regularly reviewing this Agreement. Continued use of the Service after any such changes shall constitute your consent to such changes.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">Assignment; Change in Control</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            This Agreement may not be assigned by you without the prior written approval of SGB but may be assigned without your consent by SGB to any party acquiring all or any part of SGB’ assets. Any actual or proposed change in
                            control of your account that results or would result in a Competitor of SGB directly owning or controlling 50% or more of your account shall entitle SGB to terminate this Agreement with just cause immediately upon
                            written notice.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">&nbsp;Customer Communications; Disclosure</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            From time-to-time SGB may ask whether or not you wish to receive marketing and other non-critical Service-related communications. You may elect not to receive such communications at that time or opt-out of receiving such
                            communications at any subsequent time by notifying SGB. Because the Service is a hosted, online application, SGB may need to notify all users of the Service (whether or not they have opted out as described above) of
                            important announcements regarding the operation of the Service. SGB reserves the right to disclose that you are a user of the Service.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">Trade Risk Disclaimer</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            YOU AGREE THAT ANY USE OF THE SITE OR SERVICE SHOULD NOT BE INTERPRETED: (i) AS INVESTMENT, LEGAL OR TAX ADVICE; (ii) AS AN OFFER TO BUY OR SELL ANY SECURITIES, DERIVATIVE, FUTURES OR SERVICES; OR (iii) AS A REPLACEMENT
                            FOR YOUR OWN PROFESSIONAL SKILL AND JUDGMENT.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">DEFINITIONS, EXPRESSIONS AND ABBREVIATIONS USED</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">For the purposes of the GTC, the following definitions shall have the following meanings:</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">“Client Section” means the user interface located on the Website.</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">
                            “Content” means the Website and all Services, including the Client Section, their appearance and all applications, data, information, multimedia elements such as texts, drawings, graphics, design, icons, images, audio
                            and video samples and other content that may form the Website and the Services.
                        </span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">“Customer” means the user of the Services.</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">“SGB Challenge and Verification account” means trading accounts related to trading education courses provided as part of the Services by the Provider.</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">“SGB Trader account” means a trading account, which relates to the SGB Trader program provided by a third-party provider.</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">“Forbidden Trading Practices” means trading practices strictly forbidden while using our Services and are more detailed in Section 9.4 of these GTC.</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">“GTC” means these General Terms and Conditions of SGB.</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">“Provider” means the provider of certain Services.</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">“Schedules” means Schedule 1 and any other Schedules as applicable, which are part of these GTC.</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">“Services” means the Provider’s services as set out in clauses 1.1.</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">“Trading Platform” means an electronic interface provided by a third party in which the Customer performs the demo trading; and</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">“Website” means the website www.SGBTrading.com.</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">For the purposes of the GTC and their schedules, the following expressions and abbreviations shall have the following meanings:</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong><span style="font-size: 12pt;">“Calendar day” means the period from midnight to midnight of the time currently valid in the UAE.</span></strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;">
            <span style="font-size: 11pt;">
                <span style="font-family: Calibri, sans-serif;">
                    <strong>
                        <span style="font-size: 12pt;">“initial capital” means a fictitious amount that the Customer has chosen when selecting the option of the SGB Challenge and which the Customer will use to perform demo trading.</span>
                    </strong>
                </span>
            </span>
        </p>
        <p style="text-align: left;"><br /></p>
        <p style="text-align: left;"><br /></p>
        <p style="text-align: left;"><br /></p>`
        }}>

        </div>
    )
}

export default Terms