import React, {useEffect, useState} from "react";
import useTitle from "../hooks/useTitle";
import TitleInfo from "../components/singleblog/TitleInfo";
import BlogContent from "../components/singleblog/BlogContent";
import SuggestedBlog from "../components/singleblog/SuggestedBlog";
import FastLogin from "../components/FastLogin";
import {useParams} from "react-router-dom";
import axios from "axios";
import {ClipLoader} from "react-spinners";

const SingleBlog = () => {
    let {slug} = useParams()
    const [articleData, setArticleData] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchArticle()
        setLoading(true)
    }, [slug])

    const fetchArticle = async () => {
        axios.get(`/api/blog/` + slug).then(({data}) => {
            setArticleData(data)
            setLoading(false)
        })
    }

    useTitle(articleData.title)

    if (loading) {
        return (
            <div className='text-center py-[80px] bg-primary-bg-color'>
                <ClipLoader color={'#0098ff'} loading={loading} size={60} aria-label="Loading Spinner" data-testid="loader"/>
            </div>
        )
    }

    if (articleData) {
        return (
            <section className="w-full bg-primary-bg-color overflow-hidden">
                <TitleInfo title={articleData.title} categoryTitle={articleData.blog_category_tbl ? articleData.blog_category_tbl.title : 'دسته بندی نشده'} author={articleData.user_tbl.full_name} date={articleData.created_at}/>
                <BlogContent title={articleData.title} thumbnail={articleData.thumbnail_tbl ? axios.defaults.baseURL + 'storage/' + articleData.thumbnail_tbl.path + '886/' + articleData.thumbnail_tbl.file_name : ''} content={articleData.content_text}/>
                <SuggestedBlog/>
                <FastLogin dataContent={articleData.quick_register_section}/>
            </section>
        );
    }
};

export default SingleBlog
