import React from "react";
import {Link} from "react-router-dom";
import SiteLogoVertical from "../assets/svg/SiteLogoVertical";
import Twiter from "../assets/svg/Twiter";
import Linkedin from "../assets/svg/Linkedin";
import WhatsApp from "../assets/svg/WhatsApp";
import Instagram from "../assets/svg/Instagram";
import Facebooke from "../assets/svg/Facebooke";
import Youtube from "../assets/svg/Youtube";
import PhoneCalling from "../assets/svg/PhoneCalling";
import {useCookies} from "react-cookie";
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import i18n from "../i18n";

const Footer = ({t}) => {
    return (
        <footer className="w-full grid bg-primary-bg-color px-[25px] md:px-[72px] pt-10 pb-[16px]">
            <div className="flex order-2 lg:order-1 justify-between mb-[40px] lg:border-t lg:pt-[40px]">
                <div className="flex flex-wrap justify-center lg:flex-nowrap items-center text-center lg:text-start">
                    <div className='flex-col mb-[40px] lg:mb-0'>
                        <SiteLogoVertical/>
                    </div>
                    <div className="lg:w-[80%] xl:w-[65%] lg:mr-[27px] xl:pl-[65px] mb-[40px] lg:mb-0">
                        <p className="text-sm font-extrabold mb-[7px]">
                            {t('HomePage.سرمایه گذار برتر', 'سرمایه گذار برتر')} | {t('HomePage.تامین سرمایه معامله گران', 'تامین سرمایه معامله گران')} </p>
                        <p className="text-[#8f8f8f] text-sm leading-[35px] font-medium">{t('Footer.FooterAboutText', 'سرمایه گذار برتر یک شرکت تامین سرمایه است و به عنوان کارگزار خدماتی ارائه نمیدهد. ترید در بازارهای مالی، ریسک زیادی برای سرمایه شما دارد و ممکن است برای همه مناسب نباشد. به همین دلیل باید خطرات آن را درک کنید و در صورت نیاز به دنبال مشاور مالی متخصص باشید. شما باید پیش از استفاده از خدمات ما اهداف، وضعیت مالی و سطح تجربه خود را ارزیابی کنید.')}</p>
                    </div>
                    {/*<div className='grow lg:pr-[20px] xl:pr-[110px]'>*/}
                    {/*    <p className="text-sm font-extrabold mb-4">{t('Footer.نشان های اعتماد', 'نشان های اعتماد')}</p>*/}
                    {/*    <div className="grid grid-flow-col auto-cols-max justify-end gap-[10px] justify-center">*/}
                    {/*        <div className="col-auto p-[15px] rounded-[20px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)]">*/}
                    {/*            <img className="mx-auto" src={require("../assets/img/TrustIcon1.png")} alt="TrustIcon1"/>*/}
                    {/*        </div>*/}
                    {/*        <div className="col-auto p-[15px] rounded-[20px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)]">*/}
                    {/*            <img className="mx-auto" src={require("../assets/img/TrustIcon2.png")} alt="TrustIcon2"/>*/}
                    {/*        </div>*/}
                    {/*        <div className="col-auto p-[15px] rounded-[20px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)]">*/}
                    {/*            <img className="mx-auto" src={require("../assets/img/TrustIcon3.png")} alt="TrustIcon3"/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className="grid order-1 grid-cols-1 lg:grid-cols-4 lg:flex-nowrap justify-between border-b mb-[45px] lg:mb-[25px] border-y py-10 text-center lg:text-start">
                <div className='order-2 lg:order-1 text-[12px] sm:text-sm space-y-[17px] mb-[30px] lg:mb-0'>
                    <div className="font-extrabold">{t('Footer.با سرمایه گذار برتر', 'با سرمایه گذار برتر')}</div>
                    <ul className="list-disc grid grid-cols-2 justify-items-center lg:block">
                        <li className={`font-medium text-[#8F8F8F] hover:text-[#0098FF] transition-all list-disc mt-[17px] ${i18next.language === 'fa' ? 'mr-4' : 'ml-4'}`}>
                            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}blog`}>{t('Footer.وبلاگ سرمایه گذار برتر', 'وبلاگ سرمایه گذار برتر')}</Link>
                        </li>
                        <li className={`font-medium text-[#8F8F8F] hover:text-[#0098FF] transition-all list-disc mt-[17px] ${i18next.language === 'fa' ? 'mr-4' : 'ml-4'}`}>
                            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}careers`}>{t('Footer.همکاری با سرمایه گذار برتر', 'همکاری با سرمایه گذار برتر')}</Link>
                        </li>
                        <li className={`font-medium text-[#8F8F8F] hover:text-[#0098FF] transition-all list-disc mt-[17px] ${i18next.language === 'fa' ? 'mr-4' : 'ml-4'}`}>
                            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}aboutus`}>{t('Footer.درباره سرمایه گذار برتر', 'درباره سرمایه گذار برتر')}</Link>
                        </li>
                        <li className={`font-medium text-[#8F8F8F] hover:text-[#0098FF] transition-all list-disc mt-[17px] ${i18next.language === 'fa' ? 'mr-4' : 'ml-4'}`}>
                            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}contactus`}>{t('Footer.تماس با سرمایه گذار برتر', 'تماس با سرمایه گذار برتر')}</Link>
                        </li>
                    </ul>
                </div>
                <div className="order-2 lg:order-1 text-[12px] sm:text-sm space-y-[17px] mb-[30px] lg:mb-0">
                    <div className="font-extrabold">{t('Footer.لینک های کاربردی', 'لینک های کاربردی')}</div>
                    <ul className="list-disc grid grid-cols-2 justify-items-center lg:block">
                        <>
                            {useCookies(['auth_token'])[0].auth_token ? '' :
                                <li className={`font-medium text-[#8F8F8F] hover:text-[#0098FF] transition-all list-disc mt-[17px] ${i18next.language === 'fa' ? 'mr-4' : 'ml-4'}`}>
                                    <Link to={`/${i18n.language === 'en1' ? i18n.language : ''}/login`}>{t('Footer.ورود و ثبت نام', 'ورود و ثبت نام')}</Link>
                                </li>
                            }
                        </>
                        <li className={`font-medium text-[#8F8F8F] hover:text-[#0098FF] transition-all list-disc mt-[17px] ${i18next.language === 'fa' ? 'mr-4' : 'ml-4'}`}>
                            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}plans`}>{t('Footer.تعرفه ها', 'تعرفه ها')}</Link>
                        </li>
                        <li className={`font-medium text-[#8F8F8F] hover:text-[#0098FF] transition-all list-disc mt-[17px] ${i18next.language === 'fa' ? 'mr-4' : 'ml-4'}`}>
                            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}`}>{t('Footer.کانال تلگرام', 'کانال تلگرام')}</Link>
                        </li>
                        <li className={`font-medium text-[#8F8F8F] hover:text-[#0098FF] transition-all list-disc mt-[17px] ${i18next.language === 'fa' ? 'mr-4' : 'ml-4'}`}>
                            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}`}>{t('Footer.جستجو در سایت', 'جستجو در سایت')}</Link>
                        </li>
                    </ul>
                </div>
                <div className="order-2 lg:order-1 text-[12px] sm:text-sm space-y-[17px] mb-[30px] lg:mb-0">
                    <div className="font-extrabold">{t('Footer.دسترسی سریع', 'دسترسی سریع')}</div>
                    <ul className="list-disc grid grid-cols-2 justify-items-center lg:block">
                        <li className={`font-medium text-[#8F8F8F] hover:text-[#0098FF] transition-all list-disc mt-[17px] ${i18next.language === 'fa' ? 'mr-4' : 'ml-4'}`}>
                            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}`}>{t('Footer.سوالات متداول', 'سوالات متداول')}</Link>
                        </li>
                        <li className={`font-medium text-[#8F8F8F] hover:text-[#0098FF] transition-all list-disc mt-[17px] ${i18next.language === 'fa' ? 'mr-4' : 'ml-4'}`}>
                            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}careers`}>{t('Footer.استخدام شوید', 'استخدام شوید')}</Link>
                        </li>
                        <li className={`font-medium text-[#8F8F8F] hover:text-[#0098FF] transition-all list-disc mt-[17px] ${i18next.language === 'fa' ? 'mr-4' : 'ml-4'}`}>
                            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}rules`}>{t('Footer.قوانین و مقررات', 'قوانین و مقررات')}</Link>
                        </li>
                        <li className={`font-medium text-[#8F8F8F] hover:text-[#0098FF] transition-all list-disc mt-[17px] ${i18next.language === 'fa' ? 'mr-4' : 'ml-4'}`}>
                            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}contactus`}>{t('Footer.ثبت شکایات', 'ثبت شکایات')}</Link>
                        </li>
                    </ul>
                </div>
                <div className='order-1 lg:order-4'>
                    <div className="text-sm font-extrabold mb-[30px]">{t('Footer.همراه ما باشید!', 'همراه ما باشید!')}</div>
                    <div className="flex justify-center gap-[30px] mb-[30px] lg:mb-[51px]">
                        <a href="#" className="hover-icon">
                            <div className="icon-hidden">
                                <Twiter/>
                            </div>
                            <div className="hidden icon-show">
                                <Twiter color="#0098FF"/>
                            </div>
                        </a> <a href="#" className="hover-icon">
                        <div className="icon-hidden">
                            <Linkedin/>
                        </div>
                        <div className="hidden icon-show">
                            <Linkedin color="#0098FF"/>
                        </div>
                    </a> <a href="#" className="hover-icon">
                        <div className="icon-hidden">
                            <WhatsApp/>
                        </div>
                        <div className="hidden icon-show">
                            <WhatsApp color="#0098FF"/>
                        </div>
                    </a> <a href="#" className="hover-icon">
                        <div className="icon-hidden">
                            <Instagram/>
                        </div>
                        <div className="hidden icon-show">
                            <Instagram color="#0098FF"/>
                        </div>
                    </a> <a href="#" className="hover-icon">
                        <div className="icon-hidden">
                            <Facebooke/>
                        </div>
                        <div className="hidden icon-show">
                            <Facebooke color="#0098FF"/>
                        </div>
                    </a> <a href="#" className="hover-icon">
                        <div className="icon-hidden">
                            <Youtube/>
                        </div>
                        <div className="hidden icon-show">
                            <Youtube color="#0098FF"/>
                        </div>
                    </a>
                    </div>
                    {/*<div className="flex justify-between items-center bg-white rounded-[20px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] p-[17px] mb-[30px] lg:mb-0">*/}
                    {/*    <div className="text-sm font-bold text-[#8F8F8F]">{t('Footer.تلفن مشاوره', 'تلفن مشاوره')}</div>*/}
                    {/*    <div className="flex items-center gap-1">*/}
                    {/*        <p className="text-lg font-extrabold text-[#0098FF]">*/}
                    {/*            48 89 4567 <span className="text-[#D1D1D1]">021</span>*/}
                    {/*        </p>*/}
                    {/*        <PhoneCalling/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className="flex order-3 justify-between items-center text-sm font-medium text-[#8F8F8F] mt-[5px] border-t pt-[25px] lg:pt-0 lg:border-t-0 text-center lg:text-start">
                <span className='w-full lg:w-max'>{t('Footer.copyright', 'اين وبسايت متعلق به گروه سرمایه گذار برتر ميباشد و تمامی حقوق آن محفوظ است.')}</span> <span className='hidden lg:block'>.2022 © Copyright All rights reserved</span>
            </div>
        </footer>
    );
};

export default withTranslation()(Footer);
