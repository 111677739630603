import React from "react";
import WorkWithSiteCover from "../../assets/svg/WorkWithSiteCover";
import RightWaveIcon from "../../assets/svg/RightWaveIcon";
import LeftWaveIcon from "../../assets/svg/LeftWaveIcon";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

const StepsToWorkSite = (props) => {
    return (
        <div className="w-full relative mb-[92px] px-[25px] sm:px-[72px] overflow-hidden">
            <div>
                <h4 className="text-center text-[22px] sm:text-[34px] text-secondary-bg-color">
                    <p className="font-extrabold">{i18next.t('HomePage.مراحل کار با سایت', 'مراحل کار با سایت')}</p>
                    <p className="font-light">{i18next.t('HomePage.سرمایه گذار برتر', 'سرمایه گذار برتر')}</p>
                </h4>
            </div>
            <div className="absolute top-[-15px] right-0 hidden lg:block">
                <RightWaveIcon/>
            </div>
            <div className="grid grid-cols-1 justify-items-center lg:grid-cols-3 text-center lg:text-start">
                <div className="w-min transform scale-[.8] sm:scale-[0.85] xl:scale-[1] col-span-1 w-full relative mb-[-86px] sm:mb-0 mt-[20px] sm:mt-[80px] lg:mt-[130px]">
                    <div className="absolute top-0">
                        <WorkWithSiteCover/>
                    </div>
                    <div className="w-[396px] h-[460px] relative bg-white rounded-[20px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] m-[10px] mt-[73px] pt-[100px] pb-[40px] px-[30px]">
                        <div className="absolute top-[-13%] left-1/2 -translate-x-1/2 flex flex-col justify-center w-[108px] h-[108px] rounded-full bg-white shadow-[5px_5px_40px_rgba(0,0,0,0.05)]">
                            <div className="text-center text-xl leading-[30px]">
                                <div>{i18next.t('HomePage.مرحله', 'مرحله')}</div>
                                <div>{i18next.t('HomePage.اول', 'اول')}</div>
                            </div>
                        </div>
                        <p className="text-xl font-extrabold leading-[40px] text-primary-btn-color" dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.step_1.title_en : props.dataContent.step_1.title_fa }}/>
                        <p className="text-[#767D80] font-medium leading-[35px]" dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.step_1.desc_en : props.dataContent.step_1.desc_fa }}/>
                    </div>
                </div>
                <div className="w-min transform scale-[.8] sm:scale-[0.85] xl:scale-[1] col-span-1 w-full relative mb-[-86px] sm:mb-0 mt-[20px] sm:mt-[40px] lg:mt-[60px]">
                    <div className="absolute top-0">
                        <WorkWithSiteCover/>
                    </div>
                    <div className="w-[396px] h-[460px] relative bg-white rounded-[20px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] m-[10px] mt-[73px] pt-[100px] pb-[40px] px-[30px]">
                        <div className="absolute top-[-13%] left-1/2 -translate-x-1/2 flex flex-col justify-center w-[108px] h-[108px] rounded-full bg-white shadow-[5px_5px_40px_rgba(0,0,0,0.05)]">
                            <div className="text-center text-xl font-semibold leading-[30px]">
                                <div>{i18next.t('HomePage.مرحله', 'مرحله')}</div>
                                <div>{i18next.t('HomePage.دوم', 'دوم')}</div>
                            </div>
                        </div>
                        <p className="text-xl font-extrabold leading-[40px] text-primary-btn-color" dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.step_2.title_en : props.dataContent.step_2.title_fa }}/>
                        <p className="text-[#767D80] font-medium leading-[35px]" dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.step_2.desc_en : props.dataContent.step_2.desc_fa }}/>
                    </div>
                </div>
                <div className="w-min transform scale-[.8] sm:scale-[0.85] xl:scale-[1] col-span-1 w-full mb-[10px] mb-[-46px] sm:mb-0 mt-[20px] sm:mt-[40px] lg:mt-0 relative">
                    <div className="absolute top-0">
                        <WorkWithSiteCover/>
                    </div>
                    <div className="w-[396px] h-[460px] relative bg-white rounded-[20px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] m-[10px] mt-[73px] pt-[100px] pb-[40px] px-[30px]">
                        <div className="absolute top-[-13%] left-1/2 -translate-x-1/2 flex flex-col justify-center w-[108px] h-[108px] rounded-full bg-white shadow-[5px_5px_40px_rgba(0,0,0,0.05)]">
                            <div className="text-center text-xl font-extrabold leading-[30px]">
                                <div>{i18next.t('HomePage.مرحله', 'مرحله')}</div>
                                <div>{i18next.t('HomePage.سوم', 'سوم')}</div>
                            </div>
                        </div>
                        <p className="text-xl font-extrabold leading-[40px] text-primary-btn-color" dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.step_3.title_en : props.dataContent.step_3.title_fa }}/>
                        <p className="text-[#767D80] font-medium leading-[35px]" dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.step_3.desc_en : props.dataContent.step_3.desc_fa }}/>
                    </div>
                </div>
            </div>
            <div className="absolute bottom-[-125px] left-0 hidden lg:block">
                <LeftWaveIcon/>
            </div>
        </div>
    );
};

export default withTranslation()(StepsToWorkSite);
