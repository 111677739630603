export default ({color = "#C8C8C8"}) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4984 0.000109753C9.1941 -0.0055687 6.0224 1.30533 3.67928 3.64472C1.33617 5.98412 0.0129967 9.16111 9.36274e-06 12.4789C-0.00322401 15.1071 0.831091 17.6673 2.38096 19.7853L0.822539 24.4321L5.62697 22.8888C7.24472 23.9757 9.09386 24.6665 11.0258 24.9059C12.9577 25.1453 14.9185 24.9263 16.7509 24.2669C18.5833 23.6075 20.2362 22.526 21.5768 21.109C22.9174 19.6919 23.9085 17.9791 24.4703 16.1078C25.0321 14.2365 25.1489 12.2588 24.8116 10.3339C24.4743 8.40897 23.6922 6.59045 22.528 5.02408C21.3638 3.45771 19.85 2.18742 18.1081 1.31493C16.3663 0.442439 14.445 -0.00808239 12.4984 0.000109753ZM19.7706 17.6179C19.5188 18.0764 19.1676 18.4727 18.743 18.7768C18.3185 19.0808 17.8314 19.2851 17.3176 19.3748C16.6635 19.5226 15.8115 19.6374 12.94 18.4388C10.4091 17.2104 8.25785 15.3152 6.71609 12.9551C5.86901 11.8725 5.35325 10.5663 5.23132 9.19502C5.216 8.62637 5.32214 8.06103 5.54273 7.53707C5.76331 7.0131 6.09319 6.54251 6.51008 6.15731C6.86265 5.84809 7.32136 5.68902 7.78884 5.71396H8.20744C8.3938 5.69757 8.57939 5.75325 8.72665 5.86909C8.8739 5.98493 8.97189 6.15249 9.00062 6.3381C9.30315 7.06054 10.0373 8.85006 10.124 9.03067C10.1859 9.13343 10.2231 9.24925 10.2325 9.36899C10.2418 9.48873 10.2232 9.60934 10.178 9.72055C10.0774 9.92968 9.94007 10.1186 9.77245 10.2785C9.58766 10.492 9.41264 10.6564 9.22949 10.8862C9.1126 10.9726 9.0325 11.0999 9.00521 11.243C8.97793 11.3861 9.00551 11.5343 9.08237 11.6579C9.63231 12.5952 10.3154 13.4471 11.1101 14.1865C11.9718 14.96 12.9649 15.5716 14.0421 15.9924C14.1872 16.0559 14.3477 16.0759 14.5039 16.0497C14.6601 16.0235 14.8053 15.9524 14.9219 15.8449C15.2738 15.432 15.6014 14.9988 15.903 14.5477C15.9935 14.4003 16.1353 14.292 16.3009 14.2434C16.4664 14.1949 16.644 14.2098 16.7992 14.2851C17.1377 14.4001 18.9251 15.2867 19.2898 15.4673C19.5548 15.5479 19.795 15.6948 19.988 15.8942C20.0845 16.4776 20.01 17.0762 19.7738 17.6179H19.7706Z"
      fill={color}
    />
  </svg>
);
