import React, {useState} from "react";
import ArrowTop from "../../assets/svg/ArrowTop";
import ArrowDown from "../../assets/svg/ArrowDown";
import {Trans, withTranslation} from "react-i18next";
import i18next from "i18next";

const FullRulesReview = (props) => {
    const [isRulesOpen, setIsRulesOpen] = useState({
        ruleOne: false,
        ruleTwo: false,
        ruleThree: false,
        ruleFour: false,
        ruleFive: false,
        ruleSix: false,
        ruleSeven: false,
        ruleEight: false,
        ruleNine: false,
        ruleTen: false,
    });

    let Rules = Object.values(props.dataContent);
    return (
        <section className="w-full px-[25px] lg:px-[72px] mb-[127px]">
            <div className="flex items-center gap-[32px] mb-[35px]">
                <div className="text-lg font-extrabold whitespace-nowrap text-[32px]">
                    <Trans i18nKey='RulesPage.بررسی کامل قوانین'> بررسی کامل <span className='font-light'>قوانین</span> </Trans>
                </div>
                <div className="w-full h-[1px] bg-[#E8E8E8]"/>
            </div>
            {/*<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-none xl:grid-flow-col xl:auto-cols-auto justify-between items-center gap-[20px] mb-[59px]">*/}
            {/*    <button className="text-[#C7C7C7] font-bold text-sm hover:text-primary-btn-color hover:border-primary-btn-color transition-all border rounded-[10px] whitespace-nowrap px-[30px] py-[18px]">*/}
            {/*        {t('RulesPage.rulesCat1', 'قوانین پرداخت ها')}*/}
            {/*    </button>*/}
            {/*    <button className="text-[#C7C7C7] font-bold text-sm hover:text-primary-btn-color hover:border-primary-btn-color transition-all border rounded-[10px] whitespace-nowrap px-[30px] py-[18px]">*/}
            {/*        {t('RulesPage.rulesCat2', 'قوانین موارد غیر مجاز')}*/}
            {/*    </button>*/}
            {/*    <button className="text-[#C7C7C7] font-bold text-sm hover:text-primary-btn-color hover:border-primary-btn-color transition-all border rounded-[10px] whitespace-nowrap px-[30px] py-[18px]">*/}
            {/*        {t('RulesPage.rulesCat3', 'قوانین تقلب')}*/}
            {/*    </button>*/}
            {/*    <button className="text-[#C7C7C7] font-bold text-sm hover:text-primary-btn-color hover:border-primary-btn-color transition-all border rounded-[10px] whitespace-nowrap px-[30px] py-[18px]">*/}
            {/*        {t('RulesPage.rulesCat4', 'قوانین رشد سرمایه')}*/}
            {/*    </button>*/}
            {/*    <button className="text-[#C7C7C7] font-bold text-sm hover:text-primary-btn-color hover:border-primary-btn-color transition-all border rounded-[10px] whitespace-nowrap px-[30px] py-[18px]">*/}
            {/*        {t('RulesPage.rulesCat5', 'قوانین اینسترومنت ها')}*/}
            {/*    </button>*/}
            {/*    <button className="text-[#C7C7C7] font-bold text-sm hover:text-primary-btn-color hover:border-primary-btn-color transition-all border rounded-[10px] whitespace-nowrap px-[30px] py-[18px]">*/}
            {/*        {t('RulesPage.rulesCat6', 'قوانین موارد غیر مجاز')}*/}
            {/*    </button>*/}
            {/*    <button className="text-[#C7C7C7] font-bold text-sm hover:text-primary-btn-color hover:border-primary-btn-color transition-all border rounded-[10px] whitespace-nowrap px-[30px] py-[18px]">*/}
            {/*        {t('RulesPage.rulesCat7', 'قوانین تقلب')}*/}
            {/*    </button>*/}
            {/*</div>*/}
            <div className="grid grid-cols-1 lg:grid-cols-2 items-start gap-[24px]">
                {
                    props.dataContent ?
                        Rules.map((item) =>
                            <div className="w-full col-span-1 bg-white rounded-[20px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] lg:mb-[24px] px-[25px] sm:px-[40px] py-[35px]">
                                <div className="flex justify-between items-center cursor-pointer" onClick={() =>
                                    setIsRulesOpen({...isRulesOpen, ruleOne: !isRulesOpen.ruleOne})
                                }>
                                    <p className="text-[#0F4C75] text-[11px] sm:text-[13px] md:text-[14px] xl:text-[16px] md:text-xl font-extrabold">
                                        {i18next.language == 'en' ? item.question_en : item.question_fa}
                                    </p>
                                    {isRulesOpen.ruleOne ? <ArrowDown/> : <ArrowTop/>}
                                </div>
                                {isRulesOpen.ruleOne ? (
                                    <div className="text-[#767D80] font-medium leading-[35px] border-t mt-[20px] pt-[20px] plan-detail-anime text-[12px] md:text-[14px] lg:text-[16px]"  dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? item.answer_en : item.answer_fa }}/>
                                ) : null}
                            </div>
                        ) : null
                }
            </div>
        </section>
    );
};

export default withTranslation()(FullRulesReview)