import React from "react";
import {useNavigate} from "react-router-dom";
import CustomerStatisticsCover from "../../assets/svg/CustomerStatisticsCover";
import ReceiptDiscount from "../../assets/svg/ReceiptDiscount";
import AddUser from "../../assets/svg/AddUser";
import Ranking from "../../assets/svg/Ranking";
import TopFrameVideo from "../../assets/svg/TopFrameVideo";
import BottomFrameVideo from "../../assets/svg/BottomFrameVideo";
import ArrowLeftVideo from "../../assets/svg/ArrowLeftVideo";
import ArrowRightVideo from "../../assets/svg/ArrowRightVideo";
import VideoPaginate from "../../assets/svg/VideoPaginate";
import Play from "../../assets/svg/Play";
import {Trans, withTranslation} from "react-i18next";
import i18next from "i18next";

const CustomerStatistics = (props) => {
    const navigate = useNavigate();

    return (
        <>
            <section className="flex flex-wrap xl:flex-nowrap gap-[24px] mt-[50px] xl:mt-[95px] mb-[76px] px-[25px] md:px-[72px]">
                <div className='w-full xl:w-max xl:flex-1 order-2 xl:order-1'>
                    <div className="relative bg-white rounded-[20px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] p-[30px_20px_56px]">
                        <p className="text-center text-xl font-extrabold text-secondary-bg-color" dangerouslySetInnerHTML={{ __html: (i18next.language == 'en' ? props.dataContent.title_en : props.dataContent.title_fa) + ' <span class="font-light">(' + new Date().getFullYear() + ')</span>' }} />
                        <div className="absolute top-[38px] text-center w-full right-0 hidden lg:block pointer-events-none">
                            <span className='inline-block'><CustomerStatisticsCover/></span>
                        </div>
                        <div className="flex justify-center items-center gap-[24px] mt-[49px] px-[10px]" dir='ltr'>
                            <div className="max-w-[153px] flex flex-col justify-center items-center">
                                <AddUser/>
                                <div className="text-xl font-bold">+{ props.dataContent.statics_1 }</div>
                                <div className="text-xs text-[#767D80]">{i18next.t('HomePage.تعداد ثبت نامی ها', 'تعداد ثبت نامی ها')}</div>
                            </div>
                            <div className="w-[1px] h-[107px] bg-[#ECECEC]"/>
                            <div className="max-w-[153px] flex flex-col justify-center items-center">
                                <Ranking/>
                                <div className="text-xl font-bold">+{ props.dataContent.statics_2 }٪</div>
                                <div className="text-xs text-[#767D80]">{i18next.t('HomePage.بزرگترین پرداخت سود', 'بزرگترین پرداخت سود')}</div>
                            </div>
                            <div className="w-[1px] h-[107px] bg-[#ECECEC]"/>
                            <div className="max-w-[153px] flex flex-col justify-center items-center">
                                <ReceiptDiscount/>
                                <div className="text-xl font-bold">+{ props.dataContent.statics_3 }</div>
                                <div className="text-xs text-[#767D80]">{i18next.t('HomePage.مجموع پرداخت سود', 'مجموع پرداخت سود')}</div>
                            </div>
                        </div>
                    </div>
                    <button onClick={() => navigate("login")} className="w-full h-[62px] bg-primary-btn-color text-primary-bg-color hover:bg-secondary-btn-color transition-all rounded-[10px] mt-[34px] px-[60px] py-[11px]">
                        {i18next.t('HomePage.همین حالا به مشتریان ما بپیوندید', 'همین حالا به مشتریان ما بپیوندید')}
                    </button>
                </div>
                <div className="w-full xl:w-max xl:flex-1 order-1 xl:flex-none">
                    <div className='w-full xl:w-max relative bg-white rounded-[20px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] p-[30px]'>
                        <div className="absolute top-[30px] left-1/2 -translate-x-1/2 z-10">
                            <TopFrameVideo/>
                        </div>
                        <p className="absolute top-[30px] w-max left-1/2 -translate-x-1/2 z-10 text-[#1B262C] text-[15px] sm:text-xl font-extrabold">
                            <Trans i18nKey='HomePage.دیدگاه مشتریان'> دیدگاه مشتریان <span className="font-light">درباره ما</span> </Trans>
                        </p>
                        <div className="absolute bottom-[30px] left-1/2 -translate-x-1/2 z-10">
                            <BottomFrameVideo/>
                        </div>
                        <div className="absolute bottom-[30px] left-1/2 -translate-x-1/2 z-10">
                            <div className="w-[237px] flex justify-between items-center gap-[35px]">
                                <Trans i18nKey='HomePage.ArrowVideo'><ArrowRightVideo/><VideoPaginate/><ArrowLeftVideo/></Trans>
                            </div>
                        </div>
                        <div className='relative'>
                            <img className="w-full rounded-[10px]" src={require("../../assets/img/video-cover-image.png")} alt="video cover image"/>
                            <div className="w-full h-full absolute top-[0] right-[0] bg-[#1B262C] bg-opacity-50 rounded-[10px]"/>
                            <div className="absolute top-1/2 left-1/2 bg-[rgba(217,217,217,0.1)] rounded-full -translate-x-1/2 -translate-y-1/2 bg-gradient-to-r from-[rgba(255,255,255,0.1)] to-[rgba(217,217,217,0.1)] backdrop-blur-[20px] px-[27px] py-[25px]">
                                <Play/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default withTranslation()(CustomerStatistics);
