export default () => (
  <svg
    width="90"
    height="90"
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M78 34.5C38.4 34.5 46.1067 67.6822 14.4 61C13.3641 47.1443 9.33053 24.8672 15.5 18C19.6319 14.8518 34 8 45 8C57.5 8 77.4372 13.9592 78 34.5Z"
      fill="#EFEFEF"
    />
    <path
      d="M39.3375 8.36248L20.625 15.375C16.3125 16.9875 12.7875 22.0874 12.7875 26.6999V54.5624C12.7875 58.9874 15.7125 64.7999 19.275 67.4624L35.4 79.5C40.6875 83.475 49.3874 83.475 54.6749 79.5L70.8 67.4624C74.3625 64.7999 77.2874 58.9874 77.2874 54.5624V26.6999C77.2874 22.0874 73.7625 16.9875 69.45 15.375L50.7375 8.36248C47.55 7.19998 42.45 7.19998 39.3375 8.36248Z"
      stroke="#1B262C"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45 40.9502C44.85 40.9502 44.6625 40.9502 44.5125 40.9502C40.9875 40.8377 38.175 37.9127 38.175 34.3502C38.175 30.7127 41.1375 27.7501 44.775 27.7501C48.4125 27.7501 51.3751 30.7127 51.3751 34.3502C51.3376 37.9502 48.525 40.8377 45 40.9502Z"
      stroke="#1B262C"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.5375 51.4502C33.9375 53.8502 33.9375 57.7876 37.5375 60.1876C41.625 62.9251 48.3375 62.9251 52.425 60.1876C56.025 57.7876 56.025 53.8502 52.425 51.4502C48.375 48.7127 41.6625 48.7127 37.5375 51.4502Z"
      stroke="#1B262C"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
