import React from "react";
import Location from "../../assets/svg/Location";
import Email from "../../assets/svg/Email";
import PhoneCallingOutline from "../../assets/svg/PhoneCallingOutline";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { withTranslation } from "react-i18next";

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});
L.Marker.prototype.options.icon = DefaultIcon;

const ContactWays = ({ t }) => {
  return (
    <div className="w-full mb-[94px] px-[25px] sm:px-[72px]">
      <h4 className="text-center text-secondary-bg-color text-[34px] mb-[47px]">
        <p className="font-extrabold">{t('ContactUs.راه های ارتباط با', 'راه های ارتباط با')}</p>
        <p className="font-light">{t('سرمایه گذاران برتر', 'سرمایه گذاران برتر')}</p>
      </h4>
      <div className="flex flex-wrap lg:flex-nowrap justify-center items-center gap-[24px]">
        <div className="w-full lg:w-[746px] md:h-[324px] bg-white rounded-[20px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] p-[40px]">
          <div className="flex flex-wrap sm:flex-nowrap justify-between items-center text-[#797979] text-[13px] font-bold border-b pb-[15px]">
            <div className="flex items-center gap-[10px] mb-3 sm:mb-0">
              <Location />
              <p>{t('ContactUs.دفتر مرکزی', 'دفتر مرکزی')}</p>
            </div>
            <p>
              Duqe Square Business Center, Quarter Deck, QE2, Mina Rashid 554789
              Dubai U.A.E
            </p>
          </div>
          <div className="flex flex-wrap sm:flex-nowrap justify-between items-center text-[#797979] text-[13px] font-bold border-b py-[15px]">
            <div className="flex items-center gap-[10px] mb-3 sm:mb-0">
              <Email />
              <p>{t('ContactUs.ایمیل', 'ایمیل')}</p>
            </div>
            <p>admin@sgbtrading.com</p>
          </div>
          {/*<div className="flex flex-wrap sm:flex-nowrap justify-between items-center text-[#797979] text-[13px] font-bold border-b py-[15px]">*/}
          {/*  <div className="flex items-center gap-[10px]">*/}
          {/*    <PhoneCallingOutline />*/}
          {/*    <p>{t('ContactUs.تماس تایم اداری', 'تماس تایم اداری')}</p>*/}
          {/*  </div>*/}
          {/*  <p dir='rtl'>۵۶ ۲۳ ۴۲ ۲۸ ۰۲۱</p>*/}
          {/*</div>*/}
          <div className="flex flex-wrap sm:flex-nowrap justify-between items-center text-[#797979] text-[13px] font-bold border-b py-[15px]">
            <div className="flex items-center gap-[10px] mb-3 sm:mb-0">
              <img
                src={require("../../assets/img/telegram-blue.png")}
                alt="telegram Logo"
              />
              <p>{t('ContactUs.پشتیبانی اول سفارشات تلگرام', 'پشتیبانی اول سفارشات تلگرام')}</p>
            </div>
            <p dir='rtl'>SGB_Sales@</p>
          </div>
          <div className="flex flex-wrap sm:flex-nowrap justify-between items-center text-[#797979] text-[13px] font-bold pt-[15px]">
            <div className="flex items-center gap-[10px] mb-3 sm:mb-0">
              <img
                src={require("../../assets/img/telegram-blue.png")}
                alt="telegram Logo"
              />
              <p>{t('ContactUs.پشتیبانی دوم سفارشات تلگرام', 'پشتیبانی دوم سفارشات تلگرام')}</p>
            </div>
            <p dir='rtl'>SGB_Sales2@</p>
          </div>
        </div>
        <div className='w-full lg:w-min'>
          <MapContainer
            className=" lg:left-1/2 lg:-translate-x-1/2 !w-full"
            center={[51.505, -0.09]}
            zoom={13}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[51.505, -0.09]}>
            </Marker>
          </MapContainer>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ContactWays);