import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import useTitle from "../hooks/useTitle";
import Navbar from "../components/Navbar";
// import { Link } from "react-router-dom";
import Google from "../assets/svg/Google";
import axios from "axios";
import Swal from "sweetalert2";
import {useCookies} from "react-cookie";
import CryptoJS from 'crypto-js';
import {PuffLoader} from "react-spinners";
import {useTranslation, withTranslation} from "react-i18next";
import i18n from "../i18n";

const Login = ({t}) => {
    useTitle(t('PageTitle.Login', 'ورود یا ایجاد حساب کاربری'))
    const {i18n} = useTranslation();
    document.body.dir = i18n.dir();

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const [loginInput, setLogin] = useState({
        email: '',
        password: '',
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();
        setLogin({...loginInput, [e.target.name]: e.target.value});
    }

    const [cookie, setCookie, removeCookie] = useCookies(['auth_token']);
    const loginSubmit = (e) => {
        e.preventDefault();

        const data = {
            email: loginInput.email,
            password: loginInput.password,
        }

        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-start',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post('/api/login', data).then(res => {
                if (res.data.status === 200) {
                    const today = new Date()
                    let expires = new Date()
                    expires.setDate(today.getDate() + 1)

                    const password = 'Sarmayegozaran@App'
                    const ApiToken = (content, password) => CryptoJS.AES.encrypt(JSON.stringify({content}), password).toString()

                    setCookie('auth_token', ApiToken(res.data.token, password), {path: '/', expires})
                    navigate(window.location.pathname);

                    // if (res.data.role === 'admin') {
                    //     navigate( i18n.language === 'en1' ? i18n.language + '/' : '/')
                    // } else {
                    //     navigate(i18n.language === 'en1' ? i18n.language + '/' : '/')
                    // }

                    Toast.fire({
                        icon: 'success',
                        title: t('Auth.شما به حساب خود وارد شدید.', 'شما به حساب خود وارد شدید.')
                    })
                    setLoading(false)
                } else if (res.data.status === 401) {
                    setLogin({
                        email: loginInput.email,
                        password: loginInput.password,
                        error_list: {
                            password: t('Auth.ایمیل یا کلمه عبور صحیح نمی باشد.', 'ایمیل یا کلمه عبور صحیح نمی باشد.')
                        }
                    })
                    // console.log(res)
                    setLoading(false)
                } else {
                    setLogin({loginInput, error_list: res.data.errors})

                    Toast.fire({
                        icon: 'error',
                        title: t('Auth.ورود به حساب انجام نشد.', 'ورود به حساب انجام نشد.')
                    })
                    setLoading(false)
                }
            }).catch((err) => {
                setLogin({
                    email: loginInput.email,
                    password: loginInput.password,
                    error_list: err.response.data.errors
                })
                Toast.fire({
                    icon: 'error',
                    title: t('Auth.اطلاعات ورود نادرست است.', 'اطلاعات ورود نادرست است.')
                })
                setLoading(false)
            })
        })
        setLoading(true)
    }

    return (
        <>
            <Navbar/>
            <div className="w-full bg-primary-bg-color overflow-hidden">
                <div className="w-full h-[248px] relative bg-secondary-bg-color mb-[410px] sm:mb-[463px] px-[25px] sm:px-[72px]">
                    <div className="w-full h-[1px] lg:bg-[#495156]"/>
                    <h2 className="text-center text-white text-[22px] sm:text-[32px] font-extrabold mt-[40px]">
                        {t('PageTitle.Login', 'ورود یا ایجاد حساب کاربری')}
                    </h2>
                    <div className="w-full md:w-[636px] absolute top-[136px] left-1/2 -translate-x-1/2 bg-white rounded-[20px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] space-y-[30px] p-[35px_25px] sm:p-[60px]">
                        <div className="w-full flex justify-center items-center bg-[#F9F9F9] rounded-[10px] gap-[12px] py-[15px]">
                            <Google/>
                            <div className="text-[#B1B1B1] text-sm font-medium">
                                {t('Auth.ورود یا ثبت نام از طریق گوگل', 'ورود یا ثبت نام از طریق گوگل')}
                            </div>
                        </div>
                        <div className="w-full h-[1px] bg-[#E0E0E0]"/>
                        <form className='relative' onSubmit={loginSubmit}>
                            {
                                loading ?
                                    <div className='loading-section rounded-[20px] top-[-10px] right-[-10px] left-[-10px] bottom-[-10px] backdrop-blur-[5px] bg-opacity-50 bg-[#fff] z-[2] absolute text-center'>
                                        <div className='transform -translate-y-1/2 top-[50%] relative inline-block'>
                                            <PuffLoader color={'#0098ff'} loading={true} size={60} aria-label="Loading Spinner" data-testid="loader"/>
                                        </div>
                                    </div>
                                    : ''
                            }
                            <div className="">
                                <div>
                                    <input name={'email'} type="email" onChange={handleInput} value={loginInput.email} placeholder={t('Auth.آدرس ایمیل', 'آدرس ایمیل')} className="w-full h-[63px] bg-[#F3F3F3] rounded-[10px] px-[20px] py-[18px]"/>
                                    <span className={'text-red-500 text-xs font-medium'}>{loginInput.error_list.email}</span>
                                </div>

                                <div className={'my-[30px]'}>
                                    <input name={'password'} type="password" placeholder={t('Auth.رمز عبور', 'رمز عبور')} onChange={handleInput} value={loginInput.password} className="w-full h-[63px] bg-[#F3F3F3] rounded-[10px] px-[20px] py-[18px]"/>
                                    <span className={'text-red-500 text-xs font-medium'}>{loginInput.error_list.password}</span>
                                </div>
                            </div>
                            <button type='submit' className="w-full h-[56px] bg-primary-btn-color text-white text-sm font-medium hover:bg-secondary-btn-color transition-all rounded-[10px] py-[15px]">
                                {t('Auth.ورود به پنل کاربری', 'ورود به پنل کاربری')}
                            </button>
                        </form>
                        <div className="text-center text-primary-btn-color text-sm font-medium">
                            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}login-phone`}>{t('Auth.ورود با شماره موبایل و رمز عبور یکبار مصرف', 'ورود با شماره موبایل و رمز عبور یکبار مصرف')}</Link>
                        </div>
                    </div>
                </div>
                <div className="text-center px-[25px] sm:px-0">
                    <button className="w-full sm:w-[636px] h-[56px] bg-[#1B262C] text-white text-sm font-medium rounded-[10px] py-[15px] mb-[97px]">
                        {t('Auth.ورود به پنل تریدینگ', 'ورود به پنل تریدینگ')}
                    </button>
                </div>
            </div>
        </>
    );
};

export default withTranslation()(Login);
