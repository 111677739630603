import React from "react";

const en = {
    'سرمایه گذار برتر': 'SGB Trading',
    'سرمایه گذاران برتر': 'SGB',
    'دلار': '$',
    PageTitle: {
        'صفحه نخست': 'Home',
        'Plans': 'View Plans',
        'Rules': 'Site rules and regulations',
        'AboutUs': 'Learn more about SGB Company',
        'ContactUs': 'Contact Us',
        'Blog': 'Blog',
        'Login': 'Login Or Register',
        'Careers': 'Cooperation',
        'Buy': 'Buying a plan',
        'ThankYou': 'Thank You',
    },
    Navs: {
        'صفحه نخست': 'Home',
        'پلن ها': 'Plans',
        'قوانین': 'Rules',
        'درباره ما': 'About US',
        'تماس با ما': 'Contact US',
        'بلاگ': 'Blog',
        'استخدام': 'Careers',
        'لیدر بورد': 'Leader Board',
        'ورود / ثبت نام': 'Login / Register',
        'خروج از حساب': 'Logout',
        'شما از حساب خود خارج شدید.': 'You are logged out of your account.',
    },
    Footer: {
        'FooterAboutText': 'Lorem Epsom is a fake text with simple production in the incomprehensible of the printing industry, and using graphic designers, printers and texts, but also newspapers and magazines in columns and lines as necessary, and for the current conditions of the technology...',
        'نشان های اعتماد': 'Signs of trust',

        'با سرمایه گذار برتر': 'With SGB',
        'لینک های کاربردی': 'Useful links',
        'دسترسی سریع': 'quick access',

        'وبلاگ سرمایه گذار برتر': 'Blog',
        'همکاری با سرمایه گذار برتر': 'Cooperation with SGB',
        'درباره سرمایه گذار برتر': 'About SGB',
        'تماس با سرمایه گذار برتر': 'Contact With SGB',
        'ورود و ثبت نام': 'Login / Register',
        'تعرفه ها': 'Plans',
        'کانال تلگرام': 'Telegram channel',
        'جستجو در سایت': 'Search in site',
        'سوالات متداول': 'FAQ',
        'استخدام شوید': 'Careers',
        'قوانین و مقررات': 'Terms and Conditions',
        'ثبت شکایات': 'Register complaints',

        'همراه ما باشید!': 'Follow on Socials!',
        'تلفن مشاوره': 'consultancy',

        'copyright': 'This website belongs to SGB Group and all its rights are reserved.',
    },
    FastLogin: {
        "ثبت نام رایگان": 'Free registration',
        "در کمتر از ۳۰ ثانیه!": 'In less than 30 seconds',
        "Desc": 'SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.',
        "آدرس ایمیل": 'Your Email',
        "رمز عبور": 'Your Password',
        "قبلا عضو شدم": 'I already registered',
        "ثبت نام": 'Register',
    },
    HomePage: {
        'تامین سرمایه معامله گران': 'Funding of investors',
        'در بازارهای مالی': 'in financial markets',
        'desc': 'SGB is formed in order to provide traders with capital for trading in financial markets with minimum possible risk and in this way provides services to traders.',
        'مشاهده پلن ها': 'View plans',
        'درباره سرمایه گذار برتر': 'About SGB',
        'underButtons': '<1>Discount code: oneyearSGB</1> until October 25',
        'ویژگی های متمایز': 'Distinctive features',
        'سرمایه گذار برتر': 'SGB',
        'شفافیت و اعتبار در ترید': 'Transparency and\n credibility in trade',
        'ترید در برترین بروکرهای دنیا': 'Trade in the best brokers\n in the world',
        'با سابقه ترین پراپ تریدینگ خاورمیانه': 'The oldest prop trading\n in the Middle East',
        'سریع ترین سیستم پرداخت سود': 'The fastest interest\n payment system',
        'درباره شرکت': 'About',
        'AboutUsDesc': 'SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.',
        'بیشتر بخوانید': 'Read More',
        "پلن های سرمایه گذار برتر": "<1>SGB Trading</1><0>PLANs</0>",
        "PlansDesc": "SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.",
        'انتخاب مقدار': 'Balance:',
        'توضیحات پلن': 'PLANS',
        'برای ترید آماده هستید؟': 'Are you ready to trade?',
        'ثبت سفارش پلن': 'Plan Order',
        'دوره سرمایه گذاری': 'Investment period',
        'حداقل روزهای معاملاتی': 'Minimum trading days',
        'حداکثر ضرر روزانه': 'Maximum daily loss',
        'حداکثر ضرر': 'Maximum loss',
        'هدف سود': 'Profit target',
        'هزینه قابل استرداد': 'Refundable fee',
        'مرحله اول': 'First level',
        'مرحله دوم': 'Second level',
        'مرحله سوم': 'Third level',
        'چالش سرمایه': 'Capital challenge',
        'احراز هویت': 'Authentication',
        'ترید با سرمایه': 'Trade with capital',
        'روزه': 'Days',
        "CustomerStatics": "Customer statistics <1>(2022)</1>",
        "تعداد ثبت نامی ها": "Number of registrations",
        "بزرگترین پرداخت سود": "Largest interest payment",
        "مجموع پرداخت سود": "Total interest payment",
        "همین حالا به مشتریان ما بپیوندید": "Join our customers now",
        "دیدگاه مشتریان": "Opinion <1>About us</1>",
        "ArrowVideo": "<2></2><1></1><0></0>",
        "محبوب ترین": "Most Popular",
        "پلتفرم های ترید": "Trading platforms",
        "PopularPlatformDesc": "SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.",
        "پنل حرفه ای": "Professional panel",
        "مخصوص تریدر ها": "For traders",
        "TraderPanelPosterDesc": "SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.",
        "ورود به پنل": "Login to panel",
        "سوالات متداول": "<1>Frequently</1> Asked Questions",
        "سوالات بیشتر": "More questions",
        "q1": "Why should we use SGB?",
        "q2": "Why should we use SGB?",
        "q3": "Why should we use SGB?",
        "q4": "Why should we use SGB?",
        "Answer1": "SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.",
        "مراحل کار با سایت": "Steps to work with",
        "مرحله": "Step",
        "اول": "First",
        "دوم": "Second",
        "سوم": "Third",
        "مرحله ثبت نام": "Registration step",
        "مرحله مهر تایید": "Approval stamp step",
        "مرحله لایو ترید": "Live trade step",
        "Step1Text": "SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in ",
        "Step2Text": "SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in ",
        "Step3Text": "SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in ",
        'مطالب بلاک': 'Blog <1>Posts</1>',
        'مطالب بیشتر': 'More posts',
        'ادامه مطلب': 'Read more',
    },
    PlansPage: {
        'PageDesc': 'We do not set any limit on the size you trade',
        'مزایای پلن های سرمایه گذار برتر': 'We do not set any limit on the size you trade',
        'پایین ترین اهداف در بازار!': 'The lowest targets in the market!',
        'TabDesc': 'Top Investor is formed in order to provide traders with capital for trading in financial markets with minimum possible risk and in this way provides services to traders. Top Investor is to provide traders with capital for trading.',
        PlansTable: {
            'پلن ها': 'Plans <1>(one time payment)</1>',
            'تارگت مرحله اول': 'first level target',
            'تارگت مرحله دوم': 'Second level target',
            'رشد سرمایه': 'Capital Growth',
            'تا سقف 1,000,000$': 'Up to 1,000,000 $',
        }
    },
    RulesPage: {
        'قوانین و مقررات': '<1>Site</1> rules and regulations',
        'RulesDesc': 'Before registering on the site and using the platform, these rules must be read...',
        'SearchPlaceholder': 'Keyword search among rules and regulations...',
        'PageDescUnderSearch': '<0>The trader must trade two steps in the demo account and reach the upcoming targets without violating the rules.</0><1>After completing the order, traders will participate in the challenge. The trader must reach the target steps in Demo accounts, following the rules.</1>',
        'قوانین کلی': '<1>General</1> rules of the site',
        'بررسی کامل قوانین': '<1>Full review</1> of the rules',
        'rulesCat1': "Fraud rules",
        'rulesCat2': "Unauthorized rules",
        'rulesCat3': "Instruments rules",
        'rulesCat4': "Capital growth rules",
        'rulesCat5': "Fraud rules",
        'rulesCat6': "Unauthorized rules",
        'rulesCat7': "Payment rules",

        'Question1': 'Why should we use SGB?',
        'Question2': 'Why should we use SGB?',
        'Question3': 'Why should we use SGB?',
        'Question4': 'Why should we use SGB?',
        'Question5': 'Why should we use SGB?',
        'Question6': 'Why should we use SGB?',
        'Question7': 'Why should we use SGB?',
        'Question8': 'Why should we use SGB?',
        'Question9': 'Why should we use SGB?',
        'Question10': 'Why should we use SGB?',
        'Answer1': 'SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.',
        'Answer2': 'SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.',
        'Answer3': 'SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.',
        'Answer4': 'SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.',
        'Answer5': 'SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.',
        'Answer6': 'SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.',
        'Answer7': 'SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.',
        'Answer8': 'SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.',
        'Answer9': 'SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.',
        'Answer10': 'SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.SGB in providing investment funds for investing in financial markets with minimum cost may be formed and in this way it is offered to traders.',
    },
    AboutUs: {
        'AboutUsDesc': 'The first capital supply company for traders',
        // 'AboutUsDesc': 'The first capital supply company for traders in Iran',
        'IntroTitle': 'The main goals of the SGB company',
        'IntroDesc': 'So far, many traders have used our services, and their number is increasing rapidly. The main goal of the company is to create a safe and free platform for successful traders to be on the right path for their financial independence. The company\'s activity started in September 1400 and after some time, it officially started expanding its services nationwide. One of the most important goals of the company is to provide international services, which is being reviewed by the team and experts, and according to recent estimates, this project will be keyed soon.',
        'دلایل تشکیل': 'Reasons for formation',
        'سرمایه گذار برتر': 'SGB',
        'تامین سرمایه معامله گران فعال در بازار سرمایه': 'Providing capital for active traders in the capital market',
        // 'کاهش خطرات تحریم بر کارکرد': 'Reducing sanctions risks on the function',
        'کاهش خطرات تحریم بر کارکرد': 'User information \n security',
        'کاهش کلاهبرداری های اینترنتی': 'Reducing \n Internet fraud',
        'Question1': 'The maximum amount of capital received from the top investor',
        'Question2': 'The maximum amount of capital received from the top investor',
        'Answer1': 'Top Investor is formed in order to provide traders with capital for trading in financial markets with minimum possible risk and in this way provides services to traders. Top Investor is in line to provide traders with capital for trading. It is formed for trading in the financial markets with the least possible risk and in this way it provides services to the traders as the best investor in the direction of providing traders with capital for trading.',
        'Answer2': 'Top Investor is formed in order to provide traders with capital for trading in financial markets with minimum possible risk and in this way provides services to traders. Top Investor is in line to provide traders with capital for trading. It is formed for trading in the financial markets with the least possible risk and in this way it provides services to the traders as the best investor in the direction of providing traders with capital for trading.',
    },
    ContactUs: {
        'تماس با سرمایه گذار برتر': 'Contact with SGB',
        'PageDesc': 'If you have any questions, you can contact us through the following communication channels',
        'فرم ارتباط با': 'Contact us',
        'سرمایه گذار برتر': 'Form',
        'نام شما': 'Your Name',
        'آدرس ایمیل': 'Email Address',
        'شماره تماس': 'Phone number',
        'موضوع پیام': 'Message Subject',
        'پیام شما': 'Your message',
        'ارسال پیام': 'Send',
        'درخواست شما ثبت شد.': 'Your request has been registered',
        'به زودی با شما تماس خواهیم گرفت.': 'We will contact you soon',
        'درخواست شما ثبت نشد.': 'Your request was not registered.',
        'پشتیبانان تلگرام سرمایه گذار برتر': 'Your request was not registered.',
        'تلگرام پشتیبانان': 'SGB <1>Telegram</1> supporters',
        'پشتیبان شماره 1': 'Support number 1',
        'پشتیبان شماره 2': 'Support number 2',
        'پشتیبان شماره 3': 'Support number 3',
        'پشتیبان شماره 4': 'Support number 4',
        'پشتیبان شماره 5': 'Support number 5',
        'پشتیبان شماره 6': 'Support number 6',

        'راه های ارتباط با': 'Ways to communicate with',
        'دفتر مرکزی': 'Central office',
        'ایمیل': 'Email',
        'تماس تایم اداری': 'Office time call',
        'پشتیبانی اول سفارشات تلگرام': 'First support for Telegram orders',
        'پشتیبانی دوم سفارشات تلگرام': 'second support for Telegram orders',
    },
    Blog: {
        'BlogTitle': '<1>Blog</1> posts Archive',
        'BlogDesc': 'A source of educational materials about forex, investment and forex news...',
        'SearchPlaceHolder': 'Keyword search among the content published in the blog...',
        'دسته بندی مطالب': 'Blog category',
        'Category1': 'Fraud rules',
        'Category2': 'Unauthorized rules',
        'Category3': 'Instruments rules',
        'Category4': 'Capital growth rules',
        'Category5': 'Fraud rules',
        'Category6': 'Unauthorized rules',
        'Category7': 'Payment rules',
        'RelatedPost': 'Suggested <1>Posts</1>',
        'اشتراک گذاری در شبکه های اجتماعی': 'Sharing on social networks',
        'صفحه اصلی': 'Home',
        'آرشیو مطالب': 'Blog archive',
        'نویسنده': 'Author',
        'تاریخ انتشار مطلب': 'Publication date',
        'دسته بندی': 'Category',
    },
    Auth: {
        'ورود یا ثبت نام از طریق گوگل': 'Log in or register via Google',
        'آدرس ایمیل': 'Enter your Email Address',
        'رمز عبور': 'Enter your Password',
        'ورود به پنل کاربری': 'Login to the panel',
        'ورود با شماره موبایل و رمز عبور یکبار مصرف': 'Login with mobile number and one-time password',
        'ورود به پنل تریدینگ': 'Login to trading panel',
        'شما به حساب خود وارد شدید.': 'You are logged into your account.',
        'ایمیل یا کلمه عبور صحیح نمی باشد.': 'Email or password is not correct.',
        'ورود به حساب انجام نشد.': 'Login failed.',
        'اطلاعات ورود نادرست است.': 'Login information is incorrect.',
    },
    Careers: {
        'Title': 'Cooperation with <1>SGB</1>',
        'Desc': 'If you are looking for job security, you can work with us',
        'SearchPlaceHolder': 'Keyword search among job positions...',
        'صفحه اصلی': 'Home',
        'همکاری با سرمایه گذار برتر': 'Cooperation with SGB',
        'جنسیت': 'Gender',
        'ساعت کاری': 'Gender',
        'موقعیت': 'Gender',
        'گروه شغلی': 'Gender',
        'درخواست شما ثبت نشد.': 'Your request was not registered.',
        'رزومه شما ارسال شد': 'Your resume has been sent',
        'کارشناسان ما به زودی با شما تماس خواهیم گرفت.': 'Your resume has been sent',
        'ارسال رزومه': 'Send Resume',
        'نام و نام خانوادگی': 'First name and last name',
        'ایمیل': 'Email',
        'موبایل': 'Phone number',
        'انتخاب فایل رزومه': 'Choose resume file',
    },
    Buy: {
        'تنظیمات پلن': 'Plan settings',
        'پرداخت': 'Payment',
        'اتمام خرید': 'Check out',
        'جزئیات صورتحساب': 'Billing details',
        'پلتفرم موردنظر را انتخاب کنید': 'Select the desired platform',
        'بروکر موردنظر را انتخاب کنید': 'Select the desired broker',
        'نوع حساب موردنظر را انتخاب کنید': 'Select the desired account type',
        'BrokerRules': 'The broker in question has been tested by the trader and Top Investor has no responsibility for it. *',
        'BuyRules': 'I read the rules and accept the terms of the plans. *',
        'تومان': '',
        'جمع کل': 'Total Amount',
        'مبلغ پکیج': 'Package amount',
        'مبلغ تخفیف': 'The amount of discount',
        'پرداخت با زرین پال': 'Payment with Visa card or Master card',
        'کد تخفیف خود را وارد کنید': 'Enter your discount code',
        'ثبت': 'Submit',
        'روند خرید پلن': 'The process of buying a plan',
        'تبریک': 'Congratulations',
        'خرید شما موفقیت آمیز بود': 'Your purchase was successful',
        'از این پس می توانید با ورود به پنل کاربری معاملات خود را انجام دهید': 'From now on, you can do your transactions by entering the user panel',
        'ورود به پنل کاربری': 'Login to the user panel',
    },
}

export default en