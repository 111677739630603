import ThankYou from "../../assets/svg/ThankYou";
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";
import i18n from "../../i18n";

const InfoHeader = ({t}) => {
    return (
        <div className="px-[25px] sm:px-[72px]">
            <div className="gap-6 bg-white w-full mt-[20px] mx-auto">
                <div className="w-full h-max bg-white rounded-[20px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] p-[30px] pb-[40px] mb-[70px]">
                    <div className='w-full font-bold text-[#797979] text-[18px] border-b border-[#ECECEC] mb-[30px] pb-[30px]'>{t('Buy.اتمام خرید', 'اتمام خرید')}</div>

                    <div className='text-center'>
                        <span className='inline-block mb-[20px] md:mb-[30px]'><ThankYou /></span>
                        <div className='font-bold text-[22px] md:text-[34px] leading-[40px]'>{t('Buy.تبریک', 'تبریک')}!</div>
                        <div className='font-[100] text-[20px] sm:text-[22px] md:text-[34px] leading-[40px] mb-[10px]'>{t('Buy.خرید شما موفقیت آمیز بود', 'خرید شما موفقیت آمیز بود')}</div>
                        <div className='font-[800] text-[14px] md:text-[16px] text-[#717171] mb-[30px]'>{t('Buy.از این پس می توانید با ورود به پنل کاربری معاملات خود را انجام دهید', 'از این پس می توانید با ورود به پنل کاربری معاملات خود را انجام دهید')}</div>
                        <span className='inline-block'>
                            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}`} className='bg-primary-btn-color text-[#fff] px-[45px] sm:px-[72px] md:px-[147px] leading-[40px] inline-block py-[11px] rounded-[10px]'>{t('Buy.ورود به پنل کاربری', 'ورود به پنل کاربری')}</Link>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(InfoHeader)