import AuthInfo from "./AuthInfo";

const SidePanel = () => {


    return (
        <div className='flex-none flex-auto bg-white w-[306px] p-[20px] pt-[30px] rounded-[20px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)]'>
            <AuthInfo />
        </div>
    )
}

export default SidePanel