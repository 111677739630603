export default () => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0625 29.1667H8.33334C6.04167 29.1667 4.16667 31.0417 4.16667 33.3334V45.8334H18.0625V29.1667Z"
      stroke="#C8CBCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.7709 20.8333H22.2083C19.9167 20.8333 18.0417 22.7083 18.0417 24.9999V45.8333H31.9375V24.9999C31.9375 22.7083 30.0834 20.8333 27.7709 20.8333Z"
      stroke="#C8CBCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.6667 35.4167H31.9375V45.8334H45.8333V39.5834C45.8333 37.2917 43.9583 35.4167 41.6667 35.4167Z"
      stroke="#C8CBCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.0833 4.31262L27.1875 6.52095C27.3333 6.83345 27.7292 7.12511 28.0625 7.16678L30.0625 7.50012C31.3333 7.70845 31.6458 8.64591 30.7292 9.54174L29.1667 11.1042C28.8958 11.3751 28.75 11.8751 28.8333 12.2293L29.2708 14.146C29.625 15.6668 28.8125 16.2501 27.4792 15.4585L25.6042 14.3543C25.2708 14.146 24.7083 14.146 24.375 14.3543L22.5 15.4585C21.1667 16.2501 20.3542 15.6668 20.7083 14.146L21.1458 12.2293C21.2292 11.8751 21.0833 11.3542 20.8125 11.1042L19.2708 9.5626C18.3542 8.64593 18.6458 7.72924 19.9375 7.52091L21.9375 7.18763C22.2708 7.12513 22.6667 6.83347 22.8125 6.5418L23.9167 4.33341C24.5208 3.12508 25.4792 3.12512 26.0833 4.31262Z"
      stroke="#0098FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
