import React from "react";
import {Link} from "react-router-dom";
import {Trans, withTranslation} from "react-i18next";
import i18next from "i18next";
import i18n from "i18next";

const SupportPosters = ({t}) => {


    return (
        <div className="w-full px-0 sm:px-[72px]">
            <div className="w-full bg-white rounded-[20px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] p-[40px_20px_20px] sm:p-[40px] lg:p-[40px_90px] pb-[20px] md:pb-[40px] mb-[38px]">
                <div className="text-center text-secondary-bg-color text-[22px] font-light mb-[30px]">
                    <Trans i18nKey='ContactUs.تلگرام پشتیبانان'> پشتیبانان <span className="font-extrabold">تلگرام</span> سرمایه گذار برتر </Trans>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 item items-center mb-[30px] gap-[24px]">
                    <div className={`h-[58px] relative from-[rgba(236,236,236,1)] to-[rgba(236,236,236,0)] ${i18next.language === 'fa' ? 'bg-gradient-to-l' : 'bg-gradient-to-r'} rounded-[10px] p-[1px]`}>
                        <div className="w-full h-full flex items-center bg-white rounded-[10px] px-[19px] py-[17px] gap-[14px]">
                            <div className="text-primary-btn-color text-[12px] sm:text-sm font-extrabold whitespace-nowrap">
                                {t('ContactUs.پشتیبان شماره 1', 'پشتیبان شماره 1')}
                            </div>
                            <div className="text-[#BBBBBB] text-[10px] sm:text-xs font-bold" dir='rtl'>
                                SGB_TECHNICALSUPPORT@
                            </div>
                            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}`} className={`w-[42px] sm:w-[52px] h-[40px] sm:h-[50px] absolute ${i18next.language === 'fa' ? 'left-0' : 'right-0'} flex flex-col justify-center bg-primary-btn-color rounded-[10px] shadow-[0px_10px_15px_rgba(0,152,255,0.4)]`}> <img className="w-[24px] mx-auto" src={require("../../assets/img/Telegram.png")} alt="Telegram"/> </Link>
                        </div>
                    </div>
                    <div className={`h-[58px] relative from-[rgba(236,236,236,1)] to-[rgba(236,236,236,0)] ${i18next.language === 'fa' ? 'bg-gradient-to-l' : 'bg-gradient-to-r'} rounded-[10px] p-[1px]`}>
                        <div className="w-full h-full flex items-center bg-white rounded-[10px] px-[19px] py-[17px] gap-[14px]">
                            <div className="text-primary-btn-color text-[12px] sm:text-sm font-extrabold whitespace-nowrap">
                                {t('ContactUs.پشتیبان شماره 2', 'پشتیبان شماره 2')}
                            </div>
                            <div className="text-[#BBBBBB] text-[10px] sm:text-xs font-bold" dir='rtl'>
                                SGB_TECHNICALSUPPORT@
                            </div>
                            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}`} className={`w-[42px] sm:w-[52px] h-[40px] sm:h-[50px] absolute ${i18next.language === 'fa' ? 'left-0' : 'right-0'} flex flex-col justify-center bg-primary-btn-color rounded-[10px] shadow-[0px_10px_15px_rgba(0,152,255,0.4)]`}> <img className="w-[24px] mx-auto" src={require("../../assets/img/Telegram.png")} alt="Telegram"/> </Link>
                        </div>
                    </div>
                    <div className={`h-[58px] relative from-[rgba(236,236,236,1)] to-[rgba(236,236,236,0)] ${i18next.language === 'fa' ? 'bg-gradient-to-l' : 'bg-gradient-to-r'} rounded-[10px] p-[1px]`}>
                        <div className="w-full h-full flex items-center bg-white rounded-[10px] px-[19px] py-[17px] gap-[14px]">
                            <div className="text-primary-btn-color text-[12px] sm:text-sm font-extrabold whitespace-nowrap">
                                {t('ContactUs.پشتیبان شماره 3', 'پشتیبان شماره 3')}
                            </div>
                            <div className="text-[#BBBBBB] text-[10px] sm:text-xs font-bold" dir='rtl'>
                                SGB_TECHNICALSUPPORT@
                            </div>
                            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}`} className={`w-[42px] sm:w-[52px] h-[40px] sm:h-[50px] absolute ${i18next.language === 'fa' ? 'left-0' : 'right-0'} flex flex-col justify-center bg-primary-btn-color rounded-[10px] shadow-[0px_10px_15px_rgba(0,152,255,0.4)]`}> <img className="w-[24px] mx-auto" src={require("../../assets/img/Telegram.png")} alt="Telegram"/> </Link>
                        </div>
                    </div>
                    <div className={`h-[58px] relative from-[rgba(236,236,236,1)] to-[rgba(236,236,236,0)] ${i18next.language === 'fa' ? 'bg-gradient-to-l' : 'bg-gradient-to-r'} rounded-[10px] p-[1px]`}>
                        <div className="w-full h-full flex items-center bg-white rounded-[10px] px-[19px] py-[17px] gap-[14px]">
                            <div className="text-primary-btn-color text-[12px] sm:text-sm font-extrabold whitespace-nowrap">
                                {t('ContactUs.پشتیبان شماره 4', 'پشتیبان شماره 4')}
                            </div>
                            <div className="text-[#BBBBBB] text-[10px] sm:text-xs font-bold" dir='rtl'>
                                SGB_TECHNICALSUPPORT@
                            </div>
                            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}`} className={`w-[42px] sm:w-[52px] h-[40px] sm:h-[50px] absolute ${i18next.language === 'fa' ? 'left-0' : 'right-0'} flex flex-col justify-center bg-primary-btn-color rounded-[10px] shadow-[0px_10px_15px_rgba(0,152,255,0.4)]`}> <img className="w-[24px] mx-auto" src={require("../../assets/img/Telegram.png")} alt="Telegram"/> </Link>
                        </div>
                    </div>
                    <div className={`h-[58px] relative from-[rgba(236,236,236,1)] to-[rgba(236,236,236,0)] ${i18next.language === 'fa' ? 'bg-gradient-to-l' : 'bg-gradient-to-r'} rounded-[10px] p-[1px]`}>
                        <div className="w-full h-full flex items-center bg-white rounded-[10px] px-[19px] py-[17px] gap-[14px]">
                            <div className="text-primary-btn-color text-[12px] sm:text-sm font-extrabold whitespace-nowrap">
                                {t('ContactUs.پشتیبان شماره 5', 'پشتیبان شماره 5')}
                            </div>
                            <div className="text-[#BBBBBB] text-[10px] sm:text-xs font-bold" dir='rtl'>
                                SGB_TECHNICALSUPPORT@
                            </div>
                            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}`} className={`w-[42px] sm:w-[52px] h-[40px] sm:h-[50px] absolute ${i18next.language === 'fa' ? 'left-0' : 'right-0'} flex flex-col justify-center bg-primary-btn-color rounded-[10px] shadow-[0px_10px_15px_rgba(0,152,255,0.4)]`}> <img className="w-[24px] mx-auto" src={require("../../assets/img/Telegram.png")} alt="Telegram"/> </Link>
                        </div>
                    </div>
                    <div className={`h-[58px] relative from-[rgba(236,236,236,1)] to-[rgba(236,236,236,0)] ${i18next.language === 'fa' ? 'bg-gradient-to-l' : 'bg-gradient-to-r'} rounded-[10px] p-[1px]`}>
                        <div className="w-full h-full flex items-center bg-white rounded-[10px] px-[19px] py-[17px] gap-[14px]">
                            <div className="text-primary-btn-color text-[12px] sm:text-sm font-extrabold whitespace-nowrap">
                                {t('ContactUs.پشتیبان شماره 6', 'پشتیبان شماره 6')}
                            </div>
                            <div className="text-[#BBBBBB] text-[10px] sm:text-xs font-bold" dir='rtl'>
                                SGB_TECHNICALSUPPORT@
                            </div>
                            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}`} className={`w-[42px] sm:w-[52px] h-[40px] sm:h-[50px] absolute ${i18next.language === 'fa' ? 'left-0' : 'right-0'} flex flex-col justify-center bg-primary-btn-color rounded-[10px] shadow-[0px_10px_15px_rgba(0,152,255,0.4)]`}> <img className="w-[24px] mx-auto" src={require("../../assets/img/Telegram.png")} alt="Telegram"/> </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="grid grid-cols-1 md:grid-cols-2 mb-[62px]">
                <div>
                    <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}`}> <img className="w-full col-span-1" src={i18next.language === 'fa' ? require("../../assets/img/joinTelegram1.png") : require("../../assets/img/en/joinTelegram1.png")} alt="joinTelegram1"/> </Link>
                </div>
                <div>
                    <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}`}> <img className="w-full col-span-1" src={i18next.language === 'fa' ? require("../../assets/img/joinTelegram2.png") : require("../../assets/img/en/joinTelegram2.png")} alt="joinTelegram2"/> </Link>
                </div>
            </div> */}
        </div>
    );
};

export default withTranslation()(SupportPosters)