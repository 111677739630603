import React from "react";
import ReactDOM from "react-dom/client";
import "./index.min.css";
import reportWebVitals from "./reportWebVitals";
import './i18n'
import MyRoutes from "./router/MyRoutes";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <MyRoutes/>
);

reportWebVitals();
