import React, {useEffect, useState} from "react";
import axios from "axios";
import BlogCard from "../BlogCard";
import {useNavigate} from "react-router-dom";
import {ClipLoader} from "react-spinners";
import "swiper/css";
import "swiper/css/navigation";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import {Trans, withTranslation} from "react-i18next";

const BlogContent = ({t}) => {
    const [articleData, setArticleData] = useState("")
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchArticle()
        setLoading(true)
    }, [])

    const fetchArticle = async () => {
        axios.get(`/api/4/blog`)
            .then(res => {
                const Articles = res.data.data;
                setArticleData(Articles);
                setLoading(false)
            })
    }

    if (loading) {
        return (
            <div className='text-center py-[120px] bg-primary-bg-color'>
                <ClipLoader color={'#0098ff'} loading={loading} size={60} aria-label="Loading Spinner" data-testid="loader"/>
            </div>
        )
    }
    if (articleData != 0) {
        return (
            <>
                <section className="w-full mt-[72px] px-[25px] sm:px-[72px]">
                    <div className="flex justify-center sm:justify-between items-center gap-[40px] mb-0 sm:mb-[23px]">
                        <p className="text-[22px] sm:text-[34px] font-extrabold whitespace-nowrap">
                            <Trans i18nKey='HomePage.مطالب بلاک'>
                                مطالب <span className="font-light">بلاگ</span>
                            </Trans>
                        </p>
                        <div className="hidden sm:block w-full h-[1px] bg-[#E8E8E8]"/>
                        <button onClick={() => navigate("/blog")} className="hidden sm:block text-[#C7C7C7] border hover:text-primary-btn-color hover:border-primary-btn-color transition-all rounded-[10px] whitespace-nowrap px-[30px] py-[18px]"> {t('HomePage.مطالب بیشتر','مطالب بیشتر')}</button>
                    </div>



                    <Swiper
                        dir="rtl"
                        navigation={true}
                        loop={true}
                        slidesPerView={1}
                        spaceBetween={30}
                        modules={[Navigation]}
                        className="w-full bg-primary-bg-color py-10"
                        breakpoints={{
                            1280: {
                                slidesPerView: 4,
                            },
                            1024: {
                                slidesPerView: 3,
                            },
                            767: {
                                slidesPerView: 2,
                            },
                        }}
                    >
                        {
                            articleData ?
                                articleData.map((item) =>
                                    <SwiperSlide key={item.id} className='my-[50px]'>
                                        <BlogCard title={item.title} desc={item.desc} thumbnail={item.thumbnail_tbl} slug={item.slug}/>
                                    </SwiperSlide>
                                ) : null
                        }
                    </Swiper>
                </section>
            </>
        )
    }

}
export default withTranslation()(BlogContent);
