import React from "react";
import {useNavigate} from "react-router-dom";

const CareerCard = (props) => {
    const navigate = useNavigate();

    return (
        <div onClick={() => navigate(props.slug)} className='w-full bg-white rounded-[20px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] p-[20px] sm:p-[30px_40px] cursor-pointer relative card-scale-anime'>
            <p className='text-secondary-bg-color text-[14px] sm:text-[20px] text-[#0F4C75] font-extrabold mb-[15px]'>{ props.title }</p>
            <div className='flex flex-row'>
                <div className='basis-1/2 text-[12px] sm:text-[16px] text-[#767D80] font-medium'>{props.location}</div>
                <div className='basis-1/2 text-end'><span className='bg-[#E8E9EA] p-[4px_26px] text-[12px] sm:text-[16px] rounded-[10px] text-[#1B262C] font-medium'>{props.cat}</span></div>
            </div>
        </div>
    )
}

export default CareerCard;