export default () => (
  <svg
    width="300"
    height="206"
    viewBox="0 0 300 206"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M-49.5374 205.448C-52.8617 205.454 -56.1826 205.237 -59.4779 204.799C-86.5746 201.185 -111.163 182.957 -134.715 149.066L-135 148.656L-134.624 148.325L18.0734 11.7926L18.4268 12.374C25.7111 23.9217 33.3375 30.3739 41.7617 32.0838C54.8257 34.7399 68.2543 25.4037 81.2385 16.3752C94.4164 7.25555 108.05 -2.27449 121.593 0.484205C130.302 2.25114 138.157 8.82869 145.589 20.593L206.178 57.653C211.651 57.195 217.159 57.3249 222.605 58.0406C249.701 61.6543 274.29 79.8822 297.842 113.773L299.381 115.996L205.904 58.793C164.501 62.3383 122.607 97.5515 81.9338 131.819C38.8776 168.081 -5.53508 205.448 -49.5374 205.448ZM-133.45 148.85C-110.229 182.193 -85.9819 200.148 -59.3525 203.682C-12.6142 209.906 35.0702 169.802 81.1701 130.998C121.49 97.0842 163.008 62.1559 204.285 57.8582L144.768 21.4366L144.7 21.3226C137.416 9.77485 129.789 3.3113 121.319 1.60137C108.255 -1.05473 94.8268 8.28151 81.8426 17.31C68.6647 26.4296 55.0423 35.9597 41.4882 33.201C32.8815 31.4568 25.1298 25.0275 17.777 13.5481L-133.45 148.85ZM207.853 58.7018L295.288 112.2C272.489 80.0304 248.607 62.6803 222.456 59.192C217.616 58.5581 212.726 58.3901 207.853 58.6904V58.7018Z"
      fill="#F0F0F0"
    />
    <path
      d="M-33.5666 176.79C-36.617 176.791 -39.6639 176.586 -42.6863 176.174C-66.671 172.88 -88.4328 156.601 -109.317 126.392L-108.371 125.742C-87.7374 155.666 -66.1922 171.797 -42.4811 175.046C-1.35144 180.677 40.6104 145.714 81.1928 111.892C121.981 77.9098 164.148 42.8333 205.767 48.5331C229.798 51.8276 251.605 68.1061 272.443 98.315L271.497 98.9648C250.864 69.0295 229.319 52.9105 205.608 49.6616C164.49 44.0303 122.516 78.9927 81.9338 112.815C44.11 144.289 5.10072 176.79 -33.5666 176.79Z"
      fill="#F0F0F0"
    />
    <path
      d="M-17.6072 148.131C-20.3458 148.13 -23.0809 147.936 -25.7921 147.55C-46.7787 144.575 -65.7932 130.234 -83.9299 103.707L-82.9952 103.069C-65.0294 129.288 -46.2544 143.492 -25.6097 146.387C9.89993 151.426 46.2076 121.604 81.227 92.8091C116.486 63.7972 152.942 33.8163 188.942 38.9233C209.928 41.8986 228.943 56.2392 247.079 82.766L246.145 83.4044C228.179 57.1854 209.404 42.9815 188.759 40.086C153.25 35.0474 116.942 64.8687 81.9224 93.664C49.3424 120.476 15.7251 148.131 -17.6072 148.131Z"
      fill="#F0F0F0"
    />
    <path
      d="M-1.62505 119.473C-4.07827 119.476 -6.52834 119.297 -8.95497 118.937C-26.8751 116.27 -43.1537 103.867 -58.6001 81.0336L-57.6539 80.4522C-42.3557 103.012 -26.3735 115.255 -8.78398 117.866C21.1057 122.323 51.6337 97.6314 81.2042 73.7493C110.946 49.719 141.702 24.8679 172.082 29.3935C190.002 32.061 206.28 44.4524 221.727 67.2971L220.781 67.9355C205.482 45.3757 189.5 33.1326 171.911 30.5221C142.021 26.0763 111.493 50.7563 81.9224 74.6384C54.5748 96.6738 26.3723 119.473 -1.62505 119.473Z"
      fill="#F0F0F0"
    />
    <path
      d="M14.3572 90.8368C12.189 90.8403 10.024 90.6726 7.88222 90.3353C-6.93723 87.9756 -20.4343 77.5107 -33.2474 58.3481L-32.3013 57.7097C-19.6705 76.5988 -6.46986 86.904 8.0646 89.2067C32.3457 93.0484 57.1854 73.4981 81.2157 54.5862C105.428 35.5261 130.473 15.8277 155.244 19.7377C170.064 22.0974 183.561 32.5622 196.374 51.7249L195.417 52.3633C182.797 33.4742 169.597 23.169 155.062 20.8663C130.792 17.0246 105.941 36.5749 81.911 55.4868C59.7617 72.8939 36.9625 90.8368 14.3572 90.8368Z"
      fill="#F0F0F0"
    />
    <path
      d="M30.3622 62.2127C28.4515 62.217 26.5442 62.0529 24.6624 61.7225C12.8524 59.6706 2.18244 51.1323 -7.95178 35.6403L-6.99422 35.0133C2.9576 50.2317 13.3768 58.599 24.8562 60.6053C43.5287 63.8542 62.6572 49.4223 81.1587 35.4579C99.854 21.3566 119.188 6.76516 138.35 10.1052C150.16 12.1686 160.83 20.6954 170.953 36.1874L170.007 36.8144C160.055 21.6074 149.636 13.2287 138.156 11.2338C119.473 7.97351 100.356 22.4168 81.854 36.3128C65.0397 49.1146 47.6667 62.2127 30.3622 62.2127Z"
      fill="#F0F0F0"
    />
    <path
      d="M124.24 1.17424L123.679 2.1665L225.118 59.5348L225.679 58.5425L124.24 1.17424Z"
      fill="#F0F0F0"
    />
    <path
      d="M103.227 3.5348L102.291 4.18457L153.012 77.2988L153.948 76.6491L103.227 3.5348Z"
      fill="#F0F0F0"
    />
    <path
      d="M82.1389 16.8423H80.9989V131.431H82.1389V16.8423Z"
      fill="#F0F0F0"
    />
    <path
      d="M59.756 29.58L9.12903 185.672L10.2134 186.024L60.8404 29.9317L59.756 29.58Z"
      fill="#F0F0F0"
    />
    <path
      d="M38.6003 31.6736L-62.7156 203.537L-61.7336 204.116L39.5823 32.2525L38.6003 31.6736Z"
      fill="#F0F0F0"
    />
  </svg>
);
