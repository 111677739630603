import React, {useState} from "react";
import SignupCoverTop from "../assets/svg/SignupCoverTop";
import SignupCoverBottom from "../assets/svg/SignupCoverBottom";
import {Link, useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import {useCookies} from "react-cookie";
import CryptoJS from "crypto-js";
import {PuffLoader} from "react-spinners";
import {withTranslation} from "react-i18next";
import i18n from "../i18n";
import i18next from "i18next";

const FastLogin = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [loginInput, setLogin] = useState({
        email: '',
        password: '',
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();
        setLogin({...loginInput, [e.target.name]: e.target.value});
    }

    const [cookie, setCookie,] = useCookies(['auth_token']);

    if (!cookie.auth_token) {
        const loginSubmit = (e) => {
            e.preventDefault();

            const data = {
                email: loginInput.email,
                password: loginInput.password,
            }

            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-start',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            axios.get('/sanctum/csrf-cookie').then(response => {
                axios.post('/api/register', data).then(res => {
                    if (res.data.status === 200) {
                        let expires = new Date()
                        expires.setTime(expires.getTime() + (response.data.expires_in * 1000))

                        const password = 'Sarmayegozaran@App'
                        const ApiToken = (content, password) => CryptoJS.AES.encrypt(JSON.stringify({content}), password).toString()

                        setCookie('auth_token', ApiToken(res.data.token, password), {path: '/', expires})

                        navigate('/')

                        Toast.fire({
                            icon: 'success',
                            title: 'حساب کاربری شماایجاد شد.'
                        })
                        setLoading(false)
                    } else if (res.data.status === 401) {
                        setLoading(false)
                    } else {
                        setLogin({loginInput, error_list: res.data.errors})

                        Toast.fire({
                            icon: 'error',
                            title: 'حساب کاربری شماایجاد نشد.'
                        })
                        setLoading(false)
                    }
                }).catch((err) => {
                    setLogin({
                            email: loginInput.email,
                            password: loginInput.password,
                            error_list: err.response.data.errors
                        }
                    )
                    Toast.fire({
                        icon: 'error',
                        title: 'اطلاعات نادرست است.'
                    })
                    setLoading(false)
                })
            })
            setLoading(true)
        }

        return (
            <>
                <SignupCoverTop/>
                <section className="w-full py-[50px] lg:py-0 lg:h-[377px] bg-secondary-bg-color grid grid-cols-1 lg:grid-cols-2 items-center gap-[24px] px-[25px] sm:px-[72px]">
                    <div className="col-span-1 text-center lg:text-start w-full text-white">
                        <h4 className="section-heading text-[22px] sm:text-[30px] lg:text-[40px] xl:text-[55px] leading-[40px] sm:leading-[80px] mb-[14px] font-light" dangerouslySetInnerHTML={{__html: i18next.language == 'en' ? props.dataContent.title_en : props.dataContent.title_fa}}/>
                        <p className="text-[12px] sm::text-[17px] xl:text-[19px] font-medium leading-[40px]" dangerouslySetInnerHTML={{__html: i18next.language == 'en' ? props.dataContent.desc_en : props.dataContent.desc_fa}}/>
                    </div>
                    <div className="col-span-1 w-full bg-white rounded-[20px] space-y-[14px] p-[20px] sm:p-[40px] relative">
                        {
                            loading ?
                                <div className='loading-section rounded-[20px] top-[5px] right-[5px] left-[5px] bottom-[5px] backdrop-blur-[5px] bg-opacity-50 bg-[#fff] z-[2] absolute text-center'>
                                    <div className='transform -translate-y-1/2 top-[50%] relative inline-block'>
                                        <PuffLoader color={'#0098ff'} loading={true} size={60} aria-label="Loading Spinner" data-testid="loader"/>
                                    </div>
                                </div>
                                : ''
                        }

                        <form onSubmit={loginSubmit}>
                            <div>
                                <input name={'email'} type="email" placeholder={i18next.t('FastLogin.آدرس ایمیل','آدرس ایمیل')} onChange={handleInput} value={loginInput.email} className="w-full bg-[#F3F3F3] rounded-[10px] px-[20px] py-[18px]"/>
                                <span className={'text-red-500 text-xs font-medium'}>{loginInput.error_list.email}</span>
                            </div>

                            <div className={'my-[14px] sm:my-[30px]'}>
                                <input name={'password'} type="password" placeholder={i18next.t('FastLogin.رمز عبور','رمز عبور')} onChange={handleInput} value={loginInput.password} className="w-full bg-[#F3F3F3] rounded-[10px] px-[20px] py-[18px]"/>
                                <span className={'text-red-500 text-xs font-medium'}>{loginInput.error_list.password}</span>
                            </div>
                            <div className="grid sm:grid-cols-2 text-center sm:float-end">
                                <button className="order-2 sm:order-1 text-[#767D80] rounded-[10px] mt-[20px] sm:mt-0 sm:ml-[30px]">
                                    <Link to={`/${i18n.language === 'en1' ? i18n.language : ''}/login`} className="w-[117px]">{i18next.t('FastLogin.قبلا عضو شدم','قبلا عضو شدم')}</Link>
                                </button>
                                <button className="order-1 rounded-[10px] bg-primary-btn-color hover:bg-secondary-btn-color text-white px-[60px] py-[20px] text-center">{i18next.t('FastLogin.ثبت نام','ثبت نام')}</button>
                            </div>
                        </form>
                    </div>
                </section>
                <SignupCoverBottom/>
            </>
        );
    }
};

export default withTranslation()(FastLogin);
