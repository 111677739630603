import React, {useState} from "react";
import Swal from "sweetalert2";
import axios from "axios";
import {PuffLoader} from "react-spinners";
import {useTranslation} from "react-i18next";

const SendResume = (props) => {
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const [resumeInput, setResume] = useState({
            name: '',
            email: '',
            mobile: '',
            resume_file: '',
            career_id: '',
            error_list: []
        }
    )

    const handleInput = (e) => {
        e.persist()
        setResume({...resumeInput, [e.target.name]: e.target.value})
    }

    /* Select File */
    const resumeFile = document.querySelector('#resume_file');

    const ResumeSubmit = (e) => {
        e.preventDefault()

        const data = new FormData();

        data.append("name", resumeInput.name);
        data.append('email', resumeInput.email);
        data.append("mobile", resumeInput.mobile);
        data.append("career_id", props.career_id);
        data.append("resume_file", resumeFile.files[0]);

        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-start',
            showCloseButton: false,
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        axios.post('/api/resume', data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then(res => {
            if (res.data.status === 200) {
                Toast.fire({
                    toast: false,
                    position: 'center',
                    icon: 'success',
                    title: t('Careers.رزومه شما ارسال شد', 'رزومه شما ارسال شد'),
                    text: t('Careers.کارشناسان ما به زودی با شما تماس خواهیم گرفت.', 'کارشناسان ما به زودی با شما تماس خواهیم گرفت.')
                })

                setResume({
                    name: '',
                    email: '',
                    mobile: '',
                    resume_file: '',
                    error_list: [],
                });
                setLoading(false)
            } else if (res.data.status === 401) {
                // setLoading(false)
            } else {
                setResume({resumeInput, error_list: res.data.errors})
                Toast.fire({
                    icon: 'error',
                    title: t('Careers.درخواست شما ثبت نشد.', 'درخواست شما ثبت نشد.')
                })
            }
            setLoading(false)
        }).catch((err) => {
            // console.log(err)
            setResume({
                name: resumeInput.name,
                email: resumeInput.email,
                mobile: resumeInput.mobile,
                resume_file: resumeInput.resume_file,
                error_list: err.response.data.errors,
            });
            Toast.fire({
                icon: 'error',
                title: t('Careers.درخواست شما ثبت نشد.', 'درخواست شما ثبت نشد.')
            })
            setLoading(false)
        })
        setLoading(true)
    }

    return (
        <section className='send-resume-form w-full px-[25px] md:px-[72px] xl:px-[237px] mb-[80px]'>
            <div className='bg-white rounded-[20px] p-[30px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)]'>
                <div className='text-[20px] font-extrabold text-center text-[#0F4C75] border-b border-b-[#E8E8E8] pb-[20px] mb-[26px]'>{t('Careers.ارسال رزومه', 'ارسال رزومه')}</div>
                <form className='grid sm:grid-cols-2 gap-[24px] relative' onSubmit={ResumeSubmit} encType="multipart/form-data">
                    {
                        loading ?
                            <div className='loading-section rounded-[20px] top-[-20px] right-[-20px] left-[-20px] bottom-[-20px] backdrop-blur-[5px] bg-opacity-50 bg-[#fff] z-[2] absolute text-center'>
                                <div className='transform -translate-y-1/2 top-[50%] relative inline-block'>
                                    <PuffLoader color={'#0098ff'} loading={true} size={60} aria-label="Loading Spinner" data-testid="loader"/>
                                </div>
                            </div>
                            : ''
                    }
                    <div>
                        <input name={'name'} type="text" placeholder={t('Careers.نام و نام خانوادگی', 'نام و نام خانوادگی')} onChange={handleInput} value={resumeInput.name} className="w-full h-[63px] bg-[#F3F3F3] rounded-[10px] px-[20px] py-[18px] text-[13px]"/>
                        <span className={'text-red-500 text-xs font-medium'}>{resumeInput.error_list.name}</span>
                    </div>
                    <div>
                        <input name={'email'} type="email" placeholder={t('Careers.ایمیل', 'ایمیل')} onChange={handleInput} value={resumeInput.email} className="w-full h-[63px] bg-[#F3F3F3] rounded-[10px] px-[20px] py-[18px] text-[13px]"/>
                        <span className={'text-red-500 text-xs font-medium'}>{resumeInput.error_list.email}</span>
                    </div>
                    <div>
                        <input name={'mobile'} type="text" placeholder={t('Careers.موبایل', 'موبایل')} onChange={handleInput} value={resumeInput.mobile} className="w-full h-[63px] bg-[#F3F3F3] rounded-[10px] px-[20px] py-[18px] text-[13px]"/>
                        <span className={'text-red-500 text-xs font-medium'}>{resumeInput.error_list.mobile}</span>
                    </div>
                    <div className='select-file'>
                        <label htmlFor={'resume_file'}>{t('Careers.انتخاب فایل رزومه', 'انتخاب فایل رزومه')}</label>
                        <input id={'resume_file'} name={'resume_file'} type="file" onChange={handleInput} value={resumeInput.resume_file}/>
                        <span className={'text-red-500 text-xs font-medium'}>{resumeInput.error_list.resume_file}</span>
                    </div>
                    <div/>
                    <div className='text-end'>
                        <button className='p-[19px_80px] w-full sm:w-max outline-none rounded-[10px] text-white font-medium bg-[#0098FF] text-center hover:bg-[#018cea] duration-[300ms]' type='submit'>{t('Careers.ارسال رزومه', 'ارسال رزومه')}</button>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default SendResume