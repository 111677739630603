import React from "react";
import WalletTick from "../../assets/svg/WalletTick";
import ChartUp from "../../assets/svg/ChartUp";
import SecurityUser from "../../assets/svg/SecurityUser";
import Ticket from "../../assets/svg/Ticket";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

const SarmayeGozarFeatures = (props) => {
    return (
        <section className="w-full text-center text-[19px] px-[25px] sm:px-[72px] mt-[100px] lg:mt-[57px]">
            <div className='text-[22px] sm:text-[34px] section-heading font-light' dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.title_en : props.dataContent.title_fa }} />
            <div className="grid gap-[10px] grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 text-[19px] font-medium mt-[47px] leading-[40px]">
                <div>
                    <div className='inline-block w-full'>
                        <div className="xl:w-[306px] h-[306px] col-span-1 flex flex-col justify-center items-center shadow-[5px_5px_40px_rgba(0,0,0,0.05)] hover:shadow-[0px_30px_40px_rgba(0,0,0,0.08)] relative rounded-[20px] card-anime">
                            <WalletTick/>
                            <p className="mt-[12px]" dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.feature_1.title_en : props.dataContent.feature_1.title_fa }}/>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='inline-block w-full'>
                        <div className="xl:w-[306px] h-[306px] col-span-1 flex flex-col justify-center items-center shadow-[5px_5px_40px_rgba(0,0,0,0.05)] hover:shadow-[0px_30px_40px_rgba(0,0,0,0.08)] relative hover:bottom-[20px] rounded-[20px] card-anime">
                            <ChartUp/>
                            <p className="mt-[12px]" dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.feature_2.title_en : props.dataContent.feature_2.title_fa }}/>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='inline-block w-full'>
                        <div className="xl:w-[306px] h-[306px] col-span-1 flex flex-col justify-center items-center shadow-[5px_5px_40px_rgba(0,0,0,0.05)] hover:shadow-[0px_30px_40px_rgba(0,0,0,0.08)] relative hover:bottom-[20px] rounded-[20px] card-anime">
                            <SecurityUser/>
                            <p className="mt-[12px]" dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.feature_3.title_en : props.dataContent.feature_3.title_fa }}/>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='inline-block w-full'>
                        <div className="xl:w-[306px] h-[306px] col-span-1 flex flex-col justify-center items-center shadow-[5px_5px_40px_rgba(0,0,0,0.05)] hover:shadow-[0px_30px_40px_rgba(0,0,0,0.08)] relative hover:bottom-[20px] rounded-[20px] card-anime">
                            <Ticket/>
                            <p className="mt-[12px]" dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.feature_4.title_en : props.dataContent.feature_4.title_fa }}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(SarmayeGozarFeatures);
