import React, {useState} from "react";
import EmptyWalletTick from "../../assets/svg/EmptyWalletTick";
import CardSlash from "../../assets/svg/CardSlash";
import InternetGlobalRefresh from "../../assets/svg/InternetGlobalRefresh";
import ArrowTop from "../../assets/svg/ArrowTop";
import ArrowDown from "../../assets/svg/ArrowDown";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

const CreateReasons = (props) => {
    const [isRulesOpen, setIsRulesOpen] = useState({
        ruleOne: false,
        ruleTwo: false,
    });

    return (
        <div className="w-full px-[25px] md:px-[72px]">
            <h3 className="text-secondary-bg-color text-center text-[22px] md:text-[34px] leading-[40px] md:leading-[50px] pb-[48px] section-heading font-light" dangerouslySetInnerHTML={{__html: i18next.language == 'en' ? props.dataContent.title_en : props.dataContent.title_fa}} />
            <div className="grid grid-cols-1 lg:grid-cols-3 text-center items-start text-secondary-bg-color text-[19px] leading-[40px] font-bold pb-[82px]">
                <div className={`col-span-1 flex flex-col justify-center items-center border-b lg:border-b-0 pb-10 lg-pb-0 mb-10 lg:mb-0 ${i18next.language === 'fa' ? 'lg:border-l' : 'lg:border-r'}`}>
                    <EmptyWalletTick/>
                    <div className="w-[269px] mt-[12px]" dangerouslySetInnerHTML={{__html: i18next.language == 'en' ? props.dataContent.feature_1.title_en : props.dataContent.feature_1.title_fa}}/>
                </div>
                <div className={`col-span-1 flex flex-col justify-center items-center border-b lg:border-b-0 pb-10 lg-pb-0 mb-10 lg:mb-0 ${i18next.language === 'fa' ? 'lg:border-l' : 'lg:border-r'}`}>
                    <CardSlash/>
                    <div className="w-[269px] mt-[12px]" dangerouslySetInnerHTML={{__html: i18next.language == 'en' ? props.dataContent.feature_2.title_en : props.dataContent.feature_2.title_fa}}/>
                </div>
                <div className="col-span-1 flex flex-col justify-center items-center">
                    <InternetGlobalRefresh/>
                    <div className="w-[269px] mt-[12px]" dangerouslySetInnerHTML={{__html: i18next.language == 'en' ? props.dataContent.feature_3.title_en : props.dataContent.feature_3.title_fa}}/>
                </div>
            </div>
            <div className="w-full bg-white rounded-[20px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] mb-[24px] px-[40px] py-[35px]">
                <div className="flex justify-between items-center cursor-pointer" onClick={() =>
                    setIsRulesOpen({...isRulesOpen, ruleOne: !isRulesOpen.ruleOne})
                }>
                    <p className="text-[#0F4C75] text-[11px] sm:text-[13px] md:text-[14px] xl:text-[16px] md:text-xl font-extrabold" dangerouslySetInnerHTML={{__html: i18next.language == 'en' ? props.dataContentFAQ.question_1_en : props.dataContentFAQ.question_1_fa}}/>
                    {isRulesOpen.ruleOne ? <ArrowDown/> : <ArrowTop/>}
                </div>
                {isRulesOpen.ruleOne ? (
                    <div className="text-[#767D80] font-medium leading-[35px] border-t mt-[20px] pt-[20px] plan-detail-anime text-[12px] md:text-[14px] lg:text-[16px]" dangerouslySetInnerHTML={{__html: i18next.language == 'en' ? props.dataContentFAQ.answer_1_en : props.dataContentFAQ.answer_1_fa}}/>
                ) : null}
            </div>
            <div className="w-full bg-white rounded-[20px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] px-[40px] py-[35px]">
                <div className="flex justify-between items-center cursor-pointer" onClick={() =>
                    setIsRulesOpen({...isRulesOpen, ruleTwo: !isRulesOpen.ruleTwo})
                }>
                    <p className="text-[#0F4C75] text-[11px] sm:text-[13px] md:text-[14px] xl:text-[16px] md:text-xl font-extrabold" dangerouslySetInnerHTML={{__html: i18next.language == 'en' ? props.dataContentFAQ.question_2_en : props.dataContentFAQ.question_2_fa}}/>
                    {isRulesOpen.ruleTwo ? <ArrowDown/> : <ArrowTop/>}
                </div>
                {isRulesOpen.ruleTwo ? (
                    <div className="text-[#767D80] font-medium leading-[35px] border-t mt-[20px] pt-[20px] plan-detail-anime text-[12px] md:text-[14px] lg:text-[16px]" dangerouslySetInnerHTML={{__html: i18next.language == 'en' ? props.dataContentFAQ.answer_2_en : props.dataContentFAQ.answer_2_fa}}/>
                ) : null}
            </div>
        </div>
    );
};

export default withTranslation()(CreateReasons);