import React from "react";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import {useTranslation} from "react-i18next";
import i18n from "../i18n";

const BlogCard = (props) => {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const DescLimited = props.desc.substr(0, 240)
    return (
        <div dir='auto' onClick={() => navigate((i18n.language === 'en1' ? '/en' : '') + "/blog/" + props.slug)} className="w-full bg-white rounded-[20px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] p-[15px] cursor-pointer relative hover:bottom-[20px] card-anime">
            { props.thumbnail ? <img className="w-full rounded-[10px]" src={axios.defaults.baseURL + 'storage/' + props.thumbnail.path + '336/' + props.thumbnail.file_name} alt={props.title}/> : '' }
            <p className="text-secondary-bg-color font-extrabold mt-[21px] mb-[15px]">
                {props.title}
            </p>
            <p className="text-[#767D80] text-sm font-medium leading-[27px]">{DescLimited.substr(0, Math.min(DescLimited.length, DescLimited.lastIndexOf(" ")))}{props.desc.length > DescLimited.length ? '...' : ''}</p>
            <div className="w-full h-[1px] bg-[#ECECEC] my-[15px]"/>
            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}blog/` + props.slug } className="flex justify-center text-primary-btn-color text-sm font-extrabold"> {t('HomePage.ادامه مطلب', 'ادامه مطلب')} </Link>
        </div>
    );
};

export default BlogCard;
