import React from "react";
import { withTranslation } from "react-i18next";
import i18next from "i18next";

const TradersPanelPoster = (props) => {
    return (
        <>
            <div className="flex flex-wrap lg:flex-nowrap items-center px-[25px] sm:px-[72px]">
                <div className="w-full text-center lg:text-start col-span-1">
                    <h4 className="text-[22px] sm:text-5xl leading-[40px] sm:leading-[70px] mb-[14px] section-heading font-light" dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.title_en : props.dataContent.title_fa }} />
                    <p className="xl:w-[636px] text-[#767d80] text-[12px] sm:text-[19px] leading-[30px] sm:leading-[40px] mb-[30px]" dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.desc_en : props.dataContent.desc_fa }} />
                    <button className="w-[237px] h-[62px] bg-primary-btn-color hover:bg-secondary-btn-color text-white transition-all rounded-[10px] px-[60px] py-[11px]">
                        {i18next.t('HomePage.ورود به پنل', 'ورود به پنل')}
                    </button>
                </div>
                <div className="w-full col-span-1">
                    <img className="w-full" src={require("../../assets/img/last-dashboad.png")} alt="Login-dashboard-poster" />
                </div>
            </div>

            <div className='text-center'>
                <img className="hidden md:block w-full mx-auto mt-10" src={i18next.language === 'fa' ? require("../../assets/img/Support.png") : require("../../assets/img/en/Support.png")} alt="Support" />

                <img className="inline-block md:hidden mb-[40px] md:mb-0" src={i18next.language === 'fa' ? require("../../assets/img/SupportRes.png") : require("../../assets/img/en/SupportRes.png")} srcSet={`${i18next.language === 'fa' ? require("../../assets/img/SupportRes.png") : require("../../assets/img/en/SupportRes.png")} 1x, ${i18next.language === 'fa' ? require("../../assets/img/SupportRes@2x.png") : require("../../assets/img/en/SupportRes@2x.png")} 2x`} alt="Support" />
            </div>
        </>
    );
};

export default withTranslation()(TradersPanelPoster);
