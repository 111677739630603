export default ({color = "#C8C8C8"}) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.969 4.15765C24.0352 4.60188 23.041 4.89919 22.02 5.03936C23.108 4.3302 23.9078 3.24281 24.2717 1.97837C23.276 2.61923 22.1773 3.07549 21.0261 3.32591C20.5606 2.77856 19.9865 2.33839 19.3419 2.03442C18.6973 1.73045 17.9969 1.5695 17.2869 1.5625C15.8899 1.60685 14.5672 2.21486 13.6085 3.25321C12.6498 4.29156 12.1334 5.67568 12.1725 7.10208C12.1688 7.5265 12.2081 7.95019 12.2898 8.36635C10.2255 8.23991 8.21271 7.65656 6.39106 6.65696C4.56941 5.65737 2.98241 4.26546 1.74008 2.57741C1.28141 3.43453 1.04055 4.39527 1.03949 5.37198C1.03415 6.26742 1.23831 7.15107 1.63507 7.94967C2.03182 8.74826 2.60968 9.43871 3.32051 9.96325C2.50469 9.94283 1.7078 9.70807 1.00688 9.28136V9.34796C0.983143 10.5955 1.37644 11.8141 2.1219 12.8029C2.86736 13.7917 3.92051 14.4918 5.10783 14.7876C4.67082 14.9093 4.21987 14.9706 3.76691 14.9704C3.43855 14.9716 3.11099 14.9381 2.78937 14.8705C3.08935 15.9431 3.71294 16.8919 4.57147 17.5818C5.43 18.2717 6.47974 18.6676 7.57137 18.7133C5.79343 20.2373 3.54668 21.0734 1.22521 21.0753C0.815854 21.0784 0.406719 21.0565 0 21.0091C2.31137 22.6285 5.05002 23.4928 7.85324 23.4877C17.2722 23.4877 22.4208 15.0534 22.4208 7.75066C22.4208 7.50114 22.4208 7.26836 22.4012 7.03547C23.4229 6.24519 24.2933 5.26969 24.969 4.15765Z"
      fill={color}
    />
  </svg>
);
