import React, {useEffect, useState} from 'react'
import useTitle from "../hooks/useTitle";
import ContactForm from '../components/contactus/ContactForm'
import SupportPosters from '../components/contactus/SupportPosters'
import ContactWays from '../components/contactus/ContactWays'
import FastLogin from "../components/FastLogin";
import axios from "axios";
import {ClipLoader} from "react-spinners";

const ContactUs = () => {
    useTitle("تماس با ما")
    const [pageData, setPageData] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchArticle()
        setLoading(true)
    }, [])

    const fetchArticle = async () => {
        axios.get(`/api/page/درباره-ما`).then(({data}) => {
                setPageData(data)
                setLoading(false)
                console.log(data)
            }
        ).catch((err) => {
            console.log(err)
        })
    }

    if (loading) {
        return (
            <div className='text-center py-[80px] bg-primary-bg-color'>
                <ClipLoader color={'#0098ff'} loading={loading} size={60} aria-label="Loading Spinner" data-testid="loader"/>
            </div>
        )
    }

    if (pageData) {
        return (
            <section className='w-full bg-primary-bg-color overflow-hidden'>
                <ContactForm/>
                <SupportPosters/>
                <ContactWays/>
                <FastLogin dataContent={pageData.quick_register_section}/>
            </section>
        )
    }
}

export default ContactUs