import { useLayoutEffect } from "react";
import i18next from "i18next";
import {useTranslation} from "react-i18next";

const useTitle = (title) => {
  const {t} = useTranslation()

  useLayoutEffect(() => {
    document.title = title + ' | ' + t('سرمایه گذار برتر');
  }, [i18next.language]);
};

export default useTitle
