import React from "react";
import useTitle from "../../hooks/useTitle";
import CareerCategory from "../../assets/svg/CareerCategory";
import CompanyLocation from "../../assets/svg/CompanyLocation";
import Whatch from "../../assets/svg/Whatch";
import User from "../../assets/svg/User";
import {useTranslation} from "react-i18next";
import i18n from "../../i18n";

const TitleInfo = (props) => {
    useTitle(props.title)
    const {t} = useTranslation()

    return (
        <div className="w-full lg:h-[248px] bg-secondary-bg-color px-[25px] md:px-[72px]">
            <div className="w-full h-[1px] bg-[#495156]"/>
            <h2 className="text-center text-white text-[22px] md:text-[32px] font-extrabold mt-[40px] mb-[30px] md:mb-[20px]">{props.title}</h2>
            <div className="text-center text-[#BFBFBF] text-sm font-medium mb-[30px] leading-[26px]">
                {t('Careers.صفحه اصلی', 'صفحه اصلی')} {">"} {t('Careers.همکاری با سرمایه گذار برتر', 'همکاری با سرمایه گذار برتر')} {">"} {props.categoryTitle} {">"}{" "} <span className="text-white text-opacity-20">{props.title}</span>
            </div>

          <div className="flex flex-col lg:flex-row justify-center items-center text-[#BFBFBF] gap-[24px] lg:gap-[71px] pb-[60px] lg:pb-0">
                <div className="flex items-center gap-[5px]">
                    <CareerCategory/> <span>{t('Careers.گروه شغلی', 'گروه شغلی')}: {props.categoryTitle}</span>
                </div>
                <div className="flex items-center gap-[5px]">
                    <CompanyLocation/> <span>{t('Careers.موقعیت', 'موقعیت')}: {props.location}</span>
                </div>
                <div className="flex items-center gap-[5px]">
                    <Whatch/> <span>{t('Careers.ساعت کاری', 'ساعت کاری')}: {i18n.language == 'en' ? 'Full Time' :props.cooperationType}</span>
                </div>
                <div className='flex items-center gap-[5px]'>
                    <User color={'#BFBFBF'}/> <span>{t('Careers.جنسیت', 'جنسیت')}: {i18n.language == 'en' ? 'Male' : props.gender}</span>
                </div>
            </div>
        </div>
    );
};

export default TitleInfo;
