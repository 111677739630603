import useTitle from "../hooks/useTitle";
import InfoHeaderThankYou from "../components/buy/InfoHeaderThankYou";
import ThankYouContent from "../components/buy/ThankYouContent";
import {withTranslation} from "react-i18next";

const Buy = ({t}) => {
    useTitle(t('PageTitle.ThankYou', 'خرید شما موفقیت آمیز بود'))


    return (
        <section className='overflow-hidden w-full'>
            <InfoHeaderThankYou/>
            <ThankYouContent/>
        </section>);
};

export default withTranslation()(Buy)