import React, { useState } from "react";
import useTitle from "../../hooks/useTitle";
import Swal from "sweetalert2";
import axios from "axios";
import { PuffLoader } from "react-spinners";
import { withTranslation } from "react-i18next";

const ContactForm = ({ t }) => {
    useTitle(t('PageTitle.ContactUs', 'تماس باما'))

    const [loading, setLoading] = useState(false)
    const [ConsultationInput, setConsultaion] = useState({
        full_name: '',
        email: '',
        phone: '',
        title: '',
        message: '',
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();
        setConsultaion({ ...ConsultationInput, [e.target.name]: e.target.value });
    }

    const ConsultationSubmit = (e) => {
        e.preventDefault();

        const data = {
            full_name: ConsultationInput.full_name,
            email: ConsultationInput.email,
            phone: ConsultationInput.phone,
            title: ConsultationInput.title,
            message: ConsultationInput.message,
        }

        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-start',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        axios.post('/api/consultation-request-site', data).then(res => {
            if (res.data.status === 200) {
                Toast.fire({
                    icon: 'success',
                    title: t('ContactUs.درخواست شما ثبت شد.', 'درخواست شما ثبت شد.'),
                    desc: t('ContactUs.به زودی با شما تماس خواهیم گرفت.', 'به زودی با شما تماس خواهیم گرفت.'),
                })

                setConsultaion({
                    full_name: '',
                    email: '',
                    phone: '',
                    title: '',
                    message: '',
                    error_list: [],
                });
                setLoading(false)
            } else if (res.data.status === 401) {
                setLoading(false)
            } else {
                setConsultaion({ ConsultationInput, error_list: res.data.errors })
                Toast.fire({
                    icon: 'error',
                    title: t('ContactUs.درخواست شما ثبت نشد.', 'درخواست شما ثبت نشد.')
                })
                setLoading(false)
            }
        }).catch((err) => {
            setConsultaion({
                full_name: ConsultationInput.full_name,
                email: ConsultationInput.email,
                phone: ConsultationInput.phone,
                title: ConsultationInput.title,
                message: ConsultationInput.message,
                error_list: err.response.data.errors,
            });
            Toast.fire({
                icon: 'error',
                title: t('ContactUs.درخواست شما ثبت نشد.', 'درخواست شما ثبت نشد.')
            })
            setLoading(false)
        })
        setLoading(true)
    }

    return (
        <>
            <div className="w-full relative bg-secondary-bg-color px-[25px] xl:px-[72px] pb-[90px]">
                <div className="w-full h-[1px] xl:bg-[#495156]" />
                <div className="text-center mt-[10px] xl:mt-[40px]">
                    <h3 className="text-white text-[22px] lg:text-[32px] mb-[15px] font-extrabold">
                        {t('ContactUs.تماس با سرمایه گذار برتر', 'تماس با سرمایه گذار برتر')}
                    </h3>
                    <p className="text-[#BFBFBF] text-[14px] lg:text-sm font-medium">
                        {t('ContactUs.PageDesc', 'درصورت داشتن هرگونه سوال، میتوانید از مسیر های ارتباطی زیر با ما در تماس باشید')}
                    </p>
                </div>
            </div>
            <form id='ConsultationForm' onSubmit={ConsultationSubmit} className="md:mx-[25px] xl:mx-[72px] bg-white rounded-[20px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] mx-auto mt-[-35px] p-[35px] sm:p-[40px] relative mb-[70px]">
                {
                    loading ?
                        <div className='loading-section rounded-[20px] top-[5px] right-[5px] left-[5px] bottom-[5px] backdrop-blur-[5px] bg-opacity-50 bg-[#fff] z-[2] absolute text-center'>
                            <div className='transform -translate-y-1/2 top-[50%] relative inline-block'>
                                <PuffLoader color={'#0098ff'} loading={true} size={60} aria-label="Loading Spinner" data-testid="loader" />
                            </div>
                        </div>
                        : ''
                }

                <div className="text-center text-[22px] xl:text-[34px] leading-[40px] xl:leading-[50px] mb-[30px] sm:mb-[50px]">
                    <p className="font-extrabold">{t('ContactUs.فرم ارتباط با', 'فرم ارتباط با')}</p>
                    <p className="font-light">{t('ContactUs.سرمایه گذار برتر', 'سرمایه گذار برتر')}</p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-[23px]">
                    <label className="col-span-1 w-full">
                        <span className="text-[#717171]">{t('ContactUs.نام شما', 'نام شما')}</span>
                        <input name={'full_name'} type="text" placeholder={t('ContactUs.نام شما', 'نام شما')} onChange={handleInput} value={ConsultationInput.full_name} className="w-full h-[63px] bg-[#F3F3F3] rounded-[10px] px-[20px] py-[18px] mt-[14px]" />
                        <span className={'text-red-500 text-xs font-medium'}>{ConsultationInput.error_list.full_name}</span>
                    </label>
                    <label className="col-span-1 w-full">
                        <span className="text-[#717171]">{t('ContactUs.آدرس ایمیل', 'آدرس ایمیل')}</span>
                        <input name={'email'} type="email" placeholder={t('ContactUs.آدرس ایمیل', 'آدرس ایمیل')} onChange={handleInput} value={ConsultationInput.email} className="w-full h-[63px] bg-[#F3F3F3] rounded-[10px] px-[20px] py-[18px] mt-[14px]" />
                        <span className={'text-red-500 text-xs font-medium'}>{ConsultationInput.error_list.email}</span>
                    </label>
                    <label className="col-span-1 w-full">
                        <span className="text-[#717171]">{t('ContactUs.شماره تماس', 'شماره تماس')}</span>
                        <input name={'phone'} type="text" placeholder={t('ContactUs.شماره تماس', 'شماره تماس')} onChange={handleInput} value={ConsultationInput.phone} className="w-full h-[63px] bg-[#F3F3F3] rounded-[10px] px-[20px] py-[18px] mt-[14px]" />
                        {/*<span className={'text-red-500 text-xs font-medium'}>{ConsultationInput.error_list.phone}</span>*/}
                    </label>
                    <label className="col-span-1 w-full">
                        <span className="text-[#717171]">{t('ContactUs.موضوع پیام', 'موضوع پیام')}</span>
                        <input name={'title'} type="text" placeholder={t('ContactUs.موضوع پیام', 'موضوع پیام')} onChange={handleInput} value={ConsultationInput.title} className="w-full h-[63px] bg-[#F3F3F3] rounded-[10px] px-[20px] py-[18px] mt-[14px]" />
                        <span className={'text-red-500 text-xs font-medium'}>{ConsultationInput.error_list.title}</span>
                    </label>
                </div>
                <label className="col-span-4 w-full block mt-[23px]">
                    <span className="text-[#717171]">{t('ContactUs.پیام شما', 'پیام شما')}:</span> <textarea placeholder={t('ContactUs.پیام شما', 'پیام شما')} onChange={handleInput} value={ConsultationInput.message} name={'message'} className="w-full h-[152px] bg-[#F3F3F3] rounded-[10px] px-[20px] py-[18px] mt-[14px]" /> <span className={'text-red-500 no-resize text-xs font-medium'}>{ConsultationInput.error_list.message}</span>
                </label>

                <div className="text-left">
                    <button className="inline-block w-full sm:w-[200px] h-[56px] bg-primary-btn-color text-white text-sm font-medium rounded-[10px] py-[15px] mt-[40px] px-[30px]">
                        {t('ContactUs.ارسال پیام', 'ارسال پیام')}
                    </button>
                </div>
            </form>
        </>
    );
};

export default withTranslation()(ContactForm);