import React from "react";
import {withTranslation} from "react-i18next";

const TablePlanDetails = ({t}) => {
  return (
    <div className="mx-[-1px] bg-[#F5F5F5] relative top-[-1px] leading-[40px] rounded-b-[20px] mx-auto p-[40px] lg:p-[90px_63px] shadow-lg shadow-inner">
      <p className="text-[#767d80] text-right">{t('RulesPage.Answer1', 'سرمایه گذار برتر در راستای تامین سرمایه معامله گران برای ترید در بازارهای مالی با حداقل ریسک ممکن تشکیل شده و در این راه خدماتی را به تریدرها ارائه میکند سرمایه گذار برتر در راستای تامین سرمایه معامله گران برای ترید سرمایه گذار برتر در راستای تامین سرمایه معامله گران برای ترید در بازارهای مالی با حداقل ریسک ممکن تشکیل شده و در این راه خدماتی را به تریدرها ارائه میکند سرمایه گذار برتر در راستای تامین سرمایه معامله گران برای ترید.')}</p>
    </div>
  );
};

export default withTranslation()(TablePlanDetails);
