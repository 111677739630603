import React from "react";
import {withTranslation} from "react-i18next";

const ContentCategory = ({t}) => {
  return (
    <div className="w-full px-[25px] md:px-[72px] md:mb-[76px]">
      <div className="flex items-center gap-[32px] mb-6">
        <div className="text-lg font-extrabold whitespace-nowrap">
          {t('Blog.دسته بندی مطالب', 'دسته بندی مطالب')}
        </div>
        <div className="w-full h-[1px] bg-[#E8E8E8]" />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-none xl:grid-flow-col xl:auto-cols-auto justify-between items-center gap-[20px] mb-[59px] font-bold text-[#C7C7C7]">
        <button className="text-[12px] md:text-sm hover:text-primary-btn-color hover:border-primary-btn-color transition-all border rounded-[10px] whitespace-nowrap px-[20px] md:px-[30px] py-[12px] md:py-[18px]">
          {t('Blog.Category1', 'قوانین پرداخت ها')}
        </button>
        <button className="text-[12px] md:text-sm hover:text-primary-btn-color hover:border-primary-btn-color transition-all border rounded-[10px] whitespace-nowrap px-[20px] md:px-[30px] py-[12px] md:py-[18px]">
          {t('Blog.Category2', 'قوانین موارد غیر مجاز')}
        </button>
        <button className="text-[12px] md:text-sm hover:text-primary-btn-color hover:border-primary-btn-color transition-all border rounded-[10px] whitespace-nowrap px-[20px] md:px-[30px] py-[12px] md:py-[18px]">
          {t('Blog.Category3', 'قوانین تقلب')}
        </button>
        <button className="text-[12px] md:text-sm hover:text-primary-btn-color hover:border-primary-btn-color transition-all border rounded-[10px] whitespace-nowrap px-[20px] md:px-[30px] py-[12px] md:py-[18px]">
          {t('Blog.Category5', 'قوانین رشد سرمایه')}
        </button>
        <button className="text-[12px] md:text-sm hover:text-primary-btn-color hover:border-primary-btn-color transition-all border rounded-[10px] whitespace-nowrap px-[20px] md:px-[30px] py-[12px] md:py-[18px]">
          {t('Blog.Category5', 'قوانین اینسترومنت ها')}
        </button>
        <button className="text-[12px] md:text-sm hover:text-primary-btn-color hover:border-primary-btn-color transition-all border rounded-[10px] whitespace-nowrap px-[20px] md:px-[30px] py-[12px] md:py-[18px]">
          {t('Blog.Category6', 'قوانین موارد غیر مجاز')}
        </button>
        <button className="text-[12px] md:text-sm hover:text-primary-btn-color hover:border-primary-btn-color transition-all border rounded-[10px] whitespace-nowrap px-[20px] md:px-[30px] py-[12px] md:py-[18px]">
          {t('Blog.Category7', 'قوانین تقلب')}
        </button>
      </div>
    </div>
  );
};

export default withTranslation()(ContentCategory)
