export default ({color = "#C8C8C8"}) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g cliprath="url(#clip0_47_159)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.20842e-05 7.80334H5.5953V24.9676H1.20842e-05V7.80334ZM20.835 8.00026L20.657 7.95103C20.5856 7.91899 20.5083 7.90219 20.4301 7.9018C20.0999 7.83248 19.7632 7.7994 19.426 7.80334C18.241 7.85314 17.083 8.1748 16.0408 8.74308C14.9985 9.31137 14.0995 10.1112 13.4127 11.082V7.80334H7.81904V24.9676H13.4127V15.6066C13.4127 15.6066 17.6414 9.72145 19.426 14.0329V24.9676H25.0196V13.3774C25.0144 12.1431 24.6014 10.9453 23.8452 9.97219C23.0891 8.99905 22.0326 8.30554 20.8415 8.00026H20.835ZM2.73642 4.91182e-05C3.27365 0.00361528 3.79781 0.167076 4.24267 0.469519C4.68752 0.771963 5.03314 1.1999 5.2359 1.69943C5.43866 2.19896 5.48947 2.74784 5.38189 3.27634C5.27432 3.80483 5.01316 4.28954 4.63146 4.66914C4.24976 5.04873 3.76459 5.30623 3.23722 5.40916C2.70986 5.5121 2.16393 5.45584 1.66839 5.24747C1.17285 5.03911 0.74992 4.68803 0.452982 4.23849C0.156044 3.78895 -0.00158951 3.26104 1.20842e-05 2.72162C0.00129408 2.3624 0.0731315 2.00671 0.211425 1.67541C0.349718 1.34412 0.551765 1.04369 0.805951 0.790905C1.06014 0.538121 1.36149 0.338237 1.69276 0.202566C2.02402 0.0668944 2.37866 -0.00210935 2.73642 4.91182e-05Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_47_159">
        <rect width="25" height="25" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
