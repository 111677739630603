import React, {useState} from "react";
import {Trans} from "react-i18next";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

const GeneralRules = (props) => {
    const [isRuleOne, setIsRuleOne] = useState(true);
    const [isRuleTwo, setIsRuleTwo] = useState(false);
    const [isRuleThree, setIsRuleThree] = useState(false);
    const [isRuleFour, setIsRuleFour] = useState(false);

    return (
        <section className="w-full px-[25px] sm:px-[72px]">
            <div className="flex flex-wrap md:flex-nowrap items-center gap-[32px] mb-[35px] text-center md:text-right">
                <div className="w-full md:w-min  text-secondary-bg-color text-[22px] md:text-[32px] font-extrabold whitespace-nowrap">
                    <Trans i18nKey='RulesPage.قوانین کلی'>
                    قوانین کلی <span className="font-light">سایت</span>
                    </Trans>
                </div>
                <div className="hidden md:block w-full h-[1px] bg-[#E8E8E8]"/>
            </div>
            <div className="flex flex-wrap xl:flex-nowrap justify-between gap-[46px] mb-[70px]">
                <div className="flex flex-col items-center space-y-[20px] w-full">
                    <button className={`w-full text-sm font-bold rounded-[10px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] px-[60px] py-[20px] ${
                        isRuleOne
                            ? "text-white bg-primary-btn-color"
                            : "text-[#767D80] bg-white"
                    }`} onClick={() => {
                        setIsRuleOne(true);
                        setIsRuleTwo(false);
                        setIsRuleThree(false);
                        setIsRuleFour(false);
                    }}>
                        <div className='xl:w-[400px]' dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.question_1_en : props.dataContent.question_1_fa }}/>
                    </button>
                    <button className={`w-full text-sm font-bold rounded-[10px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] px-[60px] py-[20px] ${
                        isRuleTwo
                            ? "text-white bg-primary-btn-color"
                            : "text-[#767D80] bg-white"
                    }`} onClick={() => {
                        setIsRuleOne(false);
                        setIsRuleTwo(true);
                        setIsRuleThree(false);
                        setIsRuleFour(false);
                    }}>
                        <div className='xl:w-[400px]' dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.question_2_en : props.dataContent.question_2_fa }}/>
                    </button>
                    <button className={`w-full text-sm font-bold rounded-[10px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] px-[60px] py-[20px] ${
                        isRuleThree
                            ? "text-white bg-primary-btn-color"
                            : "text-[#767D80] bg-white"
                    }`} onClick={() => {
                        setIsRuleOne(false);
                        setIsRuleTwo(false);
                        setIsRuleThree(true);
                        setIsRuleFour(false);
                    }}>
                        <div className='xl:w-[400px]' dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.question_3_en : props.dataContent.question_3_fa }}/>
                    </button>
                    <button className={`w-full text-sm font-bold rounded-[10px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] px-[60px] py-[20px] ${
                        isRuleFour
                            ? "text-white bg-primary-btn-color"
                            : "text-[#767D80] bg-white"
                    }`} onClick={() => {
                        setIsRuleOne(false);
                        setIsRuleTwo(false);
                        setIsRuleThree(false);
                        setIsRuleFour(true);
                    }}>
                        <div className='xl:w-[400px]' dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.question_4_en : props.dataContent.question_4_fa }}/>
                    </button>
                </div>
                <div className="bg-white rounded-[20px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] px-[40px] py-[30px]">
                    <p className="text-[12px] sm:text-[15px] leading-[35px] font-medium" dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.answer_1_en : props.dataContent.answer_1_fa }}/>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(GeneralRules)
