import React from "react";
import Twiter from "../../assets/svg/Twiter";
import Linkedin from "../../assets/svg/Linkedin";
import WhatsApp from "../../assets/svg/WhatsApp";
import Instagram from "../../assets/svg/Instagram";
import Facebooke from "../../assets/svg/Facebooke";
import Youtube from "../../assets/svg/Youtube";
import '../../assets/styles/blog.min.css';
import {useTranslation} from "react-i18next";

const BlogContent = (props) => {
  const {t} = useTranslation()
  return (
    <section className="mx-[25px] lg:mx-auto lg:w-[966px] relative top-[-25px] bg-white rounded-[20px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] mx-auto mb-[40px] am:mb-[69px] p-[15px] md:p-[40px]">
      { props.thumbnail ? <img className="w-full rounded-[10px] mb-[30px]" src={props.thumbnail} alt={ props.title }/> : ''}
      <div className='content-text' dangerouslySetInnerHTML={{ __html: props.content }} />
      <div className="w-full flex flex-col sm:flex-row justify-between items-center border rounded-[10px] px-[10px] sm:px-[45px] py-[15px]">
        <div className="text-[#797979] text-sm font-bold text-center sm:text-right mb-[20px] sm:mb-0">
          {t('Blog.اشتراک گذاری در شبکه های اجتماعی', 'اشتراک گذاری در شبکه های اجتماعی')}
        </div>
        <div className="flex items-center gap-[20px]">
          <a href="#" className="hover-icon">
            <div className="icon-hidden">
              <Twiter />
            </div>
            <div className="hidden icon-show">
              <Twiter color="#0098FF" />
            </div>
          </a>
          <a href="#" className="hover-icon">
            <div className="icon-hidden">
              <Linkedin />
            </div>
            <div className="hidden icon-show">
              <Linkedin color="#0098FF" />
            </div>
          </a>
          <a href="#" className="hover-icon">
            <div className="icon-hidden">
              <WhatsApp />
            </div>
            <div className="hidden icon-show">
              <WhatsApp color="#0098FF" />
            </div>
          </a>
          <a href="#" className="hover-icon">
            <div className="icon-hidden">
              <Instagram />
            </div>
            <div className="hidden icon-show">
              <Instagram color="#0098FF" />
            </div>
          </a>
          <a href="#" className="hover-icon">
            <div className="icon-hidden">
              <Facebooke />
            </div>
            <div className="hidden icon-show">
              <Facebooke color="#0098FF" />
            </div>
          </a>
          <a href="#" className="hover-icon">
            <div className="icon-hidden">
              <Youtube />
            </div>
            <div className="hidden icon-show">
              <Youtube color="#0098FF" />
            </div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default BlogContent;
