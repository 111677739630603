import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import i18next from "i18next";

const AboutCompony = (props) => {
    const navigate = useNavigate();

    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 my-[90px] px-[25px] sm:px-[72px] text-center lg:text-start">
            <div className="relative order-2 lg:order-1 col-span-1 xl:right-[0] lg:right-[-30px] text-center mt-[50px] lg:mt-0">
                <img className='inline-block' src={require("../../assets/img/abount-sgb.jpg")} alt="About Compony Poster" />
            </div>
            <div className="col-span-1 order-1 flex flex-col justify-center items-center lg:items-start">
                <div className='text-[22px] sm:text-[34px] section-heading font-light' dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.title_en : props.dataContent.title_fa }} />
                <div className="text-[#767d80] leading-[40px] font-medium text-[12px] sm:text-[16px]" dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.desc_en : props.dataContent.desc_fa }} />
                <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}aboutus`} className="h-[62px] leading-[42px] text-white bg-primary-btn-color hover:bg-secondary-btn-color transition-all rounded-[10px] py-[11px] px-[60px] mt-[30px]">
                    {i18next.t('HomePage.بیشتر بخوانید', 'بیشتر بخوانید')}
                </Link>
            </div>
        </div>
    );
};

export default withTranslation()(AboutCompony);
