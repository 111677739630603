import React from "react";
import i18next from "i18next";

const IntroSectionImage = () => {
    return (
        <>
            {
                i18next.language === 'en1' ?
                    <div className="flex relative text-center flex-col justify-center w-[620px] transform scale-[.5] sm:scale-100 mb-[-40px] lg:mb-0  sm:w-full h-[396px] md:w-[544px] lg:w-[404px] xl:w-[800px] sm:left-[-22px] lg:left-0 xl:pr-[72px] lg:pr-[0] z-[1] mt-[10px] sm:mt-[100px] lg:mt-0 xl:top-[0] lg:top-[40px]">
                        <div className="z-[3]">
                            <img className='inline-block' src={require("../../assets/img/en/Header-back-trading.png")} srcSet={`${require("../../assets/img/en/Header-back-trading.png")} 1x, ${require("../../assets/img/en/Header-back-trading@2x.png")} 2x`} alt="Header-back-trading"/>
                        </div>
                        <div className="absolute top-[-30px] left-[270px] sm:left-[300px] md:left-[200px] xl:left-[200px] lg:left-[130px] z-[4] my-animate-one">
                            <img src={require("../../assets/img/en/aplle-icon-header.png")} alt="aplle-icon-header"/>
                        </div>
                        <div className="absolute top-[-35px] left-[50px] md:left-0 z-[4] my-animate-three">
                            <img src={require("../../assets/img/en/Trace-icon-header.png")} alt="aplle-icon-header"/>
                        </div>
                        <div className="absolute top-[5px] right-[12px] xl:right-[12px] lg:right-[-62px] z-[4] my-animate-two">
                            <img src={require("../../assets/img/en/dolllorToCoin-icon-header.png")} alt="aplle-icon-header"/>
                        </div>
                        <div className="absolute bottom-[45px] left-[10px] sm:left-[40px] md:left-[-36px] z-[4] my-animate-two">
                            <img src={require("../../assets/img/en/dowjnes-icon-header.png")} alt="aplle-icon-header"/>
                        </div>
                        <div className="absolute bottom-[35px] right-[42px] xl:right-[32px] lg:right-[2px] my-animate-three">
                            <img src={require("../../assets/img/en/BTC-icon-header.png")} alt="aplle-icon-header"/>
                        </div>
                    </div>
                    :
                    <div className="flex relative text-center flex-col justify-center w-[620px] transform scale-[.5] sm:scale-100 mb-[-40px] lg:mb-0  sm:w-full h-[396px] md:w-[544px] lg:w-[404px] xl:w-[800px] sm:left-[-22px] lg:left-0 xl:pl-[72px] lg:pl-[0] z-[1] mt-[10px] sm:mt-[100px] lg:mt-0 xl:top-[0] lg:top-[40px]">
                        <div className="z-[3]">
                            <img className='inline-block' src={require("../../assets/img/Header-back-trading.png")} srcSet={`${require("../../assets/img/Header-back-trading.png")} 1x, ${require("../../assets/img/Header-back-trading@2x.png")} 2x`} alt="Header-back-trading"/>
                        </div>
                        <div className="absolute top-[-30px] left-[200px] xl:left-[200px] lg:left-[130px] z-[4] my-animate-one">
                            <img src={require("../../assets/img/aplle-icon-header.png")} alt="aplle-icon-header"/>
                        </div>
                        <div className="absolute top-[-35px] right-[50px] md:right-[10px] z-[4] my-animate-three">
                            <img src={require("../../assets/img/dolllorToCoin-icon-header.png")} alt="aplle-icon-header"/>
                        </div>
                        <div className="absolute top-[5px] left-[12px] xl:left-[12px] lg:left-[-62px] z-[4] my-animate-two">
                            <img src={require("../../assets/img/Trace-icon-header.png")} alt="aplle-icon-header"/>
                        </div>
                        <div className="absolute bottom-[45px] right-[10px] sm:right-[40px] md:right-[-75px] z-[2] my-animate-two">
                            <img src={require("../../assets/img/BTC-icon-header.png")} alt="aplle-icon-header"/>
                        </div>
                        <div className="absolute bottom-[35px] left-[72px] xl:left-[72px] lg:left-[2px] z-[4] my-animate-three">
                            <img src={require("../../assets/img/dowjnes-icon-header.png")} alt="aplle-icon-header"/>
                        </div>
                    </div>
            }
        </>
    )
}

export default IntroSectionImage