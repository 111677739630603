import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SiteLogo from "../assets/svg/SiteLogo";
import Home from "../assets/svg/Home";
import Box from "../assets/svg/Box";
import Scales from "../assets/svg/Scales";
import Information from "../assets/svg/Information";
import Call from "../assets/svg/Call";
import Book from "../assets/svg/Book";
import People from "../assets/svg/People";
import User from "../assets/svg/User";
import i18n from "../i18n";
import axios from "axios";
import Swal from 'sweetalert2';
import { useCookies } from "react-cookie";
import Menu from "../assets/svg/Menu";
import SiteLogoRes from "../assets/svg/SiteLogoRes";
import Close from "../assets/svg/Close";
import { withTranslation } from "react-i18next";

const Navbar = ({ t }) => {

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    goToTop();

    const navigate = useNavigate();
    const location = useLocation();
    const [cookie, setCookie, removeCookie] = useCookies(['auth_token', 'i18next']);

    const Languages = [

    ]
    const changeLanguage = (e) => {
        i18n.changeLanguage(e.target.value)

        if (i18n.language === 'en1') {
            window.location.replace(
                `/${i18n.language}${location.pathname}${location.search}${location.hash}`
            )
        } else {
            // console.log(location.pathname)
            window.location.replace(
                `/`
            )
        }
    }

    const logoutSubmit = (e) => {
        e.preventDefault();
        axios.post('/api/logout').then(res => {
            removeCookie('auth_token', { path: '/' });
            navigate(window.location.pathname);
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-start',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })


            Toast.fire({
                icon: 'success',
                title: t('Navs.شما از حساب خود خارج شدید.', 'شما از حساب خود خارج شدید.')
            })
        }).catch(err => {
            if (err.response.status == 401) {
                removeCookie('auth_token', { path: '/' });
                navigate(window.location.pathname);
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-start',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })


                Toast.fire({
                    icon: 'success',
                    title: t('Navs.شما از حساب خود خارج شدید.', 'شما از حساب خود خارج شدید.')
                })
            }
        });

    }

    var AuthButtons = '';
    if (!cookie.auth_token) {
        AuthButtons = (
            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}login`} className="flex items-center border border-white hover:bg-white transition-all hover:text-secondary-bg-color rounded-[10px] px-5 py-[5px] gap-[7px]">
                <span className='lg:hidden xl:inline-block'><User /></span> <span>{t('Navs.ورود / ثبت نام', 'ورود / ثبت نام')}</span>
            </Link>
        );
    } else {
        AuthButtons = (
            <button onClick={logoutSubmit} className="flex items-center border border-white hover:bg-white transition-all hover:text-secondary-bg-color rounded-[10px] px-5 py-[5px] gap-[7px]">
                <span>{t('Navs.خروج از حساب', 'خروج از حساب')}</span>
            </button>
        );
    }

    const OffCanvas = () => {
        const offCanvas = document.getElementById("off-canvas")
        const offCanvasBg = document.getElementById("bg-off-canvas")

        offCanvas.classList.remove('hidden')
        offCanvasBg.classList.remove('hidden')

        setTimeout(function () {
            offCanvas.classList.remove('left-[-360px]')
            offCanvasBg.classList.remove('bg-opacity-0')
            offCanvas.classList.add('left-0')
            offCanvasBg.classList.add('bg-opacity-80')
        }, 100)
    }

    const CloseOffCanvas = () => {
        const offCanvas = document.getElementById("off-canvas")
        const offCanvasBg = document.getElementById("bg-off-canvas")

        offCanvas.classList.add('left-[-360px]')
        offCanvasBg.classList.add('bg-opacity-0')
        offCanvas.classList.remove('left-0')
        offCanvasBg.classList.remove('bg-opacity-80')

        setTimeout(function () {
            offCanvas.classList.add('hidden')
            offCanvasBg.classList.add('hidden')
        }, 100)
    }

    return (
        <>
            <nav className="w-full bg-secondary-bg-color text-white px-[25px] lg:px-[60px] py-[30px] lg:py-[40px]">
                <div className="flex items-center">
                    <div className='flex xl:hidden flex-1'>
                        <span onClick={OffCanvas}>
                            <Menu />
                        </span>
                    </div>
                    <div className='flex items-center'>
                        <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}`}>
                            <div className='hidden xl:flex'>
                                <SiteLogo />
                            </div>
                            <div className='flex xl:hidden'>
                                <SiteLogoRes width={150} height={45} />
                            </div>
                        </Link>
                        <div className="hidden xl:block w-[1px] h-[45px] bg-white bg-opacity-20 mx-5"></div>
                    </div>
                    <ul className="hidden xl:flex flex-1 items-center gap-[24px]">
                        <li className='w-max'>
                            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}`} className="flex items-center gap-[7px] linkContainer">
                                <div className={`icons transition-all ${location.pathname === "/" ? "link-under-line" : ""
                                    }`}>
                                    <Home />
                                </div>
                                <div>{t('Navs.صفحه نخست', 'صفحه نخست')}</div>
                            </Link>
                        </li>
                        <li className='w-max'>
                            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}plans`} className="flex items-center gap-[7px] linkContainer">
                                <div className={`icons transition-all ${location.pathname === "/plans" ? "link-under-line" : ""
                                    }`}>
                                    <Box />
                                </div>
                                <div>{t('Navs.پلن ها', 'پلن ها')}</div>
                            </Link>
                        </li>
                        <li className='w-max'>
                            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}rules`} className="flex items-center gap-[7px] linkContainer">
                                <div className={`icons transition-all ${location.pathname === "/rules" ? "link-under-line" : ""
                                    }`}>
                                    <Scales />
                                </div>
                                <div>{t('Navs.قوانین', 'قوانین')}</div>
                            </Link>
                        </li>
                        <li className='w-max'>
                            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}aboutus`} className="flex items-center gap-[7px] linkContainer">
                                <div className={`icons transition-all ${location.pathname === "/aboutus" ? "link-under-line" : ""
                                    }`}>
                                    <Information />
                                </div>
                                <div>{t('Navs.درباره ما', 'درباره ما')}</div>
                            </Link>
                        </li>
                        <li className='w-max'>
                            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}contactus`} className="flex items-center gap-[7px] linkContainer">
                                <div className={`icons transition-all ${location.pathname === "/contactus" ? "link-under-line" : ""
                                    }`}>
                                    <Call />
                                </div>
                                <div>{t('Navs.تماس با ما', 'تماس با ما')}</div>
                            </Link>
                        </li>
                        <li className='w-max'>
                            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}blog`} className="flex items-center gap-[7px] linkContainer">
                                <div className={`icons transition-all ${location.pathname === "/blog" ? "link-under-line" : ""
                                    }`}>
                                    <Book />
                                </div>
                                <div>{t('Navs.بلاگ', 'بلاگ')}</div>
                            </Link>
                        </li>
                        <li className='w-max'>
                            <Link to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}leaderbord`} className="flex items-center gap-[7px] linkContainer">
                                <div className={`icons transition-all ${location.pathname === "/leaderbord" ? "link-under-line" : ""
                                    }`}>
                                    <People />
                                </div>
                                <div>{t('Navs.لیدر بورد', 'لیدر بورد')}</div>
                            </Link>
                        </li>
                    </ul>
                    <div className="hidden xl:flex items-center gap-2">
                        {AuthButtons}

                        {/*<select className='bg-transparent cursor-pointer outline-none border border-white rounded-[10px] px-[9px] py-[5px] appearance-none' onChange={changeLanguage} defaultValue={i18n.language}>*/}
                        {/*    <option value='fa'>FA</option>*/}
                        {/*    <option value='en1'>EN</option>*/}
                        {/*</select>*/}
                    </div>
                </div>
            </nav>

            <div id='off-canvas' className='hidden fixed bg-[#0f1519] w-[360px] top-0 left-[-360px] bottom-0 z-[999] p-[30px] duration-[300ms]'>
                <div className='border-b border-[#354750] pb-[30px] mb-[20px]'>
                    <Link to={`/${i18n.language === 'en' ? i18n.language + '/' : ''}`}> <span className='flex flex-col col-span-1 items-center'><SiteLogo /></span> </Link> <span onClick={CloseOffCanvas} className='absolute top-[20px] right-[20px]'><Close /></span>
                </div>



                <nav className='text-[#ffffff]'>
                    <ul>
                        <li className='mb-[25px]'>
                            <Link onClick={CloseOffCanvas} to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}`} className="">{t('Navs.صفحه نخست', 'صفحه نخست')}</Link>
                        </li>
                        <li className='mb-[25px]'>
                            <Link onClick={CloseOffCanvas} to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}plans`} className="">{t('Navs.پلن ها', 'پلن ها')}</Link>
                        </li>
                        <li className='mb-[25px]'>
                            <Link onClick={CloseOffCanvas} to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}rules`} className="">{t('Navs.قوانین', 'قوانین')}</Link>
                        </li>
                        <li className='mb-[25px]'>
                            <Link onClick={CloseOffCanvas} to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}aboutus`} className="">{t('Navs.درباره ما', 'درباره ما')}</Link>
                        </li>
                        <li className='mb-[25px]'>
                            <Link onClick={CloseOffCanvas} to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}contactus`} className="">{t('Navs.تماس با ما', 'تماس با ما')}</Link>
                        </li>
                        <li className='mb-[25px]'>
                            <Link onClick={CloseOffCanvas} to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}careers`} className="">{t('Navs.استخدام', 'استخدام')}</Link>
                        </li>
                        <li className='mb-[25px]'>
                            <Link onClick={CloseOffCanvas} to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}blog`} className="">{t('Navs.بلاگ', 'بلاگ')}</Link>
                        </li>
                    </ul>
                </nav>
            </div>
            <div id='bg-off-canvas' className='hidden fixed left-0 top-0 bottom-0 right-[-500px] bg-[#0f1519] z-[998] bg-opacity-0 backdrop-blur-[10px] duration-[300ms]' />
        </>
    );
};

export default withTranslation()(Navbar);
