export default () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.06253 1.83749C8.58753 1.39499 9.43503 1.39499 9.94503 1.83749L11.13 2.84999C11.355 3.03749 11.7825 3.19499 12.0825 3.19499H13.3575C14.1525 3.19499 14.805 3.84749 14.805 4.64249V5.91749C14.805 6.21749 14.9625 6.63749 15.15 6.86249L16.1625 8.04749C16.605 8.57249 16.605 9.41999 16.1625 9.92999L15.15 11.115C14.9625 11.34 14.805 11.76 14.805 12.06V13.335C14.805 14.13 14.1525 14.7825 13.3575 14.7825H12.0825C11.7825 14.7825 11.3625 14.94 11.1375 15.1275L9.95253 16.14C9.42753 16.5825 8.58003 16.5825 8.07003 16.14L6.88503 15.1275C6.66003 14.94 6.23253 14.7825 5.94003 14.7825H4.62753C3.83253 14.7825 3.18003 14.13 3.18003 13.335V12.0525C3.18003 11.76 3.03003 11.3325 2.84253 11.115L1.83003 9.92249C1.39503 9.40499 1.39503 8.56499 1.83003 8.04749L2.84253 6.85499C3.03003 6.62999 3.18003 6.20999 3.18003 5.91749V4.64999C3.18003 3.85499 3.83253 3.20249 4.62753 3.20249H5.92503C6.22503 3.20249 6.64503 3.04499 6.87003 2.85749L8.06253 1.83749Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 6.0975V9.72"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.99585 12H9.00259"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
