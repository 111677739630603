import React from "react";
import Navbar from "../components/Navbar";
import {Outlet} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import SidePanel from "../components/Panel/SidePanel/SidePanel";
import '../assets/styles/Panel.min.css'

const MainLayout = () => {
    const {t, i18n} = useTranslation();
    document.body.dir = i18n.dir();

    return (
        <>
            <Navbar/>
            <div className='under-header w-full bg-secondary-bg-color px-[25px] lg:px-[72px] py-[60px] relative !pt-0'/>
            <div className='flex mt-[-30px] relative px-[25px] lg:px-[72px] gap-6'>
                <SidePanel/>
                <div className='content-panel w-full'>
                    <Outlet/>
                </div>
            </div>
        </>
    );
};

export default MainLayout;
