import React, {useEffect, useState} from 'react'
import useTitle from "../hooks/useTitle";
import InfoHeader from '../components/plans/InfoHeader'
import PlansTable from '../components/plans/PlansTable'
import {withTranslation} from "react-i18next";
import axios from "axios";
import {ClipLoader} from "react-spinners";

const Plans = ({t}) => {
    useTitle(t('PageTitle.Plans', 'پلن های سرمایه گذار برتر'))

    const [pageData, setPageData] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchArticle()
        setLoading(true)
    }, [])

    const fetchArticle = async () => {
        axios.get(`/api/page/پلن-ها`).then(({data}) => {
            setPageData(data)
            setLoading(false)
        })
    }

    if (loading) {
        return (
            <div className='text-center py-[80px] bg-primary-bg-color'>
                <ClipLoader color={'#0098ff'} loading={loading} size={60} aria-label="Loading Spinner" data-testid="loader"/>
            </div>
        )
    }

    if (pageData) {
        return (
            <section className="w-full bg-primary-bg-color overflow-hidden">
                <InfoHeader dataContent={pageData}/>
                <PlansTable/>
            </section>
        )
    }
}

export default withTranslation()(Plans)