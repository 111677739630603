import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { withTranslation } from "react-i18next";
import i18next from "i18next";

const PopularPlatforms = (props) => {
    return (
        <div className="w-full mb-[83px] px-[25px] sm:px-[41px]" style={{
            display: 'flex',
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <h4 className="text-center text-[22px] sm:text-5xl leading-[40px] sm:leading-[70px] text-secondary-bg-color mb-[14px] section-heading font-light" dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.title_en : props.dataContent.title_fa }} />
            <p className="w-full lg:w-[650px] text-center mx-auto text-[#767D80] text-[12px] sm:text-[19px] font-medium leading-[40px]" dangerouslySetInnerHTML={{ __html: i18next.language == 'en' ? props.dataContent.desc_en : props.dataContent.desc_fa }} />

            <div className="xl:w-[251px] h-[82px] bg-white rounded-[20px] flex flex-col justify-center shadow-[5px_5px_40px_rgba(0,0,0,0.05)] my-[30px]">
                <img className="w-[77px] mx-auto" src={require("../../assets/img/FusionMarketsLogo.png")} alt="Fusion Markets Logo" />
            </div>

        </div >
    );
};

export default withTranslation()(PopularPlatforms);
