import React from "react";

const ContentCareer = (props) => {
    return (
        <div className="w-full px-[25px] md:px-[72px] xl:px-[237px]">
            <div className='resume-content rounded-[20px] p-[24px] sm:p-[40px] bg-[#ffffff] mt-[-40px] mb-[30px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)]' dir={'auto'}>
                <h2 className='text-[#0098FF] mb-[30px] text-[20px] font-extrabold'>Job description</h2>
                <div dangerouslySetInnerHTML={{__html: props.jobDesc}} />

                <div dangerouslySetInnerHTML={{__html: props.jobContent}} />

                <h2 className='text-[#0098FF] my-[30px]'>Requirement:</h2>
                <div dangerouslySetInnerHTML={{__html: props.jobRequirement}} />
            </div>
        </div>
    )
}

export default ContentCareer