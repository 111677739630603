import React, {useEffect, useState} from "react";
import useTitle from "../hooks/useTitle";
import InfoHeader from "../components/aboutus/InfoHeader";
import CreateReasons from "../components/aboutus/CreateReasons";
import CustomerStatistics from "../components/home/CustomerStatistics";
import FastLogin from "../components/FastLogin";
import {withTranslation} from "react-i18next";
import axios from "axios";
import {ClipLoader} from "react-spinners";

const AboutUs = ({t}) => {
    useTitle(t('PageTitle.AboutUs', 'درباره ما'))
    const [pageData, setPageData] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchArticle()
        setLoading(true)
    }, [])

    const fetchArticle = async () => {
        axios.get(`/api/page/درباره-ما`).then(({data}) => {
                setPageData(data)
                setLoading(false)
                console.log(data)
            }
        ).catch((err) => {
            console.log(err)
        })
    }

    if (loading) {
        return (
            <div className='text-center py-[80px] bg-primary-bg-color'>
                <ClipLoader color={'#0098ff'} loading={loading} size={60} aria-label="Loading Spinner" data-testid="loader"/>
            </div>
        )
    }

    if (pageData) {
        return (
            <section className="w-full bg-primary-bg-color">
                <InfoHeader dataContent={pageData.section_1}/>
                <CreateReasons dataContent={pageData.section_2} dataContentFAQ={pageData.section_3}/>
                <CustomerStatistics dataContent={pageData.statistics_section}/>
                <FastLogin dataContent={pageData.quick_register_section}/>
            </section>
        )
    }
};

export default withTranslation()(AboutUs);