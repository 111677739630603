export default ({ color = "white" }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99941 11.1927C11.4354 11.1927 12.5994 10.0286 12.5994 8.59268C12.5994 7.15674 11.4354 5.99268 9.99941 5.99268C8.56347 5.99268 7.39941 7.15674 7.39941 8.59268C7.39941 10.0286 8.56347 11.1927 9.99941 11.1927Z"
      stroke="#0098FF"
      strokeWidth="1.5"
    />
    <path
      d="M3.01675 7.07569C4.65842 -0.140973 15.3501 -0.132639 16.9834 7.08403C17.9417 11.3174 15.3084 14.9007 13.0001 17.1174C11.3251 18.734 8.67508 18.734 6.99175 17.1174C4.69175 14.9007 2.05842 11.309 3.01675 7.07569Z"
      stroke="#0098FF"
      strokeWidth="1.5"
    />
  </svg>
);
