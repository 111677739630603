import React from "react";
import InputSearchIcon from "../../assets/svg/InputSearchIcon";
import {Trans} from "react-i18next";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

const SearchHeader = ({t}) => {
    return (
        <div className="w-full h-[169px] relative bg-secondary-bg-color mb-[68px] px-[25px] sm:px-[72px]">
            <div className="w-full h-[1px] xl:bg-[#495156]"/>
            <div className="text-center mt-[10px] xl:mt-[40px]">
                <h3 className="text-white text-[22px] md:text-[32px] font-extrabold">
                    <Trans i18nKey='Blog.BlogTitle'> آرشیو مطالب <span className="font-light">بلاگ</span> </Trans>
                </h3>
                <p className="text-[rgba(255,255,255,0.7)] text-[12px] md:text-sm font-medium leading-[30px] md:leading-[40px]">
                    {t('Blog.BlogDesc', 'منبعی از مطالب آموزشی درباره فارکس، سرمایه گذاری و اخبار فارکس...')}
                </p>
            </div>
            <div className="absolute w-full md:w-min px-[25px] md:px-0 left-1/2 -translate-x-1/2 top-[140px]">
                <div className="relative">
                    <input className={`w-full md:w-[636px] h-[58px] rounded-[10px] px-[25px] ${i18next.language === 'fa' ? 'pl-[65px]': 'pr-[65px]'} py-[4px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)]`} placeholder={t('Blog.SearchPlaceHolder', 'جستجو کلمه کلیدی در میان مطالب منتشر شده در بلاگ...')} type="text"/>
                    <div className={`absolute top-[-0.5%] ${i18next.language === 'fa' ? 'left-[-1.5%]': 'right-[-1.5%]'}`}>
                        <InputSearchIcon/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(SearchHeader);
