import React, {useEffect, useState} from "react";
import axios from "axios";
import CareerCard from "./CareerCard";
import ReactPaginate from "react-paginate";
import RightSliderArrow from "../../assets/svg/RightSliderArrow";
import LeftSliderArrow from "../../assets/svg/LeftSliderArrow";
import {ClipLoader} from "react-spinners";
import InputSearchIcon from "../../assets/svg/InputSearchIcon";
import {Trans, withTranslation} from "react-i18next";
import i18next from "i18next";

const CareersItems = ({t}) => {
    const [postsPerPage] = useState(12);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0)
    const [careers, setCareers] = useState([]);
    const [CareersCount, setCareersCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [useSearch, setUseSearch] = useState(false)
    const [SearchInput, setSearch] = useState({
        search: '',
    });
    const handleInput = (e) => {
        e.persist()
        setSearch({...SearchInput, [e.target.name]: e.target.value});
    }

    const searchSubmit = (e) => {
        e.preventDefault()

        setLoading(true)
        setOffset(1)
        setPageCount(0)
        setUseSearch(true)
        showCareers(SearchInput.search)
    }

    const showCareers = async (search) => {
        const getPostData = (data) => {
            return (
                <>
                    <section className='py-[50px] px-[25px] md:px-[72px]'>
                        <div className='grid grid-cols-1 lg:grid-cols-2 gap-[24px]  w-full col-span-1'>
                            {
                                data ?
                                    data.map((item) =>
                                        <div key={item.id}>
                                            <CareerCard title={item.title} slug={item.slug} location={item.country + '، ' + item.city} cat={item.cat_tbl.title}/>
                                        </div>
                                    ) : null
                            }
                        </div>
                    </section>
                </>
            )
        }

        axios.get(`api/careers`, {
            params: {
                search: search
            }
        }).then(res => {
            const data = res.data;
            const slice = data.slice(offset - 1, offset - 1 + postsPerPage)

            // For displaying Data
            const postData = getPostData(slice)

            // Using Hooks to set value
            setCareers(postData)
            setPageCount(Math.ceil(data.length / postsPerPage))
            setCareersCount(data.length)
            setLoading(false)
        })
    }

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    useEffect(() => {
        setLoading(true)
        if (useSearch) {
            showCareers(SearchInput.search)
            showCareers(SearchInput.search)
        } else {
            showCareers()
        }
    }, [offset])

    return (
        <div>
            <div className="w-full sm:h-[169px] pb-[40px] sm:pb-0 relative bg-secondary-bg-color mb-[20px] sm:mb-[73px] px-[25px] md:px-[72px]">
                <div className="w-full h-[1px] lg:bg-[#495156]"/>
                <div className="text-center mt-[40px]">
                    <h3 className="text-white text-[22px] md:text-[32px] font-extrabold">
                        <Trans i18nKey='Careers.Title'> همکاری با <span className="font-light">سرمایه گذار برتر</span> </Trans>
                    </h3>
                    <p className="text-[rgba(255,255,255,0.7)] text-sm font-medium leading-[40px]">
                        {t('Careers.Desc', 'اگر به دنبال امنیت شغلی هستید میتوانید با ما همکاری کنید')}
                    </p>
                </div>
                <div className="absolute px-[25px] md:px-0 w-full md:w-max left-0 md:left-1/2 md:-translate-x-1/2 top-[165px] sm:top-[140px]">
                    <div className="relative">
                        <form onSubmit={searchSubmit}>
                            <input name={'search'} onChange={handleInput} value={SearchInput.search} className={`outline-none w-full md:w-[636px] h-[58px] rounded-[10px] px-[25px] py-[4px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] text-[9px] sm:text-[16px] ${i18next.language === 'fa' ? 'pl-[65px]' : 'pr-[65px]'}`} placeholder={t('Careers.SearchPlaceHolder', 'جستجو کلمه کلیدی در میان موقعیت های شغلی...')} type="text"/>
                            <button type='submit' className={`absolute top-[-0.5%] ${i18next.language === 'fa' ? 'left-[-1.5%]' : 'right-[-1.5%]'}`}>
                                <InputSearchIcon/>
                            </button>
                        </form>
                    </div>
                </div>
            </div>

            {
                loading ?
                    <div className='text-center py-[80px] bg-primary-bg-color'>
                        <ClipLoader color={'#0098ff'} loading={loading} size={60} aria-label="Loading Spinner" data-testid="loader"/>
                    </div>
                    : CareersCount > 0 ? careers : ''
            }


            {CareersCount > postsPerPage ? <ReactPaginate previousLabel={<RightSliderArrow color="#C3C3C3"/>} nextLabel={<LeftSliderArrow color="#C3C3C3"/>} breakLabel={"..."} breakClassName={"break-me"} pageCount={pageCount} onPageChange={handlePageClick} containerClassName="paginate flex justify-center items-center text-[#C3C3C3] font-bold gap-[40px] pt-[79px] pb-[106px]" pageClassName="hover:text-primary-btn-color transition duration-[300ms]" activeClassName={"text-[#0098FF]"}/> : ''}
        </div>
    );
}
export default withTranslation()(CareersItems);