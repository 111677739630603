import BuyHeaderIconFirst from "../../assets/svg/BuyHeaderIconFirst"
import BuyHeaderIconLast from "../../assets/svg/BuyHeaderIconLast"
import BuyHeaderIconSecond from "../../assets/svg/BuyHeaderIconSecond"
import {withTranslation} from "react-i18next";

const InfoHeader = ({t}) => {
    return (

        <div className="w-full h-[169px] relative bg-secondary-bg-color mb-[68px] px-[25px] sm:px-[72px]">
            <div className="w-full h-[1px] bg-[#495156]"/>
            <div className="text-center mt-[40px]">
                <h3 className="text-white text-[32px] font-extrabold pb-[99px] relative">
                    {t('Buy.روند خرید پلن', 'روند خرید پلن')} </h3>
                <div className="w-full top-[212px] h-[88px]">
                    <div className=" flex justify-center items-center py-[15px] px-[20px] sm:px-[30px] mx-auto bg-white rounded-[20px] shadow-[5px_5px_40px_rgba(0,0,0,0.05)] mt-[-65px]">
                        <div className="flex gap-[40px] sm:gap-[80px] md:gap-[100px] justify-center items-center">
                            <div className='text-center'>
                                <div className='inline-block'><BuyHeaderIconFirst color='#C3C3C3'/></div>
                                <div className='text-[#C3C3C3] text-[14px]'>{t('Buy.تنظیمات پلن', 'تنظیمات پلن')}</div>
                            </div>
                            <div className='text-center'>
                                <div className='inline-block'><BuyHeaderIconSecond/></div>
                                <div className='text-[#C3C3C3] text-[14px]'>{t('Buy.پرداخت', 'پرداخت')}</div>
                            </div>
                            <div className='text-center'>
                                <div className='inline-block'><BuyHeaderIconLast color='#555555'/></div>
                                <div className='text-[#555] text-[14px]'>{t('Buy.اتمام خرید', 'اتمام خرید')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(InfoHeader)