import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import Home from "../pages/Home";
import Plans from "../pages/Plans";
import Rules from "../pages/Rules";
import AboutUs from "../pages/AboutUs";
import ContactUs from "../pages/ContactUs";
import Blog from "../pages/Blog";
import LeaderBord from "../pages/LeaderBord";
import SingleBlog from "../pages/SingleBlog";
import Login from "../pages/Login";
import LoginPhone from "../pages/LoginPhone";
import Buy from "../pages/Buy";
import Careers2 from "../pages/Careers";
import SingleCareer from "../pages/SingleCareer";
import axios from "axios";
import { useCookies } from "react-cookie";
import CryptoJS from 'crypto-js';
import ThankYou from "../pages/ThankYou";
import i18n from "i18next";
import PanelLayout from "../layouts/PanelLayout";
import Dashboard from "../pages/Panel/Dashboard";
import TermsAndConditions from "../pages/TermsAndConditions"

// http://api.sarmayegozaranbartar.itrad.ir/
// http://api.sgb.test/
// https://panel.sgbtrading.com/
axios.defaults.baseURL = "https://panel.sgbtrading.com/"; //  https://panel.sgbtrading.com/
axios.defaults.frontUrl = 'https://sgbtrading.com/' // https://sgbtrading.com/
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';

axios.defaults.withCredentials = true;

const MyRoutes = () => {
    const [cookie] = useCookies(['auth_token']);
    if (cookie.auth_token) {
        const password = 'Sarmayegozaran@App'
        const decrypt = (crypted, password) => JSON.parse(CryptoJS.AES.decrypt(crypted, password).toString(CryptoJS.enc.Utf8)).content
        const decryptedString = decrypt(cookie.auth_token, password)
        axios.interceptors.request.use(function (config) {
            const token = cookie.auth_token;
            config.headers.Authorization = token ? `Bearer ${decryptedString}` : '';
            return config;
        });
    }

    const lang = i18n.language;

    return (
        <Router>
            {/*<React.StrictMode>*/}
            <Routes>
                <Route element={<MainLayout />}>
                    <Route path={`${lang === 'en1' ? lang : ''}/`} element={<Home />} />
                    <Route path={`${lang === 'en1' ? lang : ''}/plans`} element={<Plans />} />
                    <Route path={`${lang === 'en1' ? lang : ''}/rules`} element={<Rules />} />
                    <Route path={`${lang === 'en1' ? lang : ''}/aboutus`} element={<AboutUs />} />
                    <Route path={`${lang === 'en1' ? lang : ''}/contactus`} element={<ContactUs />} />
                    <Route path={`${lang === 'en1' ? lang : ''}/blog`} element={<Blog />} />
                    <Route path={`${lang === 'en1' ? lang : ''}/blog/:slug`} element={<SingleBlog />} />
                    <Route path={`${lang === 'en1' ? lang : ''}/leaderbord`} element={<LeaderBord />} />
                    <Route path={`${lang === 'en1' ? lang : ''}/buy`} element={cookie.auth_token ? <Buy /> : <Navigate to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}login`} />} />
                    <Route path={`${lang === 'en1' ? lang : ''}/thank-you`} element={cookie.auth_token ? <ThankYou /> : <Navigate to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}login`} />} />
                    <Route path={`${lang === 'en1' ? lang : ''}/careers`} element={<Careers2 />} />
                    <Route path={`${lang === 'en1' ? lang : ''}/careers/:slug`} element={<SingleCareer />} />
                    <Route path={`${lang === 'en1' ? lang : ''}/terms-and-conditions`} element={<TermsAndConditions />} />

                </Route>

                <Route element={<PanelLayout />}>
                    <Route path={`${lang === 'en1' ? lang : ''}/panel`} element={<Dashboard />} />
                </Route>

                <Route path={`${lang === 'en1' ? lang : ''}/login`} element={cookie.auth_token ? <Navigate to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}`} /> : <Login />} />
                <Route path={`${lang === 'en1' ? lang : ''}/login-phone`} element={cookie.auth_token ? <Navigate to={`/${i18n.language === 'en1' ? i18n.language + '/' : ''}`} /> : <LoginPhone />} />
            </Routes>
            {/*</React.StrictMode>*/}
        </Router>
    );
};

export default MyRoutes;
