export default () => (
  <svg
    width="90"
    height="90"
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M81.6406 56.2624C42.0406 56.2624 41.5 89.5 10 84C9.62208 78.9452 0.499991 77 7.84262 61.5C8.12104 49.6002 9.58128 37.3619 13.5 33C23 27 35.0712 29.3106 49 29C66.7239 28.6048 83.5 27 81.6406 56.2624Z"
      fill="#EFEFEF"
    />
    <path
      d="M67.65 50.8125C66.075 52.35 65.175 54.5625 65.4 56.925C65.7375 60.975 69.45 63.9375 73.5 63.9375H80.625V68.4C80.625 76.1625 74.2875 82.5 66.525 82.5H28.6125C29.775 81.525 30.7875 80.325 31.575 78.975C32.9625 76.725 33.75 74.0625 33.75 71.25C33.75 62.9625 27.0375 56.25 18.75 56.25C15.225 56.25 11.9625 57.4875 9.375 59.55V43.1625C9.375 35.4 15.7125 29.0625 23.475 29.0625H66.525C74.2875 29.0625 80.625 35.4 80.625 43.1625V48.5625H73.05C70.95 48.5625 69.0375 49.3875 67.65 50.8125Z"
      stroke="#1B262C"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.375 46.5374V29.4001C9.375 24.9376 12.1125 20.9625 16.275 19.3875L46.05 8.13748C50.7 6.37498 55.6875 9.82509 55.6875 14.8126V29.0625"
      stroke="#1B262C"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M84.5955 52.3881V60.1133C84.5955 62.1758 82.9455 63.8631 80.8455 63.9381H73.4956C69.4456 63.9381 65.733 60.9757 65.3955 56.9257C65.1705 54.5632 66.0705 52.3506 67.6455 50.8131C69.033 49.3881 70.9455 48.5632 73.0455 48.5632H80.8455C82.9455 48.6382 84.5955 50.3256 84.5955 52.3881Z"
      stroke="#1B262C"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.25 45H52.5"
      stroke="#1B262C"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.75 71.25C33.75 74.0625 32.9625 76.725 31.575 78.975C30.7875 80.325 29.775 81.525 28.6125 82.5C25.9875 84.8625 22.5375 86.25 18.75 86.25C13.275 86.25 8.51251 83.325 5.92501 78.975C4.53751 76.725 3.75 74.0625 3.75 71.25C3.75 66.525 5.925 62.2875 9.375 59.55C11.9625 57.4875 15.225 56.25 18.75 56.25C27.0375 56.25 33.75 62.9625 33.75 71.25Z"
      stroke="#1B262C"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9053 71.2482L16.6177 74.9606L24.6053 67.5732"
      stroke="#1B262C"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
