import {withTranslation} from "react-i18next";
import i18next from "i18next";

const PlansArrowButton = () => {
    return (
        <>
            {
                i18next.language === 'fa' ?
                    <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6.0832 11.12L2.27987 7.31667C1.8307 6.8675 1.8307 6.1325 2.27987 5.68334L6.0832 1.88" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/> </svg>
                    :
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.917 12.12 9.72 8.317c.45-.45.45-1.184 0-1.634L5.917 2.88" stroke="#fff" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/></svg>
            }
        </>
    )
}
export default withTranslation()(PlansArrowButton)